import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tooltip: {
    fontSize: 12,
  },
  fileDownloadButton: {
    marginRight: 24,
  },
  createButton: {
    marginRight: 24,
  },
  assignResponsible: {
    overflowY: 'visible',
  },
    tooltipItem: {
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }
));

export default useStyles;
