import {
  BUSINESS_LAWYER,
  KC_LAWYER,
  FINANCIER,
  EVENT_REGISTRATION_RESPONSIBLE,
  WORKGROUP_HEAD,
  ECOLOGIST,
} from 'constants/roles';

// action
const VIEW = 'view';
const EDIT = 'edit';
const MULTIPLE_SELECT = 'multiple-select';
const FILTER = 'filter';

// pages
const CREATE_EVENT_PAGE = 'create-event:';
const EDIT_EVENT_PAGE = 'edit-event:';

// blocks
const COMMENT = 'comment';
const EDITABLE_FIELD = 'always-editable-field';
const RESPONSIBLE_FIELD = 'responsible-select:';
const STATUS_FIELD = 'status_field:';
const ENVIRONMENTAL_RISKS = 'environmental-riskId:';
const ENVIRONMENTAL_LOSS = 'environmental-loss:';
const ECOLOGIST_COMMENT = 'ecologist-comment:';
const INVENTORY_NUMBER = 'inventory-number:';
const TECHPLACE_SAP = 'techPlace-sap:';
const BALANS_COST = 'balans-cost:';
const WRECK_REPAIR_COST = 'wreck-repair-cost:';
const RECOVERY_COST = 'recovery-cost:';
const ECONOMIC_LOSS = 'economic-loss:';
const DOCUMENT_PREPARATION = 'document-preparation:';
const PERFORMERS_VIEW = 'performers-view:';
/* ------------------------ */

// create event page
export const CREATE_EVENT = `${CREATE_EVENT_PAGE}${VIEW}`;
export const RESPONSIBLE_SELECT = `${CREATE_EVENT_PAGE}${RESPONSIBLE_FIELD}${VIEW}`;
export const MULTIPLE_RESPONSIBLE_SELECT = `${CREATE_EVENT_PAGE}${RESPONSIBLE_FIELD}${MULTIPLE_SELECT}`;

// edit event page
export const EVENT_COMMENT = `${EDIT_EVENT_PAGE}${COMMENT}${EDIT}`;
export const BUSINESS_LAWYER_COMMENT = `${EDIT_EVENT_PAGE}${COMMENT}${BUSINESS_LAWYER}${VIEW}`;
export const KC_LAWYER_COMMENT = `${EDIT_EVENT_PAGE}${COMMENT}${KC_LAWYER}${VIEW}`;
export const ALWAYS_EDITABLE_FIELD = `${EDIT_EVENT_PAGE}${EDITABLE_FIELD}${EDIT}`;
export const ECOLOGIST_COMMENT_FIELD = `${EDIT_EVENT_PAGE}${ECOLOGIST_COMMENT}${EDIT}`;
export const ENVIRONMENTAL_RISKS_SELECT = `${EDIT_EVENT_PAGE}${ENVIRONMENTAL_RISKS}${EDIT}`;
export const ENVIRONMENTAL_FIELD_LOSS = `${EDIT_EVENT_PAGE}${ENVIRONMENTAL_LOSS}${EDIT}`;
export const INVENTORY_NUMBER_FIELD = `${EDIT_EVENT_PAGE}${INVENTORY_NUMBER}${EDIT}`;
export const TECHPLACE_SAP_FIELD = `${EDIT_EVENT_PAGE}${TECHPLACE_SAP}${EDIT}`;
export const BALANS_COST_FIELD = `${EDIT_EVENT_PAGE}${BALANS_COST}${EDIT}`;
export const WRECK_REPAIR_COST_FIELD = `${EDIT_EVENT_PAGE}${WRECK_REPAIR_COST}${EDIT}`;
export const RECOVERY_COST_FIELD = `${EDIT_EVENT_PAGE}${RECOVERY_COST}${EDIT}`;
export const ECONOMIC_LOSS_FIELD = `${EDIT_EVENT_PAGE}${ECONOMIC_LOSS}${EDIT}`;
export const DOCUMENT_PREPARATION_CHECKBOXES = `${EDIT_EVENT_PAGE}${DOCUMENT_PREPARATION}${EDIT}`;
export const PERFORMERS_BLOCK = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${EDIT}`;
export const PERFORMERS_FINANCIER_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${FINANCIER}`;
export const PERFORMERS_KC_LAWYER_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${KC_LAWYER}`;
export const PERFORMERS_RESPONSIBLE_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${EVENT_REGISTRATION_RESPONSIBLE}`;
export const PERFORMERS_HEAD_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${WORKGROUP_HEAD}`;
export const PERFORMERS_BUSINESS_LAWYER_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${BUSINESS_LAWYER}`;
export const PERFORMERS_ECOLOGIST_COMMENT = `${EDIT_EVENT_PAGE}${PERFORMERS_VIEW}${COMMENT}${ECOLOGIST}`;

// add files to event on creation
export const ADD_FILES_TO_EVENT_ON_CREATION = 'add-files-to-event-on-creation';

// add files to object on creation
export const ADD_FILES_TO_OBJECT_ON_CREATION = 'add-files-to-object-on-creation';

// edit-event-status-field
export const EDIT_STATUS_FIELD = `${STATUS_FIELD}${EDIT}`;

// filter-responsible-field
export const RESPONSIBLE_FILTER = `${RESPONSIBLE_FIELD}${FILTER}`;
