/*
* 24 - h1
* 20b - h2
* 20 - h3
* 18b - h4
* 16b - subtitle1
* 16 - subtitle2
* 14b - body1
* 14 - body2
* 12 - caption
*/
const typography = {
  h1: {
    fontSize: 24,
    lineHeight: '48px',
    fontWeight: 'normal',
  },
  h2: {
    fontSize: 20,
    lineHeight: '48px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  h3: {
    fontSize: 20,
    lineHeight: '48px',
    letterSpacing: 'normal',
  },
  h4: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  subtitle2: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },
  body1: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0',
  },
  button: { // not used
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '1.25px',
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  overline: { // not used
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '1.5px',
  },
};

export default typography;