import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from '@material-ui/lab';
import { CheckCircleOutlined, CancelOutlined } from '@material-ui/icons';

import useStyles from './NotificationAlertStyles';

const iconMapping = {
  error: <CancelOutlined/>,
  success: <CheckCircleOutlined/>,
};

const NotificationAlert = ({ type = "info", children, onClose }) => {
  const classes = useStyles();

  return (
    <Alert
      data-test="notification-wrapper"
      iconMapping={iconMapping}
      classes={{
        root: classes.root,
        action: classes.action,
        icon: classes.icon,
        message: classes.message,
        standardSuccess: classes.alertSuccess,
        standardError: classes.alertError,
      }}
      onClose={onClose}
      severity={type}
    >
      {children}
    </Alert>
  );
};

NotificationAlert.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default NotificationAlert;
