import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    width: '30%',
  },
  title: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.black,
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
    marginTop: ({ subTitle }) => (subTitle ? 0 : 7),
  },
  subTitle: {
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.colors.darkGray,
  },
}
));

export default useStyles;
