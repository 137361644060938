/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'API';
import { get, uniq } from 'lodash';

const getUserRoles = (user) => {
  const access = get(user, 'access', []);
  return uniq(access.map(({ role } = {}) => role)).filter(it => it);
};

const getUserHoldings = (user) => {
  const access = get(user, 'access', []);
  return uniq(access.map(({ holdingId } = {}) => holdingId)).filter(it => it);
};

export const getUserSelector = (state) => state.account?.user;
export const getUserRoleSelector = (state) => state.account?.user?.access;
export const getCurrentUserRoleSelector = (state) => state.account?.user?.roles;

export const loadUserData = createAsyncThunk(
  'account/getUserData',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.user.getUserData();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    user: {
      name: 'No name'
    },
  },
  extraReducers: {
    [loadUserData.fulfilled]: (state, action) => {
      state.user = {
        ...action.payload,
        roles: getUserRoles(action.payload),
        holdings: getUserHoldings(action.payload)
      };
    },
  },
});

export default accountSlice.reducer;
