import {makeStyles} from '@material-ui/core/styles';
import {
    white,
    fullBlack,
    darkGray,
    lightSelection,
    primaryLight,
    gray
} from '../ThemeProvider/theme/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        position: 'relative',
        '&::before': {
            backgroundColor: gray,
            height: '2px',
            content: '""',
            display: 'block',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
    },
    translationContent: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        height: 48,
        width: '100%',
    },
    tabsWrapper: {
        position: 'relative',
        backgroundColor: white,
        whiteSpace: 'nowrap',
        '&::before': {
            display: 'block',
            content: '""',
            backgroundColor: gray,
            height: '2px',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
    },
    tab: {
        paddingLeft: 16,
        paddingRight: 16,
        height: 48,
        fontSize: 16,
        lineHeight: 1.5,
        textAlign: 'center',
        '&.MuiButtonBase-root': {
            borderRadius: 0,
            textTransform: 'none',
        },
        position: 'relative',
        '&::before': {
            backgroundColor: gray,
            height: '2px',
            content: '""',
            display: 'block',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
    },
    active: {
        '&.MuiButtonBase-root': {
            color: primaryLight,
            backgroundColor: lightSelection,
            textShadow: `.5px 0px 0px ${primaryLight}`,
        },
    },
    inactive: {
        '&.MuiButtonBase-root': {
            color: fullBlack,
            backgroundColor: white,
            textShadow: 'none',
        },
    },
    hiddenCount: {
        fontSize: 16,
        lineHeight: 1.5,
        color: darkGray,
    },
    backwardButtonWrapper: {
        width: 67,
        minWidth: 67,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    backwardButton: {
        marginLeft: 5,
        marginRight: 2,
    },
    forwardButtonWrapper: {
        marginLeft: 'auto',
        width: 67,
        minWidth: 67,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    forwardButton: {
        marginLeft: 2,
        marginRight: 5,
    },
    activeIndicator: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 4,
        zIndex: 1,
        backgroundColor: primaryLight,
    },
}));

export default useStyles;
