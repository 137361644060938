import routes from 'routes';
import NoAccess from 'pages/NoAccess';
import Main from 'pages/Main';
import CreateEvent from 'pages/CreateEvent';
import Event from 'pages/Event';
import EditEvent from 'pages/EditEvent';

import { CREATE_EVENT } from 'constants/permissions';

const routesList = [
  {
    name: 'main',
    path: routes.main,
    component: Main,
    exact: true,
  },
  {
    name: 'createEvent',
    path: routes.createEvent,
    component: CreateEvent,
    exact: true,
    permission: CREATE_EVENT,
  },
  {
    name: 'event',
    path: routes.event,
    component: Event,
    exact: true,
  },
  {
    name: 'editEvent',
    path: routes.editEvent,
    component: EditEvent,
    exact: true,
  },
  {
    name: 'noAccess',
    path: routes.noAccess,
    component: NoAccess,
    exact: false,
  },
];

export default routesList;
