import React from 'react';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';

import lightTheme from './theme/lightTheme';
import darkTheme from './theme/darkTheme';

const ThemeProvider = ({ theme, children }) => {
  const themeType = theme === 'dark' ? darkTheme : lightTheme;

  return (
    <MaterialThemeProvider theme={themeType}>{children}</MaterialThemeProvider>
  );
};

export default ThemeProvider;
