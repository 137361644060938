/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import API from 'API';
import { LOADING, SUCCEEDED, INIT_STATUS } from 'constants/main';
import { assignStatusesTranslations } from 'utils/mappers';


export const loadHoldingsList = createAsyncThunk('dictionaries/loadHoldingList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getHoldingList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadCompensationCategoriesList = createAsyncThunk('dictionaries/loadCompensationCategoriesList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getCompensationCategoriesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadEnterprisesList = createAsyncThunk('dictionaries/loadEnterpriseList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getEnterpriseList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadUnitsList = createAsyncThunk('dictionaries/loadUnitsList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getUnitsList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadRegionsList = createAsyncThunk('dictionaries/loadRegionsList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getRegionsList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadRegionsDistrictsList = createAsyncThunk('dictionaries/loadRegionsDistrictsList', async (eventAreaId, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getRegionsDistrictsList(eventAreaId);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadDamageCategoriesList = createAsyncThunk('dictionaries/loadDamageCategoriesList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getDamageCategoriesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadPropertyCategoriesList = createAsyncThunk('dictionaries/loadPropertyCategoriesList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getPropertyCategoriesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadPropertyTypesList = createAsyncThunk('dictionaries/loadPropertyTypesList', async (propertyCategoryId, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getPropertyTypesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadEnvironmentalRisksList = createAsyncThunk('dictionaries/loadEnvironmentalRisksList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getEnvironmentalRisksList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadCaseStatusesList = createAsyncThunk('dictionaries/loadCaseStatusesList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getCaseStatusesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const loadRolesList = createAsyncThunk('dictionaries/loadRolesList', async (args, { rejectWithValue }) => {
    try {
      const response = await API.dictionary.getRolesList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: {
    dictionaries: {
      rolesDictionary: [],
      holdingsDictionary: [],
      compensationCategoriesDictionary: [],
      holdingsNamesObject: {},
      enterprisesDictionary: [],
      enterprisesNamesObject: {},
      unitsDictionary: [],
      unitsNamesObject: {},
      regionsDictionary: [],
      regionsDistrictsDictionary: [],
      damageCategoriesDictionary: [],
      propertyCategoriesDictionary: [],
      propertyTypesDictionary: [],
      propertyTypesNamesObject: {},
      environmentalRisksDictionary: [],
      ecologicalRisksNamesObject: {},
      filteredEnterprises: [],
      filteredUnits: [],
      filteredRegionsDistricts: [],
      caseStatusesDictionary: [],
      caseStatusesNamesObject: {},

      rolesDictionaryStatus: INIT_STATUS,
      holdingsDictionaryStatus: INIT_STATUS,
      compensationCategoriesStatus: INIT_STATUS,
      enterprisesDictionaryStatus: INIT_STATUS,
      unitsDictionaryStatus: INIT_STATUS,
      regionsDictionaryStatus: INIT_STATUS,
      regionsDistrictsDictionaryStatus: INIT_STATUS,
      damageCategoriesDictionaryStatus: INIT_STATUS,
      propertyCategoriesDictionaryStatus: INIT_STATUS,
      propertyTypesDictionaryStatus: INIT_STATUS,
      environmentalRisksDictionaryStatus: INIT_STATUS,
      caseStatusesDictionaryStatus: INIT_STATUS,
      ...initialStateMetadata,
    },
  },
  reducers: {
    getFilteredEnterprises(state, action) {
      const holdingIds = action.payload || [];
      state.dictionaries.filteredEnterprises = state.dictionaries.enterprisesDictionary.filter(({ holdingId } = {}) => holdingIds.includes(holdingId));
    },
    getFilteredUnits(state, action) {
      const enterpriseIds = action.payload || [];
      state.dictionaries.filteredUnits = state.dictionaries.unitsDictionary.filter(({ enterpriseId } = {}) => enterpriseIds.includes(enterpriseId));
    },
    getFilteredRegionDistricts(state, action) {
      state.dictionaries.filteredRegionsDistricts = state.dictionaries.regionsDistrictsDictionary.filter(({ regionId } = {}) => regionId === action.payload);
    },
  },
  extraReducers: {
    [loadHoldingsList.pending]: (state) => {
      state.dictionaries.holdingsDictionaryStatus = LOADING;
    },
    [loadHoldingsList.fulfilled]: (state, action) => {
      state.dictionaries.holdingsDictionary = action.payload;
      state.dictionaries.holdingsNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.holdingsDictionaryStatus = SUCCEEDED;
    },

    [loadCompensationCategoriesList.pending]: (state) => {
      state.dictionaries.compensationCategoriesStatus = LOADING;
    },
    [loadCompensationCategoriesList.fulfilled]: (state, action) => {
      state.dictionaries.compensationCategoriesDictionary = action.payload;
      state.dictionaries.compensationCategoriesStatus = SUCCEEDED;
    },

    [loadEnterprisesList.pending]: (state) => {
      state.dictionaries.enterprisesDictionaryStatus = LOADING;
    },
    [loadEnterprisesList.fulfilled]: (state, action) => {
      state.dictionaries.enterprisesDictionary = action.payload;
      state.dictionaries.enterprisesNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.enterprisesDictionaryStatus = SUCCEEDED;
    },

    [loadUnitsList.pending]: (state) => {
      state.dictionaries.unitsDictionaryStatus = LOADING;
    },
    [loadUnitsList.fulfilled]: (state, action) => {
      state.dictionaries.unitsDictionary = action.payload;
      state.dictionaries.unitsNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.unitsDictionaryStatus = SUCCEEDED;
    },

    [loadRegionsList.pending]: (state) => {
      state.dictionaries.regionsDictionaryStatus = LOADING;
    },
    [loadRegionsList.fulfilled]: (state, action) => {
      state.dictionaries.regionsDictionary = action.payload;
      state.dictionaries.regionsDictionaryStatus = SUCCEEDED;
    },

    [loadRegionsDistrictsList.pending]: (state) => {
      state.dictionaries.regionsDistrictsDictionaryStatus = LOADING;
    },
    [loadRegionsDistrictsList.fulfilled]: (state, action) => {
      state.dictionaries.regionsDistrictsDictionary = action.payload;
      state.dictionaries.regionsDistrictsDictionaryStatus = SUCCEEDED;
    },

    [loadRolesList.pending]: (state) => {
      state.dictionaries.rolesDictionaryStatus = LOADING;
    },
    [loadRolesList.fulfilled]: (state, action) => {
      state.dictionaries.rolesDictionary = action.payload;
      state.dictionaries.rolesDictionaryStatus = SUCCEEDED;
    },

    [loadDamageCategoriesList.pending]: (state) => {
      state.dictionaries.damageCategoriesDictionaryStatus = LOADING;
    },
    [loadDamageCategoriesList.fulfilled]: (state, action) => {
      state.dictionaries.damageCategoriesDictionary = action.payload;
      state.dictionaries.damageCategoriesDictionaryStatus = SUCCEEDED;
    },

    [loadPropertyCategoriesList.pending]: (state) => {
      state.dictionaries.propertyCategoriesDictionaryStatus = LOADING;
    },
    [loadPropertyCategoriesList.fulfilled]: (state, action) => {
      state.dictionaries.propertyCategoriesDictionary = action.payload;
      state.dictionaries.propertyCategoriesDictionaryStatus = SUCCEEDED;
    },

    [loadPropertyTypesList.pending]: (state) => {
      state.dictionaries.propertyTypesDictionaryStatus = LOADING;
    },
    [loadPropertyTypesList.fulfilled]: (state, action) => {
      state.dictionaries.propertyTypesDictionary = action.payload;
      state.dictionaries.propertyTypesNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.propertyTypesDictionaryStatus = SUCCEEDED;
    },

    [loadEnvironmentalRisksList.pending]: (state) => {
      state.dictionaries.environmentalRisksDictionaryStatus = LOADING;
    },
    [loadEnvironmentalRisksList.fulfilled]: (state, action) => {
      state.dictionaries.environmentalRisksDictionary = action.payload;
      state.dictionaries.environmentalRisksNamesObject = keyBy(action.payload, ({ id } = {}) => id);
      state.dictionaries.environmentalRisksDictionaryStatus = SUCCEEDED;
    },

    [loadCaseStatusesList.pending]: (state) => {
      state.dictionaries.caseStatusesDictionaryStatus = LOADING;
    },
    [loadCaseStatusesList.fulfilled]: (state, action) => {
      state.dictionaries.caseStatusesDictionary = assignStatusesTranslations(action.payload);
      state.dictionaries.caseStatusesNamesObject = keyBy(assignStatusesTranslations(action.payload), ({ enumValue } = {}) => enumValue);
      state.dictionaries.caseStatusesDictionaryStatus = SUCCEEDED;
    },
  },
});

export default dictionariesSlice.reducer;
export const {
  getFilteredEnterprises,
  getFilteredUnits,
  getFilteredRegionDistricts
} = dictionariesSlice.actions;
