import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import {
  createEvent,
  checkOnUniqueInventoryNumber,
  resetError,
  resetEventStore
} from 'store/event';
import { showNotification } from 'store/notifications';
import { mapEventToServerData } from 'utils/mappers';
import routes, { routeBits } from 'routes';
import UniqueInventoryNumberDialog from 'components/UniqueInventoryNumberDialog';
import { CREATE_EVENT_TITLE, CANCEL, SAVE, FORM_HAS_ERRORS } from 'constants/texts';
import { createEventFormValidation } from 'utils/validation';
import { Typography, Button } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';
import PageHeader from 'components/PageHeader';

import CreateEventForm from './components/CreateEventForm';
import useAppStyles from 'containers/App/AppStyles';

const initBlockValues = {
  witness: {
    name: null,
    phone: null,
    additionalData: null,
    isEmployee: true,
  },
  object: {
    title: null,
    inventoryNumber: null,
    techPlaceSap: null,
    balansCost: null,
    propertyType: null,
    damageType: null,
    environmentalRisks: null,
    isRepairComplete: false,
    repairCompleteDate: null,
    damagesDescription: null,
    comment: null,
    damageCompensationCategoryIds: [],
  },
};

const CreateEvent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uniqueInventoryNumberList, setUniqueInventoryNumberList] = useState(null);
  const appClasses = useAppStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      eventDate: new Date('2022.02.24'),
      eventTime: null,
      holding: null,
      responsibleId: null,
      enterprise: null,
      subdivision: null,
      eventArea: null,
      regionArea: null,
      addressArea: null,
      eventDescription: null,
      eventComment: null,
      witnesses: [initBlockValues.witness, initBlockValues.witness],
      objects: [initBlockValues.object],
    },
    validationSchema: createEventFormValidation,
    onSubmit: async (values) => {
      const inventoryNumbersIsEmpty = !values?.objects?.some((it) => Boolean(it?.inventoryNumber));

      if (inventoryNumbersIsEmpty) {
        return handleSubmitForm(values);
      }

      setIsSubmitting(true);
      const { payload } = await dispatch(checkOnUniqueInventoryNumber({
        holdingId: values.holding,
        data: values?.objects.map((it) => it?.inventoryNumber).filter((it) => it),
      }));

      if (payload.length > 0) {
        setIsSubmitting(false);
        setUniqueInventoryNumberList(payload);
      } else {
        handleSubmitForm(values);
      }
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetError());
      dispatch(resetEventStore());
    }
  }, []);

  const handleSubmitForm = (values) => {
    setUniqueInventoryNumberList(null);
    setIsSubmitting(true);
    dispatch(createEvent({
      holdingId: values.holding,
      data: mapEventToServerData(values),
    })).then((data) => {
      setIsSubmitting(false);
      if (data.error) {
        dispatch(showNotification({
          type: 'error',
          message: data?.payload?.error?.message || 'Сталася помилка',
        }));
      } else {
        const { holdingId, caseId } = data.payload;
        history.push(`${routeBits.event}/${caseId}/${holdingId}`);
        dispatch(showNotification({
          type: 'success',
          message: 'Подію успішно створенно',
        }));
      }
    });
  };

  const showNotValidFormNotification = () => {
    dispatch(showNotification({
      type: 'error',
      message: FORM_HAS_ERRORS,
    }));
  };

  return (
    <Box className={appClasses.root}>
      <PageHeader
        title={CREATE_EVENT_TITLE}
        extraLine={(
          <div id="nav-menu" />
        )}
      >
        <div className={appClasses.pageHeader}>
          <Typography variant="h1">{CREATE_EVENT_TITLE}</Typography>
          <Box>
            <Button
              startIcon={<CancelOutlined/>}
              type="cart-secondary"
              component={Link}
              to={routes.main}
              disabled={isSubmitting}
              data-test="cancel-create-event-btn"
            >
              {CANCEL}
            </Button>
            <Button
              startIcon={<CheckCircleOutline/>}
              type="cart-secondary"
              className={appClasses.actionControl}
              disabled={isSubmitting}
              onClick={() => {
                formik.validateForm()
                  .then(errors => {
                    const isValid = isEmpty(errors);
                    if (isValid) {
                      formik.submitForm()
                    } else {
                      showNotValidFormNotification();
                    }
                  })
                  .catch(() => {
                    showNotValidFormNotification();
                  });
              }}
              data-test="create-event-btn"
            >
              {SAVE}
            </Button>
          </Box>
        </div>
      </PageHeader>
      <ContentSpinner isLoading={isSubmitting}>
        <CreateEventForm
          initBlockValues={initBlockValues}
          setFieldValue={(field, value, shouldValidate) => {
            formik.setFieldValue(field, value, shouldValidate);
            const fieldError = get(formik.errors, field);
            if (fieldError) formik.setFieldError(field);
          }}
          values={formik.values}
          errors={formik.errors}
        />
      </ContentSpinner>
      <UniqueInventoryNumberDialog
        isOpen={Boolean(uniqueInventoryNumberList)}
        uniqueInventoryNumberList={uniqueInventoryNumberList}
        handleActiveAction={() => handleSubmitForm(formik.values)}
        handleClose={() => setUniqueInventoryNumberList(null)}
      />
    </Box>
  );
};

export default CreateEvent;
