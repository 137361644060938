import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    appbarRoot: {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 8%)',
      color: '#989898',
      minWidth: 830,
      zIndex: 1200,
    },
    toolbarRoot: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      fontSize: 14,
      height: 'auto',
      color: '#989898',
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    headerLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    notificationBtn: {
      margin: '0 24px 0 auto',
    },
    usersName: {
      padding: '0 24px',
      fontSize: 12,
      color: theme.colors.fullBlack,
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    accountWrapper: {
      display: 'flex',
    },
    defaultBtnStyle: {
      margin: '0 0 0 2px',
    },
    logoLink: {
      lineHeight: 'initial',
      height: 30,
    },
    logo: {
      height: 30,
      width: 'auto',
    },
  }
));

export default useStyles;
