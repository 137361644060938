import React from 'react';
import { Edit } from '@material-ui/icons';
import { EDIT } from 'constants/texts';
import { Button } from 'components/ui-lib';

import useAppStyles from 'containers/App/AppStyles';

const EditCaseButton = ({ onClick }) => {
  const appClasses = useAppStyles();

  return (
    <Button
      startIcon={<Edit/>}
      type="cart-secondary"
      className={appClasses.actionControl}
      disabled={false}
      onClick={onClick}
      size="large"
      data-test="edit-button"
    >
      {EDIT}
    </Button>
  );
};

export default EditCaseButton;
