import React from 'react';
import PropTypes from 'prop-types';
import OverflowTip from 'components/ui-lib/OverflowTip';
import { darkGray, black } from 'components/ui-lib/ThemeProvider/theme/colors';

const ObjectsCell = ({ description, objects, totalCount }) => {
  const joinedObjectsNames = objects.map(({ name }) => name).join(', ');
  const notClampedEndAdornmentText = totalCount - objects.length > 0 ? totalCount - objects.length : null;
  return (
    <>
      <OverflowTip
        textProps={{ style: { color: darkGray, fontSize: 14, lineHeight: 1.29 } }}
        maxLine={1}
        text={description}
        tooltipTitle={description}
        data-test="description"
      />
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <OverflowTip
            textProps={{ style: { color: black, fontSize: 14 } }}
            maxLine={2}
            text={joinedObjectsNames}
            getEllipsis={(clampedText) => {
              const visibleObjects = clampedText.split(', ').length;
              const clampedObjectsCount = totalCount - visibleObjects;
              if (clampedObjectsCount > 0) {
                return (
                  <div style={{ display: 'inline' }}>
                    ...
                    <div style={{
                      display: 'inline',
                      color: darkGray,
                      fontSize: 14
                    }}>{` (+${clampedObjectsCount})`}</div>
                  </div>
                );
              }
              return '...';
            }}
            tooltipTitle={joinedObjectsNames}
            data-test="objects-names"
          />
        </div>
        {notClampedEndAdornmentText && (
          <div data-test="more-count" style={{
            display: 'inline-flex',
            color: darkGray,
            fontSize: 14,
            width: 50,
            alignItems: 'flex-end',
            paddingLeft: 16
          }}>{`(+${notClampedEndAdornmentText})`}</div>
        )}
      </div>
    </>
  );
};

ObjectsCell.propTypes = {
  description: PropTypes.string,
  objects: PropTypes.arrayOf(PropTypes.shape({
    mane: PropTypes.string,
  })),
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ObjectsCell.defaultProps = {
  description: '',
  objects: [],
  objectsCount: '',
};

export default ObjectsCell;
