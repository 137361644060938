import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from 'store/notifications';
import { startPolling, stopPolling } from 'store/pollingSlice';
import { getArchiveStatus } from 'store/event';

const useArchiveStatusPolling = () => {
	const dispatch = useDispatch();

	const startPollingProcess = useCallback((archiveCorrelationId, type = 'case', casesIds = []) => {
		const showError = () => {
			dispatch(showNotification({
				type: 'error',
				message: "Виникла помилка при формуванні документів. Спробуйте сформувати документи заново.",
			}));
		};

		if (!archiveCorrelationId) {
			showError();
			return;
		}
			const intervalId = setInterval(async () => {
				const result = await dispatch(getArchiveStatus({archiveCorrelationId}));
				if (result?.payload?.headers['is-uploaded'] === 'true') {
					let message = '';
					if (casesIds.length === 1) {
						const [casesId] = casesIds;
						message = `Документи по події ${casesId} сформовано`;
					} else if (casesIds.length >= 10) {
						const casesIdsString = casesIds.slice(0, 9).join(', ');
						message = `Документи по подіям ${casesIdsString} сформовано`;
					} else if (casesIds.length > 1 && casesIds.length < 10) {
						message = `Документи по подіям ${casesIds.join(', ')} сформовано`;
					} else if (casesIds.length === 0 && type === 'casesWithDocuments') {
						message = `Документи по всім обраним подіям сформовано`;
					}
					dispatch(showNotification({
						type: 'success',
						message: message,
					}));
					clearInterval(intervalId);
					dispatch(stopPolling({ processId: archiveCorrelationId }));
				}
				if (result?.error) {
					clearInterval(intervalId);
					dispatch(stopPolling({ processId: archiveCorrelationId }));
					showError();
				}
			}, 5000);

			dispatch(startPolling({ process: { processId: archiveCorrelationId, intervalId, type, casesIds } }));
	}, [dispatch]);

	return startPollingProcess;
};

export default useArchiveStatusPolling;
