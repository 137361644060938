import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Slide,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CANCEL,
  REPORT_DAMAGED_PROPERTY_DTEK,
} from 'constants/texts';

import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PowerBiDialog = ({ open, handleClose, handleApplyFilters, onFilterChange, resetFilters }) => {
  const classes = useStyles();
  const powerBiLink = 'https://app.powerbi.com/reportEmbed?reportId=f7db1930-581a-4e9e-a108-bba87db767af&autoAuth=true&ctid=468254ae-9aad-46c9-a2a0-70ae5657050e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly80NjgyNTRhZTlhYWQ0NmM5YTJhMDcwYWU1NjU3MDUwZS1hcGkuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D';

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-test="filters-dialog"
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h1" className={classes.headerTitle}
                      data-test="dialog-title">{REPORT_DAMAGED_PROPERTY_DTEK}</Typography>
          <Box>
            <Button
              className={classes.cancelButton}
              size="large"
              onClick={handleClose}
              startIcon={<Close/>}
              type="cart-secondary"
              data-test="cancel-button"
            >
              {CANCEL}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
       <iframe
         style={{ width: '100%', height: 'calc(100vh - 64px - 32px)' }}
         src={powerBiLink}
         title={REPORT_DAMAGED_PROPERTY_DTEK}
         allowFullScreen
       />
      </div>
    </Dialog>
  );
};

PowerBiDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

PowerBiDialog.defaultProps = {
  open: false,
  handleClose: () => {
  },
};

export default PowerBiDialog;

