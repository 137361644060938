import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NotificationAlert from 'components/NotificationAlert';

import { getNotificationData, closeNotification } from 'store/notifications';

const useStyles = makeStyles(() => ({
  anchorOriginTopRight: {
    top: 80,
    right: 16,
  },
}
));

const NotificationsManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    open,
    type,
    message,
  } = useSelector(getNotificationData);

  const handleClose = () => dispatch(closeNotification());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      classes={{
        anchorOriginTopRight: classes.anchorOriginTopRight,
      }}
    >
      <NotificationAlert type={type} onClose={handleClose}>
        {message}
      </NotificationAlert>
    </Snackbar>
  );
};

export default NotificationsManager;
