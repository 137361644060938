import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get, head, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import routes, { routeBits } from 'routes';
import { Typography, Button, DialogDefault } from 'components/ui-lib';
import PageHeader from 'components/PageHeader';
import FilterButton from 'components/FilterButton';
import CasesTable from 'pages/Main/components/CasesTable';
import { ReactComponent as FileDownloadIcon } from 'assets/icons_file_download.svg';
import { ReactComponent as PersonAddIcon } from 'assets/icons-person-add.svg';
import ProgressBar from 'components/Circular/ProgressBar';
import {
	EVENT_PAGE_TITLE,
	EXPORT,
	ADD_CASE,
	ASSIGN_RESPONSIBLE,
	ASSIGN,
	ASSIGN_RESPONSIBLE_OF_ONE_HOLDING,
	SUCCESSFULLY_APPOINTED,
	ERROR_ASSIGNING_RESPONSIBLE,
	ERROR_EVENTS_EXPORT,
	NOT_ALL_SELECTED_CASES_WITH_VALID_STATUS,
	BECOME_RESPONSIBLE,
	SOME_OF_SELECTED_CASES_HAS_RESPONSIBLE,
	REPORT,
	ERROR_EVENTS_DOCUMENTS_EXPORT,
	EXPORT_DOCUMENTS,
} from 'constants/texts';

import useAppStyles from 'containers/App/AppStyles';
import useStyles from './MainStyles';
import FiltersModal from './components/FiltersModal';
import {
	loadCasesList,
	onFilterUpdate,
	applyFilters,
	resetTemporaryFiltersState,
	resetFiltersState,
	importCases,
	setSelectedCases,
	setResponsibleForCases,
} from 'store/casesList';
import Pagination from 'components/ui-lib/Pagination';
import { LOADING } from 'constants/main';
import Can from 'containers/RoleModel/Can';
import { CREATE_EVENT } from 'constants/permissions';
import { showNotification } from 'store/notifications';
import perPageParams from 'constants/pagination';
import MainPageResponsibleSelect from './components/MainPageResponsibleSelect';
import { EVENT_REGISTRATION_RESPONSIBLE, KC_LAWYER, WORKGROUP_HEAD } from 'constants/roles';
import PowerBiDialog from './components/PowerBiDialog';
import useArchiveStatusPolling from 'hooks/useArchiveStatusPolling';
import useExcelStatusPolling from 'hooks/useExcelStatusPolling';

const Main = () => {
	const classes = useStyles();
	const appClasses = useAppStyles();
	const dispatch = useDispatch();
	const history = useHistory();


	const {
		casesList,
		totalCount,
		limit,
		offset,
		filtersState,
		temporaryFiltersState,
		loading,
		error,
		selectedCases,
	} = useSelector((state) => state.casesList);
	const { id: currentUserId, roles = [] } = useSelector((state) => state.account?.user);
	const processes = useSelector(state => state.polling.processes);

	const [isVisibleFiltersOverlay, setIsVisibleFiltersOverlay] = useState(false);
	const [isOpenMultipleAssign, setIsOpenMultipleAssign] = useState(false);
	const [responsibleId, setResponsibleId] = useState('');
	const [isOpenPowerBiDialog, setIsOpenPowerBiDialog] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const startExcelStatusPolling = useExcelStatusPolling();

	const isExportCasesWithDocumentsDisabled = useMemo(() => {
		return processes.some(({ type, casesIds }) => type === 'casesWithDocuments');
	}, [processes]);

	const isExportCasesDisabled = useMemo(() => {
		return processes.some(({ type, casesIds }) => type === 'cases');
	}, [processes]);

	useEffect(() => {
		dispatch(loadCasesList({ limit, offset, filtersState }));
	}, []);

	useEffect(() => {
		if (!error) return;
		dispatch(showNotification({
			type: 'error',
			message: error?.message || 'Сталася помилка',
		}));
	}, [error]);

	const onRowClick = (id, holdingId) => {
		history.push({
			pathname: `${routeBits.event}/${id}/${holdingId}`,
		});
	};

	const handleChangePagination = ({ limit: newLimit, offset: newOffset }) => {
		if (limit !== newLimit || offset !== newOffset) {
			dispatch(loadCasesList({ limit: newLimit, offset: newOffset, filtersState }));
		}
	};

	const exportCasesToFile = (withDocuments) => {
		setIsExporting(true);
		dispatch(importCases({ filtersState, selectedCases, withDocuments })).then((data) => {
			setIsExporting(false);
			console.log("data", data);
			if (data.error) {
				dispatch(showNotification({
					type: 'error',
					message: data?.payload?.error?.message || (withDocuments ? ERROR_EVENTS_DOCUMENTS_EXPORT : ERROR_EVENTS_EXPORT
					),
				}));
				return;
			}

			if (withDocuments) {
				const archiveCorrelationId = data?.payload?.data?.archiveCorrelationId;
				const excelCorrelationId = data?.payload?.data?.excelCorrelationId;
				startExcelStatusPolling(excelCorrelationId, 'casesWithDocuments', selectedCases.map(({ id }) => id), archiveCorrelationId);
			} else {
				const correlationId = data?.payload?.data?.correlationId;
				startExcelStatusPolling(correlationId, 'cases', selectedCases.map(({ id }) => id));
			}
		});
	};

	const handleAssignToMe = () => {
		dispatch(setResponsibleForCases({
			selectedCases,
			responsibleId: currentUserId,
			holdingId: get(head(selectedCases), 'holdingId')
		})).then((data) => {
			if (data.error) {
				dispatch(showNotification({
					type: 'error',
					message: data?.payload?.error?.message || ERROR_ASSIGNING_RESPONSIBLE,
				}));
			} else {
				dispatch(showNotification({
					type: 'success',
					message: SUCCESSFULLY_APPOINTED,
				}));
				dispatch(setSelectedCases({
					selectedCases: [...selectedCases.map(selectedCase => ({
							...selectedCase,
							responsibleId: currentUserId
						}
					))]
				}));
				dispatch(loadCasesList({ limit, offset, filtersState }));
			}
		});
	};

	const isUniqueHoldingIdInSelectedCases = selectedCases.every(({ holdingId } = {}) => holdingId === get(head(selectedCases), 'holdingId'));

	const isExportCasesToFileButtonVisible = roles.includes(WORKGROUP_HEAD) || roles.includes(KC_LAWYER);
	const isAssignToMeButtonVisible = roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && !roles.includes(WORKGROUP_HEAD);
	const isMultipleAssignButtonVisible = roles.includes(WORKGROUP_HEAD);

	const allSelectedCasesWithValidStatus = selectedCases.every(({ status }) => ['InWork', 'Registered', 'DocumentsRevised'].includes(status));
	const someCaseHasResponsible = isAssignToMeButtonVisible && selectedCases.some(({ responsibleId }) => responsibleId);
	const someCaseHasDocumentsFinalizedStatus = isAssignToMeButtonVisible && selectedCases.some(({ status }) => status === 'DocumentsFinalized');

	const isMultipleResponsibleSelectDisabled = isEmpty(selectedCases)
		|| !isUniqueHoldingIdInSelectedCases
		|| !allSelectedCasesWithValidStatus;

	const isAssignToMeSelectDisabled = isEmpty(selectedCases)
		|| !isUniqueHoldingIdInSelectedCases
		|| someCaseHasResponsible
		|| someCaseHasDocumentsFinalizedStatus;

	const getMultipleResponsibleSelectTooltip = () => {
		if (!isEmpty(selectedCases)
			&& (isMultipleResponsibleSelectDisabled || isAssignToMeSelectDisabled
			)) {
			return (
				<div>
					{!isUniqueHoldingIdInSelectedCases && (
						<div className={classes.tooltipItem}>
							{ASSIGN_RESPONSIBLE_OF_ONE_HOLDING}
						</div>
					)}
					{someCaseHasResponsible && isAssignToMeSelectDisabled && (
						<div className={classes.tooltipItem}>
							{SOME_OF_SELECTED_CASES_HAS_RESPONSIBLE}
						</div>
					)}
					{((!allSelectedCasesWithValidStatus && isMultipleResponsibleSelectDisabled)
						|| (someCaseHasDocumentsFinalizedStatus && isAssignToMeSelectDisabled)
					) && (
						<div className={classes.tooltipItem}>
							{NOT_ALL_SELECTED_CASES_WITH_VALID_STATUS}
						</div>
					)}
				</div>
			);
		}
		return '';
	};

	return (
		<Box className={classes.root}>
			<PageHeader
				title={EVENT_PAGE_TITLE}
			>
				<div className={appClasses.pageHeader}>
					<Typography variant="h1" data-test="page-title">{EVENT_PAGE_TITLE}</Typography>
					<Box>

						<Button
							className={classes.fileDownloadButton}
							size="large"
							// startIcon={<PersonAddIcon/>}
							onClick={() => setIsOpenPowerBiDialog(true)}
							type="cart-secondary"
							data-test="power-bi-button"
						>
							{REPORT}
						</Button>

						{(isAssignToMeButtonVisible || isMultipleAssignButtonVisible
						) && (
							<Tooltip title={getMultipleResponsibleSelectTooltip()}>
								<div style={{ display: 'inline-block' }}>
									{isMultipleAssignButtonVisible && (
										<Button
											disabled={isMultipleResponsibleSelectDisabled}
											className={classes.fileDownloadButton}
											size="large"
											startIcon={<PersonAddIcon/>}
											onClick={() => setIsOpenMultipleAssign(true)}
											type="cart-secondary"
											data-test="assign-button"
										>
											{isEmpty(selectedCases) ? ASSIGN_RESPONSIBLE : `${ASSIGN_RESPONSIBLE} (${selectedCases.length})`}
										</Button>
									)}
									{isAssignToMeButtonVisible && (
										<Button
											disabled={isAssignToMeSelectDisabled}
											className={classes.fileDownloadButton}
											size="large"
											startIcon={<PersonAddIcon/>}
											onClick={handleAssignToMe}
											type="cart-secondary"
											data-test="assign-button"
										>
											{isEmpty(selectedCases) ? BECOME_RESPONSIBLE : `${BECOME_RESPONSIBLE} (${selectedCases.length})`}
										</Button>
									)}
								</div>
							</Tooltip>
						)}

						{isExportCasesToFileButtonVisible && (
							<Button
								disabled={isEmpty(casesList) || isExportCasesWithDocumentsDisabled}
								className={classes.fileDownloadButton}
								size="large"
								startIcon={<FileDownloadIcon/>}
								onClick={exportCasesToFile}
								type="cart-secondary"
								data-test="export-with-documents-button"
							>
								{isEmpty(selectedCases) ? EXPORT_DOCUMENTS : `${EXPORT_DOCUMENTS} (${selectedCases.length})`}
							</Button>

						)}

						<Button
							disabled={isEmpty(casesList) || isExportCasesDisabled}
							className={classes.fileDownloadButton}
							size="large"
							startIcon={<FileDownloadIcon/>}
							onClick={() => exportCasesToFile(false)}
							type="cart-secondary"
							data-test="export-button"
						>
							{isEmpty(selectedCases) ? EXPORT : `${EXPORT} (${selectedCases.length})`}
						</Button>
						<Can
							perform={CREATE_EVENT}
							yes={() => (
								<Button
									className={classes.createButton}
									size="large"
									startIcon={<Add/>}
									component={Link}
									to={routes.createEvent}
									type="cart-secondary"
									data-test="create-button"
								>
									{ADD_CASE}
								</Button>
							)}
						/>
						<FilterButton
							onClickFilterButton={() => setIsVisibleFiltersOverlay(!isVisibleFiltersOverlay)}
							hasActiveFilters={!isEmpty(filtersState)}
						/>
					</Box>
				</div>
			</PageHeader>
			<CasesTable
				cases={casesList}
				onRowClick={onRowClick}
				loading={loading}
				selectedCases={selectedCases}
				setSelectedCases={selectedCases => dispatch(setSelectedCases({ selectedCases }))}
			/>
			<Pagination
				limit={limit}
				offset={offset}
				itemsCount={totalCount}
				limitsValues={perPageParams}
				handleChangePagination={handleChangePagination}
			/>
			<PowerBiDialog
				open={isOpenPowerBiDialog}
				handleClose={() => {
					setIsOpenPowerBiDialog(false);
				}}
			/>
			<FiltersModal
				open={isVisibleFiltersOverlay}
				resetFilters={() => {
					setIsVisibleFiltersOverlay(false);
					if (!isEmpty(filtersState)) dispatch(loadCasesList({
						limit,
						offset: 0,
						filtersState: {}
					}));
					dispatch(resetFiltersState());
					dispatch(setSelectedCases({ selectedCases: [] }));
				}}
				handleClose={() => {
					setIsVisibleFiltersOverlay(false);
					dispatch(resetTemporaryFiltersState());
				}}
				onFilterChange={(filterName, value) => dispatch(onFilterUpdate({ filterName, value }))}
				handleApplyFilters={() => {
					setIsVisibleFiltersOverlay(false);
					dispatch(applyFilters());
					dispatch(loadCasesList({ limit, offset: 0, filtersState: temporaryFiltersState }));
					dispatch(setSelectedCases({ selectedCases: [] }));
				}}
			/>
			{(loading === LOADING || isExporting
			) && <ProgressBar/>}
			<DialogDefault
				title={ASSIGN_RESPONSIBLE}
				open={isOpenMultipleAssign}
				handleClose={() => {
					setIsOpenMultipleAssign(false);
					setResponsibleId('');
				}}
				activeActionText={ASSIGN}
				handleActiveAction={() => {
					setIsOpenMultipleAssign(false);
					dispatch(setResponsibleForCases({
						selectedCases,
						responsibleId,
						holdingId: get(head(selectedCases), 'holdingId')
					})).then((data) => {
						if (data.error) {
							dispatch(showNotification({
								type: 'error',
								message: ERROR_ASSIGNING_RESPONSIBLE,
							}));
						} else {
							dispatch(showNotification({
								type: 'success',
								message: SUCCESSFULLY_APPOINTED,
							}));
						}
						setResponsibleId('');
					});
				}}
				classes={{ paper: classes.assignResponsible }}
			>
				<MainPageResponsibleSelect
					value={responsibleId}
					onChange={({ id = '' } = {}) => {
						setResponsibleId(id);
					}}
				/>
			</DialogDefault>
		</Box>
	);
};

export default Main;
