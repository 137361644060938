import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  positionArea: {
    display: 'grid',
    gridTemplateAreas: '"field-1 field-1 . field-2 field-2" "field-3 field-4 . . ." "field-5 field-5 field-5 field-5 field-5"',
    gridTemplateColumns: '1fr 1fr 32px 1fr 1fr',
    columnGap: 24,
    rowGap: 16,
    [theme.breakpoints.up(1600)]: {
      gridTemplateAreas: '"field-1 field-1 field-3 field-4 . field-2 field-2 field-2 field-2" "field-5 field-5 field-5 field-5 field-5 field-5 field-5 field-5 field-5"',
      gridTemplateColumns: 'repeat(4, 1fr) 32px repeat(4, 1fr)',
    },
  },
  formSection: {
    borderBottom: '1px solid #f3f3f3', // _color_
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  eventsAttrs: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventsAttrsRight: {
    width: '45%',
  },
  eventsAttrsLeft: {
    width: '45%',
  },
  halfBlock: {
    width: '45%',
  },
  formControlRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: 72,
    margin: 0,
    '& .MuiFormHelperText-root': {
      marginBottom: 8,
    },
  },
  formControlRowWithMargin: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 32,
  },
  shadowBlock: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: '1px solid #f3f3f3',
    padding: 16,
    borderRadius: 4,
    marginBottom: 16,
  },
  shadowBlockTitle: {
    alignItems: 'center',
    marginBottom: 4,
    height: 48,
  },
  blockForm: {
    marginBottom: 24,
  },
  eventsCheckBoxes: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  blockDivider: {
    margin: '24px 0',
  },
}
));

export default useStyles;
