import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import FullWidthButton from 'components/FullWidthButton';
import { useSelector } from 'react-redux';
import {
  DISPATCHER,
  FINANCIER,
  EVENT_REGISTRATION_RESPONSIBLE,
  WORKGROUP_HEAD,
  BUSINESS_LAWYER,
  KC_LAWYER,
  ECOLOGIST, ASSISTANT
} from 'constants/roles';

const AddFilesButton = ({ initialStatus, authorName, disabled, isFolderCreation, onClick }) => {
  const { roles = [], name: currentUserName } = useSelector((state) => state.account?.user);

  const [canAdd, setCanAdd] = useState(false);

  useEffect(() => {
    let canAddFiles = false;
    if (roles.includes(DISPATCHER) && initialStatus === 'Registered' && currentUserName === authorName) {
      canAddFiles = true;
    }
    if (roles.includes(FINANCIER) && ['Registered', 'InWork', 'DocumentsRevised'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && ['Registered', 'InWork', 'DocumentsRevised'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(WORKGROUP_HEAD) && ['Registered', 'InWork', 'DocumentsRevised'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(BUSINESS_LAWYER) && ['Registered', 'InWork', 'DocumentsRevised', 'DocumentsPrepared'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(KC_LAWYER) && ['Registered', 'InWork', 'DocumentsRevised', 'DocumentsPrepared', 'DocumentsCheckedWithoutRemarks'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(ECOLOGIST) && ['Registered', 'InWork', 'DocumentsRevised'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (roles.includes(ASSISTANT) && ['InWork', 'DocumentsRevised'].includes(initialStatus)) {
      canAddFiles = true;
    }
    if (canAddFiles) setCanAdd(canAddFiles);
  }, [initialStatus]);

  if (!canAdd) return null;

  return (
    <FullWidthButton
      disabled={disabled || isFolderCreation}
      fullWidth
      size="large"
      type="cart-dashed"
      startIcon={isFolderCreation ? <CircularProgress size={22}/> : <DescriptionOutlined/>}
      onClick={onClick}
      data-test="add-files-button"
    >
      Додати файли
    </FullWidthButton>
  );
};

export default AddFilesButton;
