import React from 'react';
import ReactDom from 'react-dom';
import { Button } from 'components/ui-lib';
import { BUSINESS_LAWYER, KC_LAWYER } from 'constants/roles';
import { ASSIGN_TO_MYSELF } from 'constants/texts';
import useStyles from './styles';

const ActionButtons = ({ data, currentUserName, roles, handleAssignPerformer, handleReturnFromFinalStatus }) => {
  const classes = useStyles();
  const canSetBusinessLawyer = data?.status
    && data?.status !== 'DocumentsFinalized'
    && roles.includes(BUSINESS_LAWYER)
    && !data?.performers.find(({ name, role } = {}) => name === currentUserName && role === BUSINESS_LAWYER);

  const canSetKCLawyer = data?.status
    && data?.status !== 'DocumentsFinalized'
    && roles.includes(KC_LAWYER)
    && !data?.performers.find(({ name, role } = {}) => name === currentUserName && role === KC_LAWYER);

  const canSetSelfToPerformers = canSetBusinessLawyer || canSetKCLawyer;
  const canReturnFromFinalStatus = data?.status === 'DocumentsFinalized' && roles.includes(KC_LAWYER)

  if (!canSetSelfToPerformers && !canReturnFromFinalStatus) return null;

  const pageFooterElement = document.getElementById('pageFooter');

  return ReactDom.createPortal(
    <>
      <div className={classes.placeholderElement} />
      <div className={classes.buttonsWrapper}>
        {canSetBusinessLawyer && (
          <Button
            className={classes.assignPerformerButton}
            variant="contained"
            color="primary"
            onClick={()=> handleAssignPerformer(BUSINESS_LAWYER)}
            data-test='show-files-button'
          >
            {!canSetKCLawyer ? ASSIGN_TO_MYSELF : 'Назначити себе Юристом Бізнес'}
          </Button>
        )}
        {canSetKCLawyer && (
          <Button
            className={classes.assignPerformerButton}
            variant="contained"
            color="primary"
            onClick={() => handleAssignPerformer(KC_LAWYER)}
            data-test='show-files-button'
          >
            {!canSetBusinessLawyer ? ASSIGN_TO_MYSELF : 'Назначити себе Юристом КЦ'}
          </Button>
        )}
        {canReturnFromFinalStatus && (
          <Button
            className={classes.assignPerformerButton}
            variant="contained"
            color="primary"
            onClick={handleReturnFromFinalStatus}
            data-test='return-from-final-status'
          >
            {'Повернути подію в роботу'}
          </Button>
        )}
      </div>
    </>,
    pageFooterElement
  );
};

export default ActionButtons;
