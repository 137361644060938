import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formHelperText: {
    display: ({ readOnly }) => (readOnly ? 'none' : 'block'
    ),
    fontSize: 12,
    marginLeft: 16,
    color: ({ error }) => (error ? theme.colors.error : theme.colors.darkGray
    ),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: ({ readOnly }) => (readOnly ? 13 : 0
    ),
  },
  inputClass: {
    '&.form-control': {
      color: ({ error }) => (error ? theme.colors.error : theme.colors.black
      ),
      border: ({ error }) => (`1px solid ${error ? theme.colors.error : theme.colors.black20}`
      ),
      padding: ({ readOnly }) => (readOnly ? '11px 0px 11px 32px' : '10px 14px 10px 58px'
      ),
      width: '100%',
      '&:hover': {
        borderColor: ({ error }) => (error ? theme.colors.error : theme.colors.primaryLight
        ),
      },
      '&:focus': {
        boxShadow: ({ error }) => (`0 0 0 1px ${error ? theme.colors.error : theme.colors.primaryLight}`
        ),
        borderColor: ({ error }) => (error ? theme.colors.error : theme.colors.primaryLight
        ),
      },
      '&::placeholder': {
        color: ({ error }) => (error ? theme.colors.error : theme.colors.black20
        ),
      },
      '&:disabled': {
        border: 'none',
        cursor: 'inherit',
      },
    },
  },
  containerClass: {
    '& .special-label': {
      color: ({ error }) => (error ? theme.colors.error : theme.colors.darkGray
      ),
      backgroundColor: ({ readOnly }) => (readOnly ? 'transparent' : theme.colors.white
      ),
      left: ({ readOnly }) => (readOnly ? 0 : 10
      ),
      padding: ({ readOnly }) => (readOnly ? 0 : '0px 5px'
      ),
      top: -7,
      lineHeight: 1,
      fontSize: 12,
    },
    '& .selected-flag': {
      padding: ({ readOnly }) => (readOnly ? '0 0 0 0px' : '0 0 0 11px'
      ),
      width: ({ readOnly }) => (readOnly ? 30 : 52
      ),
    },
  },
  focused: {
    '& .special-label': {
      color: ({ error }) => (error ? theme.colors.error : theme.colors.primaryLight
      ),
    },
  },
  greyColored: {
    color: ({ error }) => (error ? theme.colors.error : theme.colors.darkGray
    ),
  },
}
));

export default useStyles;
