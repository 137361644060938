import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      backgroundColor: theme.colors.white,
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.colors.black10}`,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerTitle: {
      color: theme.colors.black,
    },
    cancelButton: {
      letterSpacing: 'normal',
      color: theme.colors.error,
      marginRight: 24,
      fontSize: 16,
    },
    clearFiltersButton: {
      letterSpacing: 'normal',
      color: theme.colors.primaryLight,
      marginRight: 24,
      fontSize: 16,
    },
    applyButton: {
      letterSpacing: 'normal',
      fontSize: 16,
    },
    disabled: {
      color: theme.colors.darkGray,
    },
    enabled: {
      color: theme.colors.primaryLight,
    },
    eventsAttrs: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    eventsAttrsRight: {
      width: 'calc(50% - 24px)',
    },
    eventsAttrsLeft: {
      width: 'calc(50% - 24px)',
    },
    halfBlock: {
      width: 'calc(50% - 24px)',
    },
    flexWrapBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    formControlRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      height: 72,
      margin: 0,
    },
    customTagsWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      margin: 0,
    },
    content: {
      overflowY: 'scroll',
    },
    section: {
      padding: '16px 40px 24px 40px',
      '&:first-child': {
        borderBottom: `1px solid ${theme.colors.black10}`,
      },
    },
    sectionTitle: {
      marginBottom: 8,
    },
    lossesEndAdornment: {
      fontSize: 14,
      color: theme.colors.darkGray,
      fontWeight: 'normal',
      minWidth: 112,
    },
    amountInput: {
      marginLeft: 16,
      maxWidth: '30%',
      width: '30%',
    },
  }
));

export default useStyles;
