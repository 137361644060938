import React, { useState, useEffect, useContext } from 'react';
import PropType from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars-2';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {
  ClickAwayListener, Paper, IconButton, Typography, Checkbox, Grid, Button,
} from '@material-ui/core';
import {
  ArrowDropUp, Add,
} from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as CloseIcon } from './assets/error.svg';

import useStyles from './styles';

function FilterElement(props) {
  const {
    value = [],
    multiple,
    label,
    options = [],
    onChange,
    valueKey = 'id',
    'data-test': dataTest,
    disabled,
  } = props;
  const classes = useStyles({ disabled });

  const visibleOptionsCount = 3;
  const optionHeight = 48;
  const maxOptionWrapperHeight = 250;

  const [isVisibleSearch, handleChangeSearchState] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleDisplaySearch = () => {
    if (!isVisibleSearch) {
      setFocused(true);
    }
    handleChangeSearchState(!isVisibleSearch);
  };

  const clickAway = () => {
    if (focused) setFocused(false);
    if (!isVisibleSearch) return;
    handleChangeSearchState(!isVisibleSearch);
  };

  const onFilterChanged = (option) => {
    const key = valueKey || 'id';
    const value = get(option, key);
    if (onChange) {
      onChange(value);
    }
    if (!multiple) handleDisplaySearch();
  };

  const renderSearchForm = () => (
    <>
      <form noValidate spellCheck="false">
        <div
          data-test={`${dataTest}-wrapper`}
          className={classes.filterElementWrapper}
        >
          <>
            {isEmpty(value) && !isEmpty(options) && (
              <button
                className={classes.buttonOverlay}
                onClick={handleDisplaySearch}
                type="button"
                data-test="button-overlay"
              />
            )}
            <IconButton
              disabled={isEmpty(options)}
              data-test="toggle-filter-options-button"
              onClick={handleDisplaySearch}
              className={classes.filterOptionsCollapseBtn}
            >
              <SvgIcon
                component={!isVisibleSearch ? Add : ArrowDropUp}
              />
            </IconButton>
          </>
          <div
            className={clsx(classes.filterLabel,
              (!isEmpty(value)
              ) ? classes.filterLabelToTop : classes.filterLabelToCenter,
              focused ? classes.focusedLabel : classes.unfocusedLabel)}
          >
            {label}
          </div>
          {isEmpty(value) && <div className={classes.emptyLabel}/>}
          {!isEmpty(value) && (
            <div className={classes.activeOptionWrapper}>
              {value.map((valueItem) => {
                const option = find(options, { [valueKey]: valueItem });
                if (!option) return null;
                const { id, name: title } = option;
                return (
                  <Button
                    data-test={`clear-option-${id}`}
                    key={id}
                    onClick={() => onFilterChanged(option)}
                  >
                    <SvgIcon
                      component={CloseIcon}
                      className={classes.closeIcon}
                    />
                    <span
                      className={classes.activeOptionLabel}
                      data-test="active-option-title"
                    >
                        {title}
                      </span>
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </form>
    </>
  );

  const renderSearchSelect = () => (
    <div>
      <Paper classes={{ root: classes.selectWrapper121 }}>
        <Scrollbars
          style={{
            height: options.length > visibleOptionsCount
              ? maxOptionWrapperHeight
              : optionHeight * (options.length
            ),
          }}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style, backgroundColor: 'rgb(225, 225, 225)', borderRadius: 6, width: 4,
              }}
            />
          )}
          renderTrackVertical={({ style, ...props }) => {
            if (options.length > visibleOptionsCount) {
              return (
                <div
                  {...props}
                  style={{
                    ...style,
                    position: 'absolute',
                    backgroundColor: 'rgb(243, 243, 243)',
                    width: 4,
                    right: 8,
                    bottom: 2,
                    top: 2,
                    borderRadius: 3,
                  }}
                />
              );
            }
            return (<div/>
            );
          }
          }
        >
          <div classes={{ root: classes.searchMenuWrapper }}>
            {options.map((option) => {
              const { id, name: title } = option;
              const isSelected = () => {
                let isSelected = false;
                if (!isEmpty(value)
                  && find(value, it => it === get(option, valueKey, 'id'))) {
                  isSelected = true;
                }
                return isSelected;
              };
              return (
                <Button
                  key={id}
                  onClick={() => onFilterChanged(option)}
                  className={classes.menuItem}
                >
                  <div
                    style={{ display: 'flex' }}
                  >
                    <Checkbox
                      data-test="filterOptionCheckbox"
                      checked={isSelected()}
                      value="checkedB"
                      color="primary"
                    />
                    <Typography
                      variant="caption"
                      className={classes.optionLabel}
                      color="secondary">
                      {title}
                    </Typography>
                  </div>
                </Button>
              );
            })}
          </div>
        </Scrollbars>
      </Paper>
    </div>
  );

  return (
    <div className={clsx(classes.searchWrapper, focused ? classes.focused : classes.unfocused)}>
      <ClickAwayListener onClickAway={clickAway}>
        <div>
          {renderSearchForm()}
          {isVisibleSearch && renderSearchSelect()}
        </div>
      </ClickAwayListener>
    </div>
  );
}

FilterElement.propTypes = {
  value: PropType.arrayOf(PropType.oneOfType([
    PropType.string,
    PropType.number,
  ])),
  multiple: PropType.bool,
  title: PropType.string,
  location: PropType.shape({
    pathname: PropType.string,
    search: PropType.string,
  }),
  options: PropType.arrayOf(PropType.shape({
    id: PropType.oneOfType([
      PropType.string,
      PropType.number,
    ]),
    name: PropType.string,
  })),
  onChange: PropType.func,
};

export default FilterElement;
