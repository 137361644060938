import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundImage } from './assets/404.svg';
import { MAIN_PAGE, NOT_FOUND_TITLE, TRY_START_WITH } from 'constants/texts';

import useStyles from './styles';

function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.content} data-test="not-found-page">
      <NotFoundImage/>
      <div className={classes.label} data-test="not-found-page-title">
        {NOT_FOUND_TITLE}
      </div>
      <div className={classes.subLabel} data-test="not-found-page-subtitle">
        {TRY_START_WITH}
        &nbsp;
        <Link
          to={{
            pathname: '/',
          }}
          data-test="link-to-home-page"
        >
              <span
                data-test="linkToMainPage"
                className={classes.toHomePageLink}
              >
                {MAIN_PAGE}
              </span>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
