import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  COMPANY,
  DAMAGED_OBJECTS,
  DATE,
  TIME,
  EVENT_DESCRIPTION,
  EVENT_STATUS,
  HOLDING,
  TOTAL_AMOUNT_OF_LOSSES,
  UNIT,
  DAMAGE_CATEGORY,
  INVENTORY_NUMBER,
  LOSSES_AMOUNT,
  PROPERTY_NAME,
  PROPERTY_TYPE,
  RESTORATIVE_REPAIRS_CARRIED_OUT,
  EMERGENCY_REPAIRS_CARRIED_OUT,
  DAMAGED,
  DESTROYED,
  CAPTURED,
  TEMPORARILY_LOST_CONTROL,
  NO_CASES, ECONOMIC_LOSS,
  RETURNED_FOR_REVISION,
} from 'constants/texts';
import DateTimeCell from '../DateTimeCell';
import StructureCell from '../StructureCell';
import ObjectsCell from '../ObjectsCell';
import AmountCell from '../AmountCell';
import OverflowTip from 'components/ui-lib/OverflowTip';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { ReactComponent as RepairIcon } from 'assets/icons-repair.svg';
import { ReactComponent as DamageIcon } from 'assets/icons-damage.svg';
import { ReactComponent as CaptureIcon } from 'assets/icons-capture.svg';
import { ReactComponent as HryvniaIcon } from 'assets/hryvnia-symbol.svg';

import { SUCCEEDED } from "constants/main";

const INPUT_EMPTY_VALUE = '—';

const useRowStyles = makeStyles(theme => ({
    tableLink: {
      color: theme.colors.primaryLight
    }, root: {
      '& .MuiTableCell-root': {
        padding: '11px 8px',
      }, '& > *': {
        borderBottom: 'unset',
      }, '&:hover': {
        cursor: 'pointer', '& $tableLink': {
          textDecoration: 'underline',
        },
      },
    }, evenRow: {
      background: theme.colors.white,
    }, oddRow: {
      background: theme.colors.black5,
    }, collapsedHeaderRow: {
      '& .MuiTableCell-root': {
        paddingLeft: 8,
        paddingRight: 8,
        '&:first-child': {
          paddingLeft: 0,
        },
        fontSize: 12,
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.colors.black10}`,
        color: theme.colors.darkGray,
        lineHeight: 1.2,
      },
    }, collapsedContent: {
      paddingLeft: 16, paddingRight: 16, paddingBottom: 8, backgroundColor: theme.colors.black5
    }, collapsedRow: {
      height: 48, '& .MuiTableCell-root': {
        paddingLeft: 8,
        paddingRight: 8,
        '&:first-child': {
          paddingLeft: 0,
        },
        fontSize: 12,
        paddingTop: 'unset',
        paddingBottom: 'unset',
        borderBottom: `1px solid ${theme.colors.black10}`
      },
    }, restorativeRepairs: {
      color: theme.colors.success,
    }, emergencyRepairs: {
      color: theme.colors.alert,
    }, damaged: {
      color: theme.colors.alert,
    }, destroyed: {
      color: theme.colors.error,
    }, captured: {
      color: theme.colors.error,
    },
    economic_loss: {
      color: theme.colors.error,
      marginLeft: 4,
      marginTop: 8
    },
    temporarily_lost_control: {
      color: theme.colors.alert,
    }, propertyNameCell: {
      width: 274,
    }, inventoryNumberCell: {
      width: 170,
    }, lossesAmountCell: {
      width: 144,
    }, amountCellValue: {
      paddingLeft: 40,
    }, collapseHeaderItem: {
      color: theme.colors.darkGray,
    }, environmentalRisksCell: {
      width: 170,
    }, damageCategoryCell: {
      width: 90,
    },
    documentsRevisedStatusCount: {
      color: theme.colors.darkGray,
    },
  }
));
const useTableStyles = makeStyles(theme => ({
    tableContainer: {
      boxShadow: 'none',
      maxHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px)',
      minHeight: 'calc(100vh - 64px - 64px - 32px - 24px - 62px)'
    }, mainHeaderItem: {
      color: theme.colors.darkGray, fontSize: 12, lineHeight: 1.33,
    }, mainHeaderRow: {
      '& .MuiTableCell-root': {
        padding: '4px 8px', borderBottom: `1px solid ${theme.colors.black20}`
      },
    }, checkBoxCell: {
      width: 48,
    }, idCell: {
      width: 50,
    }, dateCell: {
      width: 80,
    }, structureCell: {
      width: 220,
    }, emptyCell: {
      width: 38,
    }, totalAmountCell: {
      width: 110,
    }, eventStatusCell: {
      width: 166,
    }, noCasesCell: {
      textAlign: 'center', borderBottom: '0 none', color: theme.colors.darkGray,
    },
  }
));

function Row(props) {
  const { row, onCheckBoxClick, selected, isEven, onRowClick } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const {
    propertyTypesNamesObject, environmentalRisksNamesObject, caseStatusesNamesObject,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  const showDocumentsRevisedStatusCount = Boolean(row.documentsRevisedStatusCount && row.documentsRevisedStatusCount > 0);

  const renderDamageCategory = (damageCategoryId) => {
    switch (damageCategoryId) {
      case 1: {
        return (<Tooltip title={RESTORATIVE_REPAIRS_CARRIED_OUT}>
            <RepairIcon className={classes.restorativeRepairs}/>
          </Tooltip>
        );
      }
      case 2: {
        return (<Tooltip title={EMERGENCY_REPAIRS_CARRIED_OUT}>
            <RepairIcon className={classes.emergencyRepairs}/>
          </Tooltip>
        );
      }
      case 4: {
        return (<Tooltip title={DAMAGED}>
            <DamageIcon className={classes.damaged}/>
          </Tooltip>
        );
      }
      case 6: {
        return (<Tooltip title={DESTROYED}>
            <DamageIcon className={classes.destroyed}/>
          </Tooltip>
        );
      }
      case 3: {
        return (<Tooltip title={CAPTURED}>
            <CaptureIcon className={classes.captured}/>
          </Tooltip>
        );
      }
      case 5: {
        return (<Tooltip title={TEMPORARILY_LOST_CONTROL}>
            <CaptureIcon className={classes.temporarily_lost_control}/>
          </Tooltip>
        );
      }
      case 7: {
        return (<Tooltip title={ECONOMIC_LOSS}>
            <HryvniaIcon className={classes.economic_loss} />
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (<React.Fragment>
      <TableRow
        className={clsx(classes.root, isEven ? classes.evenRow : classes.oddRow)}
        onClick={() => onRowClick(row.id, row.holdingId)}
        data-test={`row-${row.id}`}
      >
        <TableCell padding="checkbox" data-test={`select-${row.id}-checkbox-cell`}>
          <Checkbox
            padding="checkbox"
            checked={selected}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onCheckBoxClick(row.id);
            }}
            inputProps={{ 'aria-label': 'select case', 'data-test': `select-${row.id}-checkbox` }}
          />
        </TableCell>
        <TableCell
          className={classes.tableLink}
          data-test="id-cell"
        >
          {row.id}
        </TableCell>
        <TableCell data-test="date-cell">
          <DateTimeCell date={row.date}/>
        </TableCell>
        <TableCell data-test="structure-cell">
          <StructureCell
            holdingId={row.holdingId}
            enterpriseId={row.enterpriseId}
            unitId={row.unitId}
          />
        </TableCell>
        <TableCell data-test="description-objects-cell">
          <ObjectsCell
            description={row.description}
            objects={row.objectsSummary.objects}
            totalCount={row.objectsSummary.totalCount}
          />
        </TableCell>
        <TableCell
          data-test="status-cell"
        >
          <div
            data-test="status"
          >
            {get(caseStatusesNamesObject, `${row.status}.name`, row.status)}
          </div>
          {showDocumentsRevisedStatusCount && (
            <div
              data-test="documents-revised-status-count"
              className={classes.documentsRevisedStatusCount}
            >
              {`${RETURNED_FOR_REVISION}: ${row.documentsRevisedStatusCount}`}
            </div>
          )}
        </TableCell>
        <TableCell data-test="total-loss-cell">
          <AmountCell
            allObjectsLossFilled={row.allObjectsLossFilled}
            value={row.totalLoss}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              setOpen(!open);
              e.preventDefault();
              e.stopPropagation();
            }}
            data-test="expand-row-button"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow data-test={`row-${row.id}-objects-info`}>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.collapsedContent}>
              <Table aria-label="objects data">
                <TableHead>
                  <TableRow
                    className={classes.collapsedHeaderRow}
                    data-test="object-info-header-row"
                  >
                    <TableCell
                      className={clsx(classes.collapseHeaderItem, classes.propertyNameCell)}
                      data-test="object-property-name-header-cell"
                    >
                      {PROPERTY_NAME}
                    </TableCell>
                    <TableCell
                      className={classes.inventoryNumberCell}
                      data-test="object-inventory-number-header-cell"
                    >
                      {INVENTORY_NUMBER}
                    </TableCell>
                    <TableCell data-test="property-type-header-cell">{PROPERTY_TYPE}</TableCell>
                    <TableCell
                      className={classes.damageCategoryCell}
                      data-test="object-damage-category-header-cell"
                    >
                      {DAMAGE_CATEGORY}
                    </TableCell>
                    <TableCell
                      className={classes.lossesAmountCell}
                      data-test="object-losses-amount-header-cell"
                    >
                      {LOSSES_AMOUNT}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.objectsSummary.objects.map(({
                    name,
                    inventoryNumber,
                    propertyTypeId,
                    damageCategoryId,
                    totalLoss,
                  }, objectIndex) => {
                    const propertyTypeName = get(propertyTypesNamesObject, `${propertyTypeId}.name`, '');
                    return (<TableRow
                        key={`${name}_${objectIndex}`}
                        className={classes.collapsedRow}
                        data-test={`object-${objectIndex}`}
                      >
                        <TableCell component="th" scope="row" data-test="object-name-cell">
                          <OverflowTip
                            maxLine={2}
                            text={name}
                            tooltipTitle={name}
                            data-test="object-name"
                          />
                        </TableCell>
                        <TableCell data-test="object-inventory-number-cell">
                          {inventoryNumber ? (<OverflowTip
                              maxLine={2}
                              text={inventoryNumber}
                              tooltipTitle={inventoryNumber}
                              data-test="object-inventory-number"
                            />
                          ) : INPUT_EMPTY_VALUE}
                        </TableCell>
                        <TableCell data-test="object-property-type-cell">
                          <OverflowTip
                            maxLine={2}
                            text={propertyTypeName}
                            tooltipTitle={propertyTypeName}
                            data-test="object-property-type"
                          />
                        </TableCell>
                        <TableCell data-test={`object-damage-category-${damageCategoryId}-cell`}>
                          {renderDamageCategory(damageCategoryId)}
                        </TableCell>
                        <TableCell
                          className={classes.amountCellValue}
                          data-test="object-amount-cell"
                        >
                          <AmountCell value={totalLoss}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    date: PropTypes.string.isRequired,
    holdingId: PropTypes.number.isRequired,
    enterpriseId: PropTypes.number.isRequired,
    unitId: PropTypes.number.isRequired,
    totalLoss: PropTypes.number.isRequired,
    objectsSummary: PropTypes.shape({
      totalCount: PropTypes.number.isRequired, objects: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        inventoryNumber: PropTypes.string,
        propertyTypeId: PropTypes.number.isRequired,
        damageCategoryId: PropTypes.number.isRequired,
        bookValue: PropTypes.number,
      }).isRequired,).isRequired,
    }).isRequired,
  }).isRequired,
};

export default function CollapsibleTable({
  cases,
  onRowClick,
  loading,
  selectedCases,
  setSelectedCases
}) {
  const classes = useTableStyles();

  const tableRef = createRef(null);

  useEffect(() => {
    if (!cases || isEmpty(cases)) return;
    if (tableRef.current) tableRef.current.scrollTop = 0;
  }, [cases]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = [...selectedCases];
      cases.forEach(({ id, holdingId, status, responsibleId }) => {
        if (!newSelected.find(({ id: selectedId }) => selectedId === id)) newSelected.push({
          id,
          holdingId,
          status,
          responsibleId
        });
      });
      setSelectedCases(newSelected);
      return;
    }
    const casesIds = cases.map(({ id }) => id);
    setSelectedCases([...selectedCases].filter(({ id: selectedId }) => !casesIds.includes(selectedId)));
  };

  const onCheckBoxClick = (id, holdingId, status, responsibleId) => {
    if (selectedCases.find(({ id: selectedId }) => selectedId === id)) {
      setSelectedCases([...selectedCases].filter(({ id: selectedId }) => selectedId !== id))
    } else {
      setSelectedCases([...selectedCases, { id, holdingId, status, responsibleId }]);
    }
  };

  return (<TableContainer
      component={Paper}
      className={classes.tableContainer}
      ref={tableRef}
    >
      <Table
        aria-label="collapsible table"
        stickyHeader
      >
        <TableHead>
          <TableRow className={classes.mainHeaderRow} data-test="header-row">
            <TableCell
              padding="checkbox"
              className={classes.checkBoxCell}
              data-test="header-select-all-cell"
            >
              <Checkbox
                indeterminate={cases.some(({ id }) =>
                  selectedCases.map(({ id: selectedId }) => selectedId).includes(id)
                  && !cases.every(({ id }) => selectedCases.map(({ id: selectedId }) => selectedId).includes(id)))}
                checked={cases.every(({ id }) => selectedCases.map(({ id: selectedId }) => selectedId).includes(id))}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all cases', 'data-test': 'select-all-checkbox'
                }}
              />
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.idCell)}
              data-test="header-select-all-cell"
            >
              ID
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.dateCell)}
              data-test="header-date-time-cell"
            >
              <div
                className={classes.mainHeaderItem}
              >
                {DATE}
              </div>
              <div
                className={classes.mainHeaderItem}
              >
                {TIME}
              </div>
            </TableCell>
            <TableCell
              className={classes.structureCell}
              data-test="header-structure-cell"
            >
              <div className={classes.mainHeaderItem}>{HOLDING}</div>
              <div className={classes.mainHeaderItem}>{COMPANY}</div>
              <div className={classes.mainHeaderItem}>{UNIT}</div>
            </TableCell>
            <TableCell data-test="header-description-objects-cell">
              <div className={classes.mainHeaderItem}>{EVENT_DESCRIPTION}</div>
              <div className={classes.mainHeaderItem}>{DAMAGED_OBJECTS}</div>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.eventStatusCell)}
              data-test="header-event-status-cell"
            >
              <div>{EVENT_STATUS}</div>
            </TableCell>
            <TableCell
              className={clsx(classes.mainHeaderItem, classes.totalAmountCell)}
              data-test="header-total-amount-of-losses-cell"
            >
              {TOTAL_AMOUNT_OF_LOSSES}
            </TableCell>
            <TableCell className={classes.emptyCell}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {cases.map((row, rowIndex) => (
            <Row
              key={row.id}
              row={row}
              isEven={0 === rowIndex % 2}
              selected={selectedCases.map(({ id: selectedId }) => selectedId).includes(row.id)}
              onCheckBoxClick={() => onCheckBoxClick(row.id, row.holdingId, row.status, row.responsibleId)}
              onRowClick={onRowClick}
            />
          ))}
          {isEmpty(cases) && loading === SUCCEEDED && (
            <TableCell colSpan={8} className={classes.noCasesCell}>{NO_CASES}</TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
