import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';

const Checkbox = withStyles((theme) => ({
  root: {
    color: `${theme.colors.black20}`,
  },
}
))(MuiCheckbox);

export default Checkbox;
