import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import {
  PowerSettingsNew,
} from '@material-ui/icons';
import { Typography, DialogDefault } from 'components/ui-lib';

import routes from 'routes';
import { msal } from 'containers/MSAuth/MSAuth';


import { ReactComponent as LogoSvg } from './logo.svg';
import useStyles from './HeaderStyles';
import UsersRoles from '../UsersRoles';

const msalLogout = msal.logout;

function Header() {
  const classes = useStyles();
  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false);

  const { name: userName } = useSelector(({
    account: {
      user: { name },
    },
  }) => ({ name }
  ));

  const handleOpenLogoutWindow = () => setOpenLogoutModal(true);

  const handleCloseLogoutWindow = () => setOpenLogoutModal(false);

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        color="inherit"
        classes={{
          root: classes.appbarRoot,
        }}
      >
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <div className={classes.headerLeft}>
            <NavLink to={routes.main} className={classes.logoLink}>
              <LogoSvg className={classes.logo}/>
            </NavLink>
          </div>
          <div className={classes.accountWrapper}>
            <div className={classes.userInfo}>
              <Typography
                data-test="auth-user-name"
                className={classes.usersName}
                variant="body2"
                component="span"
              >
                {userName}
              </Typography>
              <UsersRoles />
            </div>
            <IconButton
              aria-label="Account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleOpenLogoutWindow}
              color="inherit"
              classes={{ root: classes.defaultBtnStyle }}
              data-test="account-menu-btn"
            >
              <PowerSettingsNew/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <DialogDefault
        title="Увага!"
        open={isOpenLogoutModal}
        handleClose={handleCloseLogoutWindow}
        activeActionText="Вийти"
        handleActiveAction={msalLogout}
      >
        <Typography data-test="dialog-sub-title" variant="h2">Бажаєте вийти з системи?</Typography>
        <Typography data-test="dialog-text" variant="subtitle2">Ви повернетесь на екран авторизації,
          звідки завжди зможете повернутись у систему.</Typography>
      </DialogDefault>
    </div>
  );
}

export default Header;
