import t from 'utils/translate';

export const CREATE = t('Створити');
export const EVENT_PAGE_TITLE = t('Події');
export const CREATE_EVENT_TITLE = t('Додавання події');
export const EDIT_EVENT_TITLE = t('Редагування події');
export const CANCEL = t('Скасувати');
export const SAVE = t('Зберегти');
export const OLD = t('Старий');
export const  PROPERTY_NAME = t('Найменування майна');
export const  INVENTORY_NUMBER = t('Інвентарний номер');
export const  PROPERTY_TYPE = t('Тип майна');
export const  DAMAGE_CATEGORY = t('Категорія шкоди');
export const  ECOLOGICAL_DAMAGE = t('Екологічна шкода');
export const  LOSSES_AMOUNT = t('Сума збитків тис. грн.');
export const  TIME = t('Час');
export const  HOLDING = t('Холдинг');
export const  COMPANY = t('Підприємство');
export const  UNIT = t('Підрозділ');
export const  EVENT_DESCRIPTION = t('Опис події');
export const  DAMAGED_OBJECTS = t('Пошкоджені об’єкти');
export const  EVENT_STATUS = t('Статус події');
export const  RETURNED_FOR_REVISION = t('Поверталось на доопрацювання');
export const  TOTAL_AMOUNT_OF_LOSSES = t('Загальна сума збитків');
export const  TOTAL_ECOLOGICAL_LOSSES = t('Загальна екологічна шкода');
export const  TOTAL_LIQUIDATION_COST = t('Загальна вартість ліквідації');
export const  FILTRATION = t('Фільтрування');
export const  EXPORT = t('Експортувати XLSX');
export const  EXPORT_DOCUMENTS = t('Завантажити документи');
export const  RESTORATIVE_REPAIRS_CARRIED_OUT = t('Здійснено відновлювальний ремонт');
export const  EMERGENCY_REPAIRS_CARRIED_OUT = t('Здійснено аварійний ремонт');
export const  DAMAGED = t('Пошкоджено');
export const  DESTROYED = t('Знищено');
export const  CAPTURED = t('Захоплено');
export const  TEMPORARILY_LOST_CONTROL = t('Тимчасово втрачено контроль');
export const  ECONOMIC_LOSS = t('Економічний збиток');
export const  APPLY = t('Застосувати');
export const  CLEAR_FILTERS = t('Очистити фiльтри');
export const  ID_NUMBER = t('ID номер');
export const  DATE = t('Дата');
export const  DATA_ENTRY_PERIOD = t('Період внесення даних');
export const  EVENT_PERIOD = t('Період події');
export const  RESPONSIBLE = t('Відповідальний');
export const  EVENT_REGION = t('Область місця події');
export const  VENUE_DISTRICT = t('Район місця події');
export const  OBJECTS = t('Об’єкти');
export const  EVENT_ATTRIBUTES = t('Атрибути події');
export const  SAP_TECHNICAL_PLACE = t('Технічне місце SAP');
export const  BOOK_VALUE = t('Балансова вартість');
export const  LOSSES_AMOUNT_END_ADORNMENT = t('тис. ₴ (без ПДВ)');
export const  EMERGENCY_RECOVERY_COST = t('Вартість аварійно-відновлювальних робіт');
export const  ENVIRONMENTAL_LOSSES = t('Екологічні збитки');
export const  ECONOMIC_LOSSES = t('Економічні збитки');
export const  ECOLOGIST = t('Еколог');
export const  RESTORATION_COST_OR_ANALOGUE = t('Вартість відновлення/аналогу');
export const  REPAIR_COMPLETION_DATE = t('Дата завершення ремонту');
export const  LAWYER_BUSINESS = t('Юрист Бізнес');
export const  LAWYER_K = t('Юрист КЦ');
export const REQUIRED_FIELD = t('Обов\'язкове поле');
export const  REGISTERED = t('Зареєстровано');
export const  IN_WORK = t('В роботі');
export const  DOCUMENTS_PREPARED = t('Пакет документів підготовлено');
export const  DOCUMENTS_REVISED = t('Пакет документів на доопрацюванні');
export const  CHECKED_WITHOUT_REMARKS = t('Перевірено без зауважень');
export const  DOCUMENTS_FINALIZED = t('Пакет документів фіналізовано');
export const  DELETE = t('Видалити');
export const  EDIT = t('Редагувати');
export const  ATTENTION = t('Увага!');
export const  DELETE_EVENT_PROMPT = t('Увага, ви намагаєтесь видалити подію');
export const  RETURN_TO_WORK_PROMPT = t('Увага, ви намагаєтесь повернути подію з фінального' +
	' статусу');
export const  RETURN = t('Повернути');
export const  FROM = t('Від');
export const  TO = t('До');
export const  COST_OF_EMERGENCY_REPAIRS = t('Вартість аварійного ремонту');
export const  RECOVERY_ANALOG = t('Відновлення\\аналог');
export const  REPAIR_COMPLETION_PERIOD = t('Період завершення ремонту');
export const  SINCE = t('З');
export const  BY = t('По');
export const  NOT_FOUND_TITLE = t('Строрінка, яку ви шукаєте, не існує або була видалена');
export const  TRY_START_WITH = t('Спробуйте почати свій шлях з');
export const  MAIN_PAGE = t('головної сторінки');
export const  ADD_CASE = t('Додати подію');
export const  NO_CASES = t('Події відсутні');
export const  NOT_VALID_DATE = t('Невірна дата');
export const  NOT_VALID_DATE_TIME = t('Дата та час не є допустимими');
export const  ATTACH_FILES = t('Додавання файлів');
export const  ATTACH_FILES_TOOLTIP_TEXT = t('Додавання файлів можливе лише в режимі редагування');
export const  NOT_VALID_STATUS = t('Сума збитків повинна бути більше 0');
export const  FORM_HAS_ERRORS = t('Форма має помилки');
export const NOT_VALID_PHONE = t('Невірний номер телефона');
export const  CANCEL_V2 = t('Відмінити');
export const  CREATE_SHAREPOINT_URL_TITLE = t('Для того щоб додати файли потрібно спочатку' +
  ' зберегти зміни події');
export const  CREATE_SHAREPOINT_URL_SUBTITLE = t('Ви оновили один або декілька атрибутів, що' +
  ' впливають на розташування папки з файлами. Тому задля запобігання помилок просимо Вас' +
  ' зберегти зміни у події.');
export const ERROR = t('Помылка');
export const ASSIGN_RESPONSIBLE = t('Призначити відповідального');
export const ASSIGN = t('Призначити');
export const ASSIGN_RESPONSIBLE_OF_ONE_HOLDING = t('Вы можете призначити відповідального тільки' +
  ' на події одного холдінгу');
export const SUCCESSFULLY_APPOINTED = t('Відповідального успішно призначено');
export const ERROR_ASSIGNING_RESPONSIBLE = t('Сталася помилка при призначенні відповідального');
export const EVENTS_SUCCESSFULLY_EXPORTED = t('Події успішно експортовано');
export const EVENT_SUCCESSFULLY_EXPORTED = t('Подію успішно експортовано');
export const EVENTS_DOCUMENTS_SUCCESSFULLY_EXPORTED = t('Документи успішно експортовано');
export const DOWNLOADING_OF_DOCUMENTS_HAS_STARTED_IN_THE_BACKGROUND = t('Завантаження документів розпочато у фоновому режимі');
export const ERROR_EVENTS_EXPORT = t('Сталася помилка при експортуванні подій');
export const ERROR_EVENTS_DOCUMENTS_EXPORT = t('Сталася помилка при експортуванні документів');
export const PART_TOTAL_LOSS_DATA = t('Данні введено частково');
export const ASSIGN_TO_MYSELF = t('Призначити на себе');
export const BECOME_RESPONSIBLE = t('Стати відповідальним');
export const NOT_ALL_SELECTED_CASES_WITH_VALID_STATUS = t('Серед обраних подій присутні з' +
  ' недопустимим статусом для призначення відповідального');
export const SOME_OF_SELECTED_CASES_HAS_RESPONSIBLE = t('Серед обраних подій присутні з' +
  ' призначеним відповідальним');
export const DELETE_EVENT_TITLE = 'Видалення події';
export const RETURN_EVENT_TO_WORK_TITLE = 'Повернення події в роботу';
export const MUST_NOT_BE_ZERO = 'Не має бути нулем';
export const REPORT_DAMAGED_PROPERTY_DTEK = 'Звіт по ушкодженому майну ДТЕК';
export const REPORT = 'Звіт';
export const DISPATCHER = 'Диспетчер';
export const INITIATOR = 'Ініціатор';
export const NOT_PROVIDED = 'Не вказано';
export const THIS_WILL_RETURN_THE_EVENT_TO_PREVIOUS_STATUS = 'Це призведе до повернення події у попередній статус';
export const THE_EVENT_HAS_BEEN_SUCCESSFULLY_RETURNED_TO_WORK = 'Подію успішно повернено в роботу';
