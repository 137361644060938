import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paginationWrapper: {
    '& ul': {
      display: 'inline-block',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '& li': {
      '&:nth-child(2)': {
        paddingLeft: 8,
      },
      paddingLeft: 13,
      display: 'inline-block',
      fontSize: 16,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#212121',
      cursor: 'pointer',
    },
    '& a:focus': {
      outline: 'none',
    },
    '& li.active': {
      fontWeight: 'bold',
      color: '#4499ee',
    },
    '& li.previous': {
      display: 'none',
    },
    '& li.next': {
      display: 'none',
    },
    '& li.disabled': {
      fontWeight: 'bold',
      color: '#a1a1a1',
    },
  },
  limitLabel: {
    '@media (max-width: 1600px)': {
      display: 'none',
    },
  },
  paginationSelect: {
    '& .MuiInput-input': {
      fontSize: 16,
    },
    marginLeft: 10,
    width: 53,
    '& .MuiInputBase-root': {
      '&::before': {
        content: '""',
        borderBottom: 'none',
      },
      '&::after': {
        content: '""',
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          content: '""',
          borderBottom: 'none',
        },
      },
    },
  },
  paginationPageButton: {
    color: '#a1a1a1',
    marginRight: 8,
  },
}
));

export default useStyles;
