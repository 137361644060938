import { makeStyles } from '@material-ui/core/styles';
import { darkGray } from '../ui-lib/ThemeProvider/theme/colors';

const useStyles = makeStyles((theme) => ({
    usersRolesRoot: {
      padding: '0 24px',
      fontSize: 11,
      color: theme.colors.darkGray,
    },
    holdingWrapper: {
      padding: 8,
    },
    holdingTitle: {
      fontWeight: 'bold',
    },
  }
));

export default useStyles;
