import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadHoldingsList,
  loadCompensationCategoriesList,
  loadEnterprisesList,
  loadUnitsList,
  loadRegionsList,
  loadRegionsDistrictsList,
  loadDamageCategoriesList,
  loadPropertyCategoriesList,
  loadPropertyTypesList,
  loadEnvironmentalRisksList,
  loadCaseStatusesList,
  loadRolesList,
} from 'store/dictionaries';
import { INIT_STATUS, SUCCEEDED } from 'constants/main';
import ContentSpinner from 'components/ContentSpinner';

const DictionariesLoader = ({ children }) => {
  const dispatch = useDispatch();
  const {
    holdingsDictionaryStatus,
    enterprisesDictionaryStatus,
    unitsDictionaryStatus,
    regionsDictionaryStatus,
    regionsDistrictsDictionaryStatus,
    damageCategoriesDictionaryStatus,
    propertyCategoriesDictionaryStatus,
    propertyTypesDictionaryStatus,
    environmentalRisksDictionaryStatus,
    caseStatusesDictionaryStatus,
    rolesDictionaryStatus,
    compensationCategoriesStatus,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  useEffect(() => {
    dispatch(loadHoldingsList());
    dispatch(loadCompensationCategoriesList());
    dispatch(loadEnterprisesList());
    dispatch(loadUnitsList());
    dispatch(loadRegionsList());
    dispatch(loadRegionsDistrictsList());
    dispatch(loadDamageCategoriesList());
    dispatch(loadPropertyCategoriesList());
    dispatch(loadPropertyTypesList());
    dispatch(loadEnvironmentalRisksList());
    dispatch(loadCaseStatusesList());
    dispatch(loadRolesList());
  }, []);

  const dictionariesAreLoading = [
    holdingsDictionaryStatus,
    enterprisesDictionaryStatus,
    unitsDictionaryStatus,
    regionsDictionaryStatus,
    regionsDistrictsDictionaryStatus,
    damageCategoriesDictionaryStatus,
    propertyCategoriesDictionaryStatus,
    propertyTypesDictionaryStatus,
    environmentalRisksDictionaryStatus,
    caseStatusesDictionaryStatus,
    rolesDictionaryStatus,
    compensationCategoriesStatus,
  ].some(dictionaryStatus => dictionaryStatus !== SUCCEEDED);

  if (dictionariesAreLoading) {
    return <ContentSpinner isLoading/>
  }

  return children;
};

export default DictionariesLoader;
