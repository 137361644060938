export const headerHeight = 64;
export const titleHeight = 65;
export const CONTENT_PADDING = 24;

export const INIT_STATUS = 'idle';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const SUCCEEDED = 'succeeded';

export const otherRoleName = 'None';

export default module;
