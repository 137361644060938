import React from 'react';
import CustomTags from 'components/FilterElement';
import TextBlock from 'components/TextBlock';
import { isEmpty, isArray } from 'lodash';
import { WORKGROUP_HEAD, FINANCIER, EVENT_REGISTRATION_RESPONSIBLE } from 'constants/roles';
import { useSelector } from 'react-redux';

const DamageCompensationCategoriesSelect = ({ value, onChange, options, initialResponsibleId, shouldUseRoleModel }) => {

  const {
    roles = [],
    id: currentUserId,
  } = useSelector((state) => state.account?.user);

  const select = (
    <CustomTags
      multiple
      filterTitle="Категорія відшкодування"
      valueKey="id"
      label="Категорія відшкодування"
      optionValue="name"
      value={value}
      onChange={onChange}
      options={options}
      data-test="reimbursement-category"
    />
  );

  const canSelect = (roles.includes(WORKGROUP_HEAD)
    || roles.includes(FINANCIER)
    || (initialResponsibleId && roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialResponsibleId === currentUserId));

  if (!shouldUseRoleModel || canSelect) return select;

  let valuesToString = '—';
  if (isArray(value) && !isEmpty(value)) valuesToString = options.filter(({ id }) => value.includes(id)).map(({ name }) => name).join(', ');

  return (
    <TextBlock
      title="Категорія відшкодування"
      data-test="reimbursement-category">
      {valuesToString}
    </TextBlock>
  );
};

export default DamageCompensationCategoriesSelect;
