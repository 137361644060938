import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

function Pagination(props) {
  const classes = useStyles();

  const {
    limit,
    handleChangePagination,
    offset,
    itemsCount,
    limitLabel,
    limitsValues = [50, 100, 200],
  } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={classes.paginationWrapper}
    >
      <div className={classes.limitLabel}>{limitLabel}</div>
      <FormControl className={classes.paginationSelect}>
        <Select
          data-test="pagination-limit"
          value={limit}
          onChange={(event) => {
            const newLimit = +event.target.value;
            if (handleChangePagination) handleChangePagination({ limit: newLimit, offset: 0 });
          }}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {limitsValues.map(limitsValue => (
            <MenuItem key={limitsValue} value={limitsValue}>{limitsValue}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <ReactPaginate
        disableInitialCallback
        forcePage={offset}
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        breakClassName="break-me"
        pageCount={Math.ceil(itemsCount / limit)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={10}
        onPageChange={({ selected } = {}) => {
          if (selected === offset) return;
          handleChangePagination({ offset: selected, limit });
        }}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
      <IconButton
        size="small"
        data-test="backward-pagination-button"
        disabled={!offset || offset === 0}
        color="inherit"
        aria-label="open menu"
        onClick={() => handleChangePagination({ offset: +offset - 1, limit })}
        edge="start"
        className={classes.paginationPageButton}
      >
        <KeyboardArrowLeft/>
      </IconButton>
      <IconButton
        size="small"
        data-test="forward-pagination-button"
        disabled={(+offset + 1
        ) * limit >= itemsCount}
        color="inherit"
        aria-label="open menu"
        onClick={() => handleChangePagination({ offset: +offset + 1, limit })}
        edge="start"
        className={classes.paginationPageButton}
      >
        <KeyboardArrowRight/>
      </IconButton>
    </Grid>
  );
}

Pagination.propTypes = {
  limit: PropTypes.number,
  page: PropTypes.number,
  handleChangePagination: PropTypes.func,
  itemsCount: PropTypes.number,
  limitLabel: PropTypes.string,
  limitsValues: PropTypes.arrayOf(PropTypes.number),
};

export default Pagination;
