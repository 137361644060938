import React from 'react';
import { Typography } from 'components/ui-lib';
import { ReactComponent as LogoSvg } from './assets/logo.svg';
import useStyles from './styles';

export default function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <div className={classes.pacman}/>
        <div className={classes.bols}>
          <div/>
          <div/>
          <div/>
        </div>
        <LogoSvg className={classes.logo}/>
        <Typography variant="body2" className={classes.text}>
          Почекайте, йде завантаження
        </Typography>
      </div>
    </div>
  );
}
