import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  actionControl: {
    marginLeft: 24,
  },
  caseBtn: {
    marginRight: 16,
    marginBottom: 16,
  },
  caseBtnSubTitle: {
    marginBottom: 16,
  },
  flexWrapBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  redColor: {
    color: '#dd4747',
  },
}
));

export default useStyles;
