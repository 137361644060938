import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    upAnchorButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.colors.gray,
      },
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
      },
      position: 'fixed',
      bottom: 88,
      right: 4,
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: theme.colors.primaryLight,
      color: theme.colors.white,
    },
    downAnchorButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.colors.gray,
      },
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
      },
      position: 'fixed',
      bottom: 24,
      right: 4,
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: theme.colors.primaryLight,
      color: theme.colors.white,
    },
  }
));

export default useStyles;
