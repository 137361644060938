/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { omit, get, isEmpty } from 'lodash';
import API from 'API';
import { LOADING, SUCCEEDED, INIT_STATUS, FAILED } from 'constants/main';

export const loadCasesList = createAsyncThunk(
  'casesList/getCasesList',
  async ({ limit = 10, offset = 0, filtersState = {} }, { rejectWithValue }) => {
    try {
      const { ecologistIds = [], responsibleId, kCLawyerId, businessLawyerId, ecologicalDamageIds = [], isTotalLiquidationCostEmpty, isEcologicalLossesEmpty } = filtersState;
      const isEcologistParamEmpty = ecologistIds.includes('isEcologistEmpty');
      const isResponsibleParamEmpty = responsibleId === 'isResponsibleEmpty';
      const isKCLawyerParamEmpty = kCLawyerId === 'isKCLawyerEmpty';
      const isBusinessLawyerParamEmpty = businessLawyerId === 'isBusinessLawyerEmpty';
      const isEcologicalDamageParamEmpty = ecologicalDamageIds.includes('isEcologicalDamageEmpty');
      const filterParams = {
        ...omit(filtersState, ['responsibleId', 'kCLawyerId', 'businessLawyerId']),
        ...(!isResponsibleParamEmpty && responsibleId) && { responsibleId },
        ...(!isKCLawyerParamEmpty && kCLawyerId) && { kCLawyerId },
        ...(!isBusinessLawyerParamEmpty && businessLawyerId) && { businessLawyerId },
        ecologistIds: ecologistIds.filter((param) => param !== 'isEcologistEmpty'),
        ecologicalDamageIds: ecologicalDamageIds.filter((param) => param !== 'isEcologicalDamageEmpty'),
      };
      let response = await API.cases.getCasesList({
        limit,
        offset: limit * offset,
        ...filterParams,
        isEcologistEmpty: isEcologistParamEmpty,
        isResponsibleEmpty: isResponsibleParamEmpty,
        isKCLawyerEmpty: isKCLawyerParamEmpty,
        isBusinessLawyerEmpty: isBusinessLawyerParamEmpty,
        isEcologicalDamageEmpty: isEcologicalDamageParamEmpty,
        isTotalLiquidationCostEmpty: Boolean(isTotalLiquidationCostEmpty),
        isEcologicalLossesEmpty: Boolean(isEcologicalLossesEmpty),
      });
      const totalCount = get(response, 'data.totalCount', 0);
      const items = get(response, 'data.items', []);
      const shouldRequestPreviousPage = totalCount > 0 && isEmpty(items) && offset > 0;
      if (shouldRequestPreviousPage) {
        response = await API.cases.getCasesList({
          limit,
          offset: limit * (offset - 1),
          ...filterParams,
          isEcologistEmpty: isEcologistParamEmpty,
          isResponsibleEmpty: isResponsibleParamEmpty,
          isKCLawyerEmpty: isKCLawyerParamEmpty,
          isBusinessLawyerEmpty: isBusinessLawyerParamEmpty,
          isEcologicalDamageEmpty: isEcologicalDamageParamEmpty,
          isTotalLiquidationCostEmpty: Boolean(isTotalLiquidationCostEmpty),
          isEcologicalLossesEmpty: Boolean(isEcologicalLossesEmpty),
        });
        return { ...response.data, offset: offset - 1 };
      }
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
        message: err?.response?.data?.message,
      });
    }
  },
);

export const importCases = createAsyncThunk(
  'casesList/importCases',
  async ({ filtersState = {}, selectedCases = [], withDocuments }, { rejectWithValue }) => {
    try {
      const { ecologistIds = [], responsibleId, kCLawyerId, businessLawyerId, ecologicalDamageIds = [], isTotalLiquidationCostEmpty, isEcologicalLossesEmpty } = filtersState;
      const isEcologistParamEmpty = ecologistIds.includes('isEcologistEmpty');
      const isResponsibleParamEmpty = responsibleId === 'isResponsibleEmpty';
      const isKCLawyerParamEmpty = kCLawyerId === 'isKCLawyerEmpty';
      const isBusinessLawyerParamEmpty = businessLawyerId === 'isBusinessLawyerEmpty';
      const isEcologicalDamageParamEmpty = ecologicalDamageIds.includes('isEcologicalDamageEmpty');
      const filterParams = {
        ...omit(filtersState, ['responsibleId', 'kCLawyerId', 'businessLawyerId']),
        ...(!isResponsibleParamEmpty && responsibleId) && { responsibleId },
        ...(!isKCLawyerParamEmpty && kCLawyerId) && { kCLawyerId },
        ...(!isBusinessLawyerParamEmpty && businessLawyerId) && { businessLawyerId },
        ecologistIds: ecologistIds.filter((param) => param !== 'isEcologistEmpty'),
        ecologicalDamageIds: ecologicalDamageIds.filter((param) => param !== 'isEcologicalDamageEmpty'),
      };
      const response = await API.cases.export({
        withDocuments,
        ...filterParams,
        isEcologistEmpty: isEcologistParamEmpty,
        isResponsibleEmpty: isResponsibleParamEmpty,
        isKCLawyerEmpty: isKCLawyerParamEmpty,
        isBusinessLawyerEmpty: isBusinessLawyerParamEmpty,
        isEcologicalDamageEmpty: isEcologicalDamageParamEmpty,
        isTotalLiquidationCostEmpty: Boolean(isTotalLiquidationCostEmpty),
        isEcologicalLossesEmpty: Boolean(isEcologicalLossesEmpty),
        ...!isEmpty(selectedCases) && { ids: selectedCases.map(({ id }) => id) },
        timezoneOffset: -1 * new Date().getTimezoneOffset()
      });
      return response;
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message,
        },
      });
    }
  },
);

export const setResponsibleForCases = createAsyncThunk(
  'casesList/setResponsibleForCases',
  async ({ selectedCases = [], responsibleId, holdingId }, { rejectWithValue }) => {
    try {
      const response = await API.cases.assignResponsible({
        caseIds: selectedCases.map(({ id }) => id),
        responsibleId,
        holdingId,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message,
        },
      });
    }
  },
);

export const getUsers = createAsyncThunk(
  'account/getResponsible',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.user.getUsers(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const getInitiators = createAsyncThunk(
  'users/initiators',
  async (args, { rejectWithValue }) => {
    try {
      const response = await API.user.getInitiators(args);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const casesListSlice = createSlice({
  name: 'casesList',
  initialState: {
    responsible: [],
    casesList: [],
    loading: INIT_STATUS,
    limit: 10,
    offset: 0,
    totalCount: 0,
    filtersState: {},
    temporaryFiltersState: {},
    selectedCases: [],
    exportingWithDocuments: [],
    isExportingCasesWithDocuments: false,
    ...initialStateMetadata
  },
  reducers: {
    onFilterUpdate(state, action) {
      const { payload: { filterName, value } } = action;
      if (value) {
        state.temporaryFiltersState[filterName] = value;
      } else {
        state.temporaryFiltersState = omit(state.temporaryFiltersState, filterName);
      }
    },
    applyFilters(state) {
      state.filtersState = state.temporaryFiltersState;
    },
    resetTemporaryFiltersState(state) {
      state.temporaryFiltersState = state.filtersState;
    },
    resetFiltersState(state) {
      state.temporaryFiltersState = {};
      state.filtersState = {};
    },
    setSelectedCases(state, action) {
      const { payload: { selectedCases } } = action;
      state.selectedCases = selectedCases;
    },
    eddToExportingWithDocuments(state, action) {
      const { payload: { caseId } } = action;
      state.exportingWithDocuments = [...state.exportingWithDocuments, caseId];
    },
    removeFromExportingWithDocuments(state, action) {
      const { payload: { caseId } } = action;
      state.exportingWithDocuments = state.exportingWithDocuments.filter(id => id !== caseId);
    },
    setIsExportingCasesWithDocuments(state, action) {
      const { payload: {exportingState} } = action;
      state.isExportingCasesWithDocuments = exportingState;
    }
  },
  extraReducers: {
    [loadCasesList.pending]: (state, action) => {
      const limit = get(action, 'meta.arg.limit');
      const offset = get(action, 'meta.arg.offset');
      state.limit = limit;
      state.offset = offset;
      state.loading = LOADING;
      state.error = false;
    },
    [loadCasesList.fulfilled]: (state, action) => {
      const { items, totalCount, offset } = action.payload;
      if (offset >= 0) {
        state.offset = offset;
      }
      state.casesList = items;
      state.totalCount = totalCount;
      state.loading = SUCCEEDED;
    },
    [loadCasesList.rejected]: (state, action) => {
      const { payload } = action;
      state.loading = FAILED;
      state.error = payload;
    },
  },
});

export default casesListSlice.reducer;
export const {
  onFilterUpdate,
  applyFilters,
  resetTemporaryFiltersState,
  resetFiltersState,
  setSelectedCases,
  eddToExportingWithDocuments,
  removeFromExportingWithDocuments,
  setIsExportingCasesWithDocuments
} = casesListSlice.actions;
