import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  holding: {
    fontSize: 14,
    color: theme.colors.darkGray,
  },
  company: {
    fontSize: 14,
    color: theme.colors.darkGray,
  },
  unit: {
    fontSize: 14,
    color: theme.colors.black,
  },
}
));

export default useStyles;
