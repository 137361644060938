import React from 'react';
import { withRouter, useHistory, matchPath } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getRouterPaths } from 'routes';

const routesList = getRouterPaths();

const getIsPathExcluded = (path, pathname) => matchPath(pathname, {
  path,
  exact: false,
  strict: false,
});

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    margin: 2,
    marginLeft: -12,
  },
}
));

const BackArrow = withRouter(({ match }) => {
  const history = useHistory();
  const classes = useStyles();

  const hierarchy = routesList.filter((path) => match.path.includes(path));
  const routeMatches = hierarchy.map((path) => getIsPathExcluded(path, history.location.pathname));

  const handleArrowClick = () => {
    const parentPath = routeMatches[routeMatches.length - 2]?.url;
    history.push(parentPath || '/');
  };

  return (
    <IconButton
      className={classes.root}
      color="default"
      component="span"
      onClick={handleArrowClick}
    >
      <ArrowBack/>
    </IconButton>
  );
});

export default BackArrow;
