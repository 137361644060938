import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  date: {
    fontSize: 14,
    color: theme.colors.black,
  },
  time: {
    fontSize: 14,
    color: theme.colors.darkGray,
  },
}
));

export default useStyles;
