import React, { useEffect } from 'react';
import {
  Box, Typography, TextField, FormControl, FormHelperText, FormControlLabel,
  InputAdornment, Divider, Tooltip,
} from '@material-ui/core';
import { Add, DescriptionOutlined, DeleteOutline, PersonAddOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { v4 as aniqueId } from 'uuid';
import { isBefore, isValid } from 'date-fns';

import PhoneInput from 'components/PhoneInput'
import parseFloat from 'utils/parseFloat';
import CustomSelect from 'components/PartCustomSelect';
import FullWidthButton from 'components/FullWidthButton';
import Switch from 'components/Switch';
import { IconButton } from 'components/ui-lib';

import {
  REQUIRED_FIELD,
  ATTACH_FILES_TOOLTIP_TEXT,
} from 'constants/texts';
import { getUserRoleSelector } from 'store/account';
import DataPicker from 'components/DatePicker/SimpleDatePicker';
import {
  getFilteredEnterprises,
  getFilteredRegionDistricts,
  getFilteredUnits
} from 'store/dictionaries';
import Can from 'containers/RoleModel/Can';
import {
  ADD_FILES_TO_EVENT_ON_CREATION,
  ADD_FILES_TO_OBJECT_ON_CREATION,
  RESPONSIBLE_SELECT,
} from 'constants/permissions';
import useAppStyles from 'containers/App/AppStyles';
import AnchorNavigation from 'components/AnchorNavigation/AnchorNavigation';
import ResponsibleSelect from 'pages/EditEvent/components/ResponsibleSelect';
import useStyles from './CreateEventFormStyles';
import DamageCompensationCategoriesSelect from '../DamageCompensationCategoriesSelect';


const CreateEventForm = ({
  values,
  errors,
  initBlockValues,
  setFieldValue,
   }) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const dispatch = useDispatch();

  const {
    holdingsDictionary,
    filteredEnterprises,
    filteredUnits,
    filteredRegionsDistricts,
    regionsDictionary,
    damageCategoriesDictionary,
    propertyTypesDictionary,
    environmentalRisksDictionary,
    compensationCategoriesDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  const userRoles = useSelector(getUserRoleSelector);

  const handleAddWitness = () => {
    setFieldValue(
      'witnesses',
      [...values.witnesses, { ...initBlockValues.witness, witnessId: aniqueId() }],
      false
    );
  };
  const handleRemoveWitness = (indexForRemove) => {
    setFieldValue(
      'witnesses',
      values.witnesses.filter((item, index) => index !== indexForRemove),
      false
    );
  };

  const handleAddObject = () => {
    setFieldValue(
      'objects',
      [...values.objects, { ...initBlockValues.object, objectId: aniqueId() }],
      false
    );
  };
  const handleRemoveObject = (indexForRemove) => {
    setFieldValue(
      'objects',
      values.objects.filter((item, index) => index !== indexForRemove),
      false
    );
  };

  const getHoldingOptions = (holdings) => {
    return holdings.filter(({ id }) => userRoles.some((role) => role.holdingId === id));
  };

  useEffect(() => {
    if (!values.holding) return;
    dispatch(getFilteredEnterprises([values.holding]));
  }, [values.holding]);

  useEffect(() => {
    if (!values.enterprise) return;
    dispatch(getFilteredUnits([values.enterprise]));
  }, [values.enterprise]);

  useEffect(() => {
    if (!values.eventArea) return;
    dispatch(getFilteredRegionDistricts(values.eventArea));
  }, [values.eventArea]);

  return (
    <Box id="eventAttributes" px={3} pt={1} pb={3} className={classes.formSection}>
      <AnchorNavigation
        portalElementId='nav-menu'
        menu={[
          { id: 'eventAttributes', title: 'Атрибути події' },
          { id: 'witnesses', title: 'Очевидці' },
          { id: 'objects', title: 'Об’єкти' },
          ...values.objects.map(({}, index) => ({ id: `object-${index}`, title: `Об’єкт ${index + 1}` }))
        ]}
      />
      <Typography variant="h2" data-test="event-attributes-title">Атрибути події</Typography>
      <Box className={classes.eventsAttrs}>
        <Box className={classes.eventsAttrsLeft}>
          <Box className={classes.formControlRow}>
            <Box className={appClasses.flexWrapBetween}>
              <DataPicker
                invalidDateMessage="Невірний формат дати"
                className={classes.halfBlock}
                minDate={new Date('2022.02.24')}
                maxDate={new Date()}
                selectedDate={values.eventDate}
                error={Boolean(errors.eventDate)}
                label="Дата"
                helperText={errors.eventDate || REQUIRED_FIELD}
                handleDateChange={(date) => {
                  setFieldValue('eventDate', date, false);
                }}
                inputProps={{ 'data-test': 'event-date' }}
              />
              <TextField
                className={classes.halfBlock}
                variant="outlined"
                label="Час"
                type="time"
                helperText={errors.eventTime || REQUIRED_FIELD}
                value={values.eventTime}
                error={Boolean(errors.eventTime)}
                onChange={({ target }) => {
                  setFieldValue('eventTime', target.value, false);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ 'data-test': 'event-time' }}
              />
            </Box>
          </Box>
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Холдинг"
              onChange={(val) => {
                setFieldValue('holding', val.id, false);
                if (values.enterprise) setFieldValue('enterprise', '', false);
                if (values.subdivision) setFieldValue('subdivision', '', false);
              }}
              options={getHoldingOptions(holdingsDictionary)}
              optionValue="name"
              value={holdingsDictionary.find(({ id }) => id === values.holding)}
              error={Boolean(errors.holding)}
              data-test="holding"
            />
            <FormHelperText error={errors.holding}>
              {REQUIRED_FIELD}
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Підприємство"
              onChange={(val) => {
                setFieldValue('enterprise', val.id, false);
                if (values.subdivision) setFieldValue('subdivision', '', false);
              }}
              options={filteredEnterprises}
              disabled={!Boolean(values.holding)}
              optionValue="name"
              value={filteredEnterprises.find(({ id }) => id === values.enterprise)}
              error={Boolean(errors.enterprise)}
              data-test="enterprise"
            />
            <FormHelperText error={errors.enterprise}>
              {REQUIRED_FIELD}
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Підрозділ"
              optionValue="name"
              disabled={!Boolean(values.enterprise)}
              onChange={(val) => setFieldValue('subdivision', val.id, false)}
              options={filteredUnits}
              value={filteredUnits.find(({ id }) => id === values.subdivision)}
              error={Boolean(errors.subdivision)}
              data-test="unit"
            />
            <FormHelperText error={errors.subdivision}>
              {REQUIRED_FIELD}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className={classes.eventsAttrsRight}>
          <Can
            perform={RESPONSIBLE_SELECT}
            yes={() => (
              <FormControl variant="outlined" className={classes.formControlRow}>
                <ResponsibleSelect
                  onChange={({ id = null } = {}) => setFieldValue('responsibleId', id, false)}
                  value={values.responsibleId}
                  mode="creation"
                  holdingId={values.holding}
                />
              </FormControl>
            )}
          />
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Область місця події"
              optionValue="name"
              options={regionsDictionary}
              error={Boolean(errors.eventArea)}
              onChange={(val) => {
                setFieldValue('eventArea', val.id, false);
                if (values.regionArea) setFieldValue('regionArea', '', false);
              }}
              value={regionsDictionary.find(({ id }) => id === values.eventArea)}
              data-test="event-area"
            />
            <FormHelperText error={errors.eventArea}>
              {REQUIRED_FIELD}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Район місця події"
              optionValue="name"
              disabled={!Boolean(values.eventArea)}
              onChange={(val) => setFieldValue('regionArea', val.id, false)}
              options={filteredRegionsDistricts}
              value={filteredRegionsDistricts.find(({ id }) => id === values.regionArea)}
              error={Boolean(errors.regionArea)}
              data-test="region-area"
            />
            <FormHelperText error={errors.regionArea}>
              {REQUIRED_FIELD}
            </FormHelperText>
          </FormControl>
          <Box className={classes.formControlRow}>
            <TextField
              fullWidth
              variant="outlined"
              label="Адреса місця події"
              helperText={REQUIRED_FIELD}
              inputProps={{ maxLength: 512, 'data-test': 'address-area' }}
              onChange={({ target }) => setFieldValue('addressArea', target.value, false)}
              value={values.addressArea}
              error={Boolean(errors.addressArea)}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.formControlRow}>
        <TextField
          fullWidth
          maxRows={10}
          multiline
          variant="outlined"
          label="Опис події"
          helperText={REQUIRED_FIELD}
          inputProps={{ maxLength: 1024, 'data-test': 'event-description' }}
          onChange={({ target }) => setFieldValue('eventDescription', target.value, false)}
          value={values.eventDescription}
          error={Boolean(errors.eventDescription)}
        />
      </Box>
      <Box className={classes.formControlRowWithMargin}>
        <TextField
          fullWidth
          maxRows={10}
          multiline
          variant="outlined"
          label="Коментар"
          inputProps={{ maxLength: 1024, 'data-test': 'event-comment' }}
          value={values.eventComment}
          onChange={({ target }) => setFieldValue('eventComment', target.value, false)}
        />
      </Box>

      <Can
        perform={ADD_FILES_TO_EVENT_ON_CREATION}
        yes={() => (
          <Tooltip title={ATTACH_FILES_TOOLTIP_TEXT}>
            <Box>
              <FullWidthButton
                disabled
                fullWidth
                size="large"
                type="cart-dashed"
                startIcon={<DescriptionOutlined/>}
                onClick={() => {}}
                data-test="add-files-to-case"
              >
                Додати файли
              </FullWidthButton>
            </Box>
          </Tooltip>
        )}
      />
      <Divider className={classes.blockDivider}/>

      <Box id="witnesses">
        <Typography variant="h2">Очевидці</Typography>
        {values.witnesses.map((item, index) => {
          const objectTitleAddon = index < 2 ? '(Обов’язково)' : '';
          const RemoveBtn = (
            <IconButton
              type="secondary"
              onClick={() => handleRemoveWitness(index)}
              data-test="remove-witness-button"
            >
              <DeleteOutline/>
            </IconButton>
          );
          const additionalDataProps = !values?.witnesses?.[index]?.isEmployee
            ? { helperText: REQUIRED_FIELD } : {};

          return (
            <Box
              key={item.witnessId}
              className={classes.shadowBlock}
              data-test={`witness-block-${index}`}
            >
              <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
                <Typography variant="subtitle1">
                  {`Очевидець ${index + 1} ${objectTitleAddon}`}
                </Typography>
                {values.witnesses.length > 2 && RemoveBtn}
              </Box>

              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="П.І.Б (Повністю)"
                  helperText={REQUIRED_FIELD}
                  inputProps={{ maxLength: 256, 'data-test': 'witnesses-name' }}
                  onChange={({ target }) => {
                    setFieldValue(`witnesses[${index}].name`, target.value, false);
                  }}
                  value={values?.witnesses?.[index]?.name}
                  error={Boolean(errors?.witnesses?.[index]?.name)}
                />
              </Box>
              <Box className={appClasses.flexWrapBetween}>
                <Box className={classes.eventsAttrsLeft}>
                  <Box className={classes.formControlRow}>
                    <PhoneInput
                      label="Телефон"
                      country={'ua'}
                      value={values?.witnesses?.[index]?.phone}
                      error={errors?.witnesses?.[index]?.phone}
                      onChange={(value) => setFieldValue(`witnesses[${index}].phone`, value, false)}
                      inputProps={{ 'data-test': 'witnesses-phone' }}
                    />
                  </Box>
                </Box>
                <Box className={classes.eventsAttrsRight}>
                  <FormControlLabel
                    control={(
                      <Switch
                        name="developmentFilter"
                        color="primary"
                        checked={values?.witnesses?.[index]?.isEmployee}
                        value={values?.witnesses?.[index]?.isEmployee}
                        onChange={(e, val) => {
                          setFieldValue(`witnesses[${index}].isEmployee`, val, false);
                          if (Boolean(errors?.witnesses?.[index]?.additionalData) && val) {
                            const additionalData = values?.witnesses?.[index]?.additionalData;
                            setFieldValue(`witnesses[${index}].additionalData`, additionalData, false);
                          }
                        }}
                        inputProps={{ 'data-test': 'witnesses-isEmployee' }}
                      />
                    )}
                    label={(<Typography variant="subtitle2">Є співробітіником</Typography>
                    )}
                  />
                </Box>
              </Box>
              <Box className={classes.formControlRowWithMargin}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={10}
                  variant="outlined"
                  label="Додаткові дані"
                  inputProps={{ maxLength: 512, 'data-test': 'witnesses-additional-data' }}
                  value={values?.witnesses?.[index]?.additionalData}
                  error={Boolean(errors?.witnesses?.[index]?.additionalData)}
                  onChange={({ target }) => {
                    setFieldValue(`witnesses[${index}].additionalData`, target.value, false);
                  }}
                  {...additionalDataProps}
                />
              </Box>
            </Box>
          );
        })}
        <FullWidthButton
          disabled={values.witnesses.length >= 5}
          fullWidth
          size="large"
          type="cart-dashed"
          startIcon={<PersonAddOutlined/>}
          onClick={handleAddWitness}
          data-test="add-witness-button"
        >
          Додати очевидця
        </FullWidthButton>
      </Box>
      <Divider className={classes.blockDivider}/>

      <Box id="objects" className={classes.blockForm}>
        <Typography variant="h2">Об’єкти</Typography>
        {values.objects.map((item, index) => {
          const objectTitleAddon = index === 0 ? '(Обов’язково)' : '';
          const RemoveBtn = (
            <IconButton
              type="secondary"
              onClick={() => handleRemoveObject(index)}
              data-test="remove-object-button"
            >
              <DeleteOutline/>
            </IconButton>
          );

          return (
            <Box
              id={`object-${index}`}
              key={item.objectId}
              className={classes.shadowBlock}
              data-test={`object-block-${index}`}
            >
              <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
                <Typography variant="subtitle1" data-test={`object-${index}-title`}>
                  {`Об’єкт ${index + 1} ${objectTitleAddon}`}
                </Typography>
                {values.objects.length > 1 && RemoveBtn}
              </Box>
              <Box className={appClasses.flexWrapBetween}>
                <Box className={classes.eventsAttrsLeft}>
                  <Box className={classes.formControlRow}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Найменування майна"
                      helperText={REQUIRED_FIELD}
                      inputProps={{ maxLength: 256, 'data-test': 'object-title' }}
                      value={values?.objects?.[index]?.title}
                      error={Boolean(errors?.objects?.[index]?.title)}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].title`, target.value, false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <Box className={appClasses.flexWrapBetween}>
                      <Box className={classes.halfBlock}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Інвентарний номер"
                          inputProps={{ maxLength: 128, 'data-test': 'object-inventory-number' }}
                          value={values?.objects?.[index]?.inventoryNumber}
                          helperText={errors?.objects?.[index]?.inventoryNumber}
                          error={Boolean(errors?.objects?.[index]?.inventoryNumber)}
                          onChange={({ target }) => {
                            setFieldValue(`objects[${index}].inventoryNumber`, target.value, false);
                          }}
                        />
                      </Box>
                      <Box className={classes.halfBlock}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Технічне місце SAP"
                          inputProps={{ maxLength: 128, 'data-test': 'object-tech-place-Sap' }}
                          value={values?.objects?.[index]?.techPlaceSap}
                          onChange={({ target }) => {
                            setFieldValue(`objects[${index}].techPlaceSap`, target.value, false);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      autocomplete
                      label="Тип майна"
                      optionValue="name"
                      onChange={({ id }) => {
                        setFieldValue(`objects[${index}].propertyType`, id, false);
                      }}
                      value={propertyTypesDictionary.find(({ id }) => id === values?.objects?.[index]?.propertyType)}
                      options={propertyTypesDictionary}
                      error={Boolean(errors?.objects?.[index]?.propertyType)}
                      data-test="object-property-type"
                    />
                    <FormHelperText error={errors?.objects?.[index]?.propertyType}>
                      {REQUIRED_FIELD}
                    </FormHelperText>
                  </FormControl>
                  <Box className={classes.formControlRow}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      fullWidth
                      variant="outlined"
                      label="Балансова вартість"
                      error={Boolean(errors?.objects?.[index]?.balansCost)}
                      {...Boolean(errors?.objects?.[index]?.balansCost) && { helperText: errors?.objects?.[index]?.balansCost }}
                      inputProps={{ min: 0, 'data-test': 'object-balance-cost' }}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={values?.objects?.[index]?.balansCost}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].balansCost`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.eventsAttrsRight}>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      autocomplete
                      label="Категорія шкоди"
                      optionValue="name"
                      onChange={({ id }) => {
                        setFieldValue(`objects[${index}].damageType`, id, false);
                      }}
                      options={damageCategoriesDictionary}
                      value={damageCategoriesDictionary.find(({ id }) => id === values?.objects?.[index]?.damageType)}
                      error={Boolean(errors?.objects?.[index]?.damageType)}
                      data-test="object-damage-type"
                    />
                    <FormHelperText error={errors?.objects?.[index]?.damageType}>
                      {REQUIRED_FIELD}
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <DamageCompensationCategoriesSelect
                      shouldUseRoleModel
                      initialResponsibleId={values?.initialResponsibleId}
                      value={values?.objects?.[index]?.damageCompensationCategoryIds}
                      onChange={(enumValue) => {
                        if (values?.objects?.[index]?.damageCompensationCategoryIds?.includes(enumValue)) {
                          setFieldValue(`objects[${index}].damageCompensationCategoryIds`, values?.objects?.[index]?.damageCompensationCategoryIds?.filter(id => id !== enumValue), false);
                        } else {
                          setFieldValue(`objects[${index}].damageCompensationCategoryIds`, [...values?.objects?.[index]?.damageCompensationCategoryIds, enumValue], false);
                        }
                      }}
                      options={compensationCategoriesDictionary}
                    />
                  </FormControl>
                  <Box
                    className={appClasses.flexWrapBetween}
                    style={{ alignItems: 'flex-start' }}
                  >
                    <FormControlLabel
                      control={(
                        <Switch
                          name="developmentFilter"
                          color="primary"
                          checked={values?.objects?.[index]?.isRepairComplete}
                          value={values?.objects?.[index]?.isRepairComplete}
                          onChange={(e, val) => {
                            setFieldValue(`objects[${index}].isRepairComplete`, val, false);
                            if (!val) setFieldValue(`objects[${index}].repairCompleteDate`, null, false);
                          }}
                          inputProps={{ 'data-test': 'object-is-repair-completed' }}
                        />
                      )}
                      label={(<Typography variant="subtitle2">Ремонт проведено</Typography>
                      )}
                    />
                    {values?.objects?.[index]?.isRepairComplete && (
                      <DataPicker
                        invalidDateMessage="Невірний формат дати"
                        className={classes.halfBlock}
                        minDate={
                          values.eventDate
                          && isValid(new Date(values.eventDate))
                          && isBefore(new Date(2022, 1, 24, 0, 0, 0), new Date(values.eventDate))
                            ? values.eventDate
                            : new Date('2022.02.24')
                        }
                        maxDate={new Date()}
                        selectedDate={values?.objects?.[index]?.repairCompleteDate}
                        error={Boolean(errors?.objects?.[index]?.repairCompleteDate)}
                        label="Завершення ремонту"
                        helperText={errors?.objects?.[index]?.repairCompleteDate || REQUIRED_FIELD}
                        handleDateChange={(date) => {
                          setFieldValue(`objects[${index}].repairCompleteDate`, isValid(date) ? new Date(date.setHours(0, 0)).toISOString() : date, false);
                        }}
                        inputProps={{ 'data-test': 'object-repair-completion-date' }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={10}
                  variant="outlined"
                  label="Опис пошкоджень"
                  helperText={REQUIRED_FIELD}
                  inputProps={{ maxLength: 1024, 'data-test': 'object-damages-description' }}
                  error={Boolean(errors?.objects?.[index]?.damagesDescription)}
                  value={values?.objects?.[index]?.damagesDescription}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].damagesDescription`, target.value, false);
                  }}
                />
              </Box>
              <Box className={classes.formControlRowWithMargin}>
                <TextField
                  fullWidth
                  maxRows={10}
                  multiline
                  variant="outlined"
                  label="Коментар"
                  inputProps={{ maxLength: 1024, 'data-test': 'object-comment' }}
                  value={values?.objects?.[index]?.comment}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].comment`, target.value, false);
                  }}
                />
              </Box>
              <Can
                perform={ADD_FILES_TO_OBJECT_ON_CREATION}
                yes={() => (
                  <Tooltip title={ATTACH_FILES_TOOLTIP_TEXT}>
                    <Box>
                      <FullWidthButton
                        disabled
                        fullWidth
                        size="large"
                        type="cart-dashed"
                        startIcon={<DescriptionOutlined/>}
                        onClick={() => {
                        }}
                        data-test="add-files-to-object-button"
                      >
                        Додати файли
                      </FullWidthButton>
                    </Box>
                  </Tooltip>
                )}
              />
            </Box>
          );
        })}
        <FullWidthButton
          disabled={false}
          fullWidth
          size="large"
          type="cart-dashed"
          startIcon={<Add/>}
          onClick={handleAddObject}
          data-test="add-object-button"
        >
          Додати об’єкт
        </FullWidthButton>
      </Box>
    </Box>
  );
};

export default CreateEventForm;
