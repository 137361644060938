import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Box, Divider, FormControlLabel, Tooltip } from '@material-ui/core';
import {
	ExpandMore,
	ExpandLess,
	ErrorOutline,
	ReportProblemOutlined
} from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { showNotification } from 'store/notifications';
import {
	loadEvent,
	deleteEvent,
	editEvent,
	setPendingEventLoad,
	resetError,
	resetEventStore,
	returnEvent,
	exportDocuments,
} from 'store/event';
import { setResponsibleForCases } from 'store/casesList';
import { getCurrentUserRoleSelector, getUserSelector } from 'store/account';
import routes, { routeBits } from 'routes';
import { mapEventToServerData } from 'utils/mappers';
import CanEditEvent from 'containers/CanEditEvent';
import PageHeader from 'components/PageHeader';
import TextBlock from 'components/TextBlock';
import SubIndentBlock from 'components/SubIndentBlock';
import { Button, DialogDefault, Typography } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';
import {
	KC_LAWYER,
	rolesTranslations,
	WORKGROUP_HEAD
} from 'constants/roles';
import { SUCCEEDED, LOADING } from 'constants/main';
import Checkbox from 'components/Checkbox';
import { ReactComponent as FileDownloadIcon } from 'assets/icons_file_download.svg';
import DeleteCaseButton from './compoments/DeleteCaseButton';
import {
	DELETE_EVENT_TITLE,
	DELETE,
	DELETE_EVENT_PROMPT,
	PART_TOTAL_LOSS_DATA,
	RETURNED_FOR_REVISION,
	EXPORT_DOCUMENTS,
	ERROR_EVENTS_DOCUMENTS_EXPORT,
	EVENT_SUCCESSFULLY_EXPORTED,
	RETURN_TO_WORK_PROMPT,
	RETURN_EVENT_TO_WORK_TITLE,
	RETURN,
	THIS_WILL_RETURN_THE_EVENT_TO_PREVIOUS_STATUS,
	THE_EVENT_HAS_BEEN_SUCCESSFULLY_RETURNED_TO_WORK,
} from 'constants/texts';
import EditCaseButton from './compoments/EditCaseButton';
import AssignToMeButton from './compoments/AssignToMeButton';
import ActionButtons from './compoments/ActionButtons';

import { ReactComponent as IconIsEmployee } from 'assets/icons-people.svg';
import { ReactComponent as IconIsNotEmployee } from 'assets/icons-people-gray.svg';
import { ReactComponent as IconIsRepairComplete } from 'assets/check-circle.svg';

import NumberFormat from "react-number-format";
import PhoneInput from "components/PhoneInput";
import useAppStyles from 'containers/App/AppStyles';
// import useWebSocketsConnection from 'API/useWebSocketsConnection';

import useStyles from './EventStyles';
import onFileReceived from 'utils/file';
import {
	DISPLAY_DATE_FORMAT_FILENAME,
	DISPLAY_TIME_FORMAT_FILENAME,
	formatDate, formatTime
} from 'utils/dateTime';
import { formatISO } from 'date-fns';
import useExcelStatusPolling from 'hooks/useExcelStatusPolling';

const Event = () => {
	const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
	const [isOpenReturnToWorkConfirmation, setIsOpenReturnToWorkConfirmation] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isReturning, setIsReturning] = useState(false);
	const [performersExpanded, setPerformersExpanded] = useState([]);
	const [witnessesExpanded, setWitnessesExpanded] = useState([]);
	const [objectsExpanded, setObjectsExpanded] = useState([]);
	const appClasses = useAppStyles();
	const classes = useStyles();
	const params = useParams();
	const user = useSelector(getUserSelector);
	const currentUserRoles = useSelector(getCurrentUserRoleSelector);
	const dispatch = useDispatch();
	const history = useHistory();
	const startExcelStatusPolling = useExcelStatusPolling();

	const {
		unitsDictionary,
		holdingsDictionary,
		regionsDistrictsDictionary,
		enterprisesDictionary,
		regionsDictionary,
		damageCategoriesDictionary,
		propertyTypesDictionary,
		environmentalRisksDictionary,
		caseStatusesNamesObject,
		compensationCategoriesDictionary,
	} = useSelector((state) => state.dictionaries?.dictionaries);
	const processes = useSelector(state => state.polling.processes);
	const {
		data = {},
		status,
		error,
	} = useSelector((state) => state.event?.event);
	const {
		id: currentUserId,
		name: currentUserName,
		roles
	} = useSelector((state) => state.account?.user);

	const isExportCaseToFileButtonVisible = roles.includes(WORKGROUP_HEAD) || roles.includes(KC_LAWYER);
	const showDocumentsRevisedStatusCount = Boolean(data.documentsRevisedStatusCount && data.documentsRevisedStatusCount > 0);

	const { environmentalRisks = [] } = data;
	const hasEnvironmentalRisks = !isEmpty(environmentalRisks);
	const {
		totalEnvironmentalLoss,
		totalLiquidationCost
	} = environmentalRisks.reduce((accObj, { environmentalLossView, liquidationCostView }) => {
		let summaryEnvironmentalLoss;
		let summaryLiquidationCost;

		if (typeof accObj.totalEnvironmentalLoss === 'number' && typeof environmentalLossView === 'number') {
			summaryEnvironmentalLoss = accObj.totalEnvironmentalLoss + environmentalLossView;
		} else if (typeof environmentalLossView === 'number') {
			summaryEnvironmentalLoss = environmentalLossView;
		} else {
			summaryEnvironmentalLoss = accObj.totalEnvironmentalLoss;
		}

		if (typeof accObj.totalLiquidationCost === 'number' && typeof liquidationCostView === 'number') {
			summaryLiquidationCost = accObj.totalLiquidationCost + liquidationCostView;
		} else if (typeof liquidationCostView === 'number') {
			summaryLiquidationCost = liquidationCostView;
		} else summaryLiquidationCost = accObj.totalLiquidationCost;

		return {
			totalEnvironmentalLoss: summaryEnvironmentalLoss,
			totalLiquidationCost: summaryLiquidationCost
		};
	}, {});

	const isDisabledExportButton = useMemo(() => {
		return processes.some(({type, casesIds}) => type === 'case' && casesIds.some((id) => params.eventId === id))
	}, [processes, params.eventId]);

	const handleEditEvent = () => history.push(`${routeBits.event}/${data.id}/${data.holding}/edit`);
	const handleAssignToMe = () => {
		dispatch(setPendingEventLoad());
		dispatch(setResponsibleForCases({
			selectedCases: [{ id: data.id }],
			responsibleId: currentUserId,
			holdingId: data.holding,
		})).then(() => {
			dispatch(loadEvent({ ...params, user, currentUserRoles, isView: true }));
		});
	};

	const handleAssignPerformer = (role) => {
		const performers = get(data, 'performers', []);
		const isPresentPerformer = performers.some(({ name }) => name === currentUserName);
		const updatedPerformers = [...data.performers];
		const currentUser = {
			id: null,
			name: currentUserName,
			role,
		};

		if (!isPresentPerformer) {
			updatedPerformers.unshift(currentUser);
		}

		dispatch(setPendingEventLoad());
		dispatch(editEvent({
			id: data.id,
			data: mapEventToServerData({
				...data,
				performers: updatedPerformers,
			}, true),
		})).then(() => {
			dispatch(loadEvent({ ...params, user, currentUserRoles, isView: true }));
		});
	};

	const handleReturnFromFinalStatus = () => {
		setIsOpenReturnToWorkConfirmation(true)
	};

	useEffect(() => {
		if (!error) return;
		const errorStatus = get(error, 'status', '');
		if (errorStatus === 404) {
			history.replace(routes.notFound);
		}
	}, [error]);

	useEffect(() => {

		dispatch(loadEvent({ ...params, user, currentUserRoles, isView: true }));
		return () => {
			dispatch(resetError());
			dispatch(resetEventStore());
		}
	}, []);

	const deleteCurrentEvent = () => {
		setIsOpenDeleteConfirmation(false);
		dispatch(deleteEvent(params)).then((data) => {
			setIsDeleting(false);
			if (data.error) {
				dispatch(showNotification({
					type: 'error',
					message: data?.payload?.error?.message || 'Сталася помилка',
				}));
			} else {
				history.push(routes.main);
				dispatch(showNotification({
					type: 'success',
					message: 'Подію успішно видалено',
				}));
			}
		});
	};

	const returnToWork = () => {
		setIsReturning(true);
		setIsOpenReturnToWorkConfirmation(false);
		dispatch(returnEvent(params)).then((data) => {
			setIsReturning(false);
			if (data.error) {
				dispatch(showNotification({
					type: 'error',
					message: data?.payload?.error?.message || 'Сталася помилка',
				}));
			} else {
				dispatch(loadEvent({ ...params, user, currentUserRoles, isView: true }));
				dispatch(showNotification({
					type: 'success',
					message: THE_EVENT_HAS_BEEN_SUCCESSFULLY_RETURNED_TO_WORK,
				}));
			}
		});
	};

	const ToggleAllBtn = (unit) => {
		return Object.keys(unit).length > 0
			? <div className={classes.expandAllBtn}><ExpandLess/> Згорнути все</div>
			: <div className={classes.expandAllBtn}><ExpandMore/> Розгорнути все</div>;
	};

	const handleChangePerformersExpand = (id) => {
		const isExpanded = performersExpanded.includes(id);

		if (isExpanded) {
			setPerformersExpanded(performersExpanded.filter((it) => it !== id))
		} else {
			setPerformersExpanded([...performersExpanded, id]);
		}
	};

	const handleChangeWitnessesExpand = (id) => {
		const isExpanded = witnessesExpanded.includes(id);

		if (isExpanded) {
			setWitnessesExpanded(witnessesExpanded.filter((it) => it !== id))
		} else {
			setWitnessesExpanded([...witnessesExpanded, id]);
		}
	};

	const handleChangeObjectsExpand = (id) => {
		const isExpanded = objectsExpanded.includes(id);

		if (isExpanded) {
			setObjectsExpanded(objectsExpanded.filter((it) => it !== id))
		} else {
			setObjectsExpanded([...objectsExpanded, id]);
		}
	};

	const exportCaseToFile = () => {
		dispatch(exportDocuments(params)).then((response) => {

			const archiveCorrelationId = response?.payload?.data?.archiveCorrelationId;
			const excelCorrelationId = response?.payload?.data?.excelCorrelationId;
			startExcelStatusPolling(excelCorrelationId, 'case', [params.eventId], archiveCorrelationId);
		});
	};

	return (
		<ContentSpinner isLoading={status === LOADING || isDeleting || isReturning}>
			{status === SUCCEEDED && (
				<Box className={appClasses.root}>
					<PageHeader
						hasBackArrow
						title="Перегляд події"
					>
						<Box className={appClasses.pageHeader}>
							<Typography variant="h1">Перегляд події</Typography>
							<Box>
								<DeleteCaseButton
									onClick={() => setIsOpenDeleteConfirmation(true)}
								/>
								<CanEditEvent renderIfHidden={(
									<AssignToMeButton onClick={handleAssignToMe}/>
								)}>
									<EditCaseButton onClick={handleEditEvent}/>
								</CanEditEvent>
								{isExportCaseToFileButtonVisible && (
									<Button
										disabled={isDisabledExportButton}
										className={classes.fileDownloadButton}
										size="large"
										startIcon={<FileDownloadIcon/>}
										onClick={exportCaseToFile}
										type="cart-secondary"
										data-test="export-button"
									>
										{EXPORT_DOCUMENTS}
									</Button>
								)}
							</Box>
						</Box>
					</PageHeader>
					<Box px={3} pt={1}>
						<Typography variant="body2" className={classes.eventStatus} data-test="event-status">
							{get(caseStatusesNamesObject, `${data?.status}.name`, data?.status)}
						</Typography>
						{showDocumentsRevisedStatusCount && (
							<Typography variant="body2" className={classes.documentsRevisedStatusCount}
													data-test="event-documents-revised-status-count">
								{`${RETURNED_FOR_REVISION}: ${data?.documentsRevisedStatusCount}`}
							</Typography>
						)}
						<SubIndentBlock
							title={<Typography variant="h2">Атрибути події</Typography>}
						>
							<Box className={appClasses.flexWrapBetween}>
								<Box className={classes.wrapper}>
									<Box className={classes.halfBlock}>
										<TextBlock title="Дата" data-test='date'>
											{data?.eventDate_view}
										</TextBlock>
										<TextBlock title="Холдинг" data-test='holding'>
											{holdingsDictionary.find(({ id }) => data?.holding === id)?.name}
										</TextBlock>
										<TextBlock title="Підрозділ" data-test='unit'>
											{unitsDictionary.find(({ id }) => data?.subdivision === id)?.name}
										</TextBlock>
									</Box>
									<Box>
										<TextBlock title="Час" data-test='time'>
											{data.eventTime}
										</TextBlock>
										<TextBlock title="Підприємство" data-test='enterprise'>
											{enterprisesDictionary.find(({ id }) => data?.enterprise === id)?.name}
										</TextBlock>
									</Box>
								</Box>
								<Box className={classes.wrapper}>
									<Box className={classes.halfBlock}>
										<TextBlock title="Створив подію" data-test='author-name'>
											{data?.authorName}
										</TextBlock>
										<TextBlock title="Область місця події" data-test='event-area'>
											{regionsDictionary.find(({ id }) => data?.eventArea === id)?.name}
										</TextBlock>
										<TextBlock title="Адреса місця події"
															 data-test='address-area'>{data?.addressArea}</TextBlock>
									</Box>
									<Box>
										<TextBlock title="Відповідальний" data-test='responsible'>
											{data?.responsibleName || '—'}
										</TextBlock>
										<TextBlock title="Район місця події" data-test='region-area'>
											{regionsDistrictsDictionary.find(({ id }) => data?.regionArea === id)?.name}
										</TextBlock>
										<TextBlock
											title="Загальна сума збитків по події тис. грн."
											data-test='total-loss'
										>

											<div className={classes.totalLossWrap}>
												<div>
													₴&nbsp;
													<span className={appClasses.redColor}>
                          <NumberFormat
														value={data.totalLoss}
														thousandSeparator=" "
														displayType={'text'}
													/>
                        </span>
												</div>
												{!data?.allObjectsLossFilled && (
													<Tooltip title={PART_TOTAL_LOSS_DATA} style={{ marginLeft: 5 }}>
														<ErrorOutline color="error"/>
													</Tooltip>
												)}
											</div>
										</TextBlock>
									</Box>
								</Box>
							</Box>
						</SubIndentBlock>
						<TextBlock title="Опис події"
											 data-test="event-description">{data?.eventDescription}</TextBlock>
						<Box className={appClasses.flexWrapBetween}>
							<TextBlock title="Коментар" data-test='event-comment'>
								{data?.eventComment || '—'}
							</TextBlock>
							<Button
								className={classes.attrFileButton}
								type="cart-primary"
								size="medium"
								disabled={!data?.isSharePointFolderHasItems}
								onClick={() => window.open(data?.sharePointFolderUrl)}
								data-test='show-files-button'
							>
								Переглянути файли
							</Button>
						</Box>
						<Box className={classes.formControlRow}>
							<Box className={classes.eventsCheckBoxes}>
								{data?.photoVideoFixationIsUnavailable
									? (
										<FormControlLabel
											control={(
												<ReportProblemOutlined
													className={classes.reportProblemIcon}
												/>
											)}
											label={(
												<Typography
													variant="subtitle2"
													className={classes.formControlLabel}
													data-test="photo-video-fixation-is-unavailable"
												>
													Немає можливості фото/відео фіксації
												</Typography>
											)}
										/>
									)
									: (
										<FormControlLabel
											control={(
												<Checkbox
													checked={data?.documentsPhotoVideoReady}
													readOnly
													disabled
													color="secondary"
													inputProps={{ 'data-test': 'documents-photo-video-ready' }}
												/>
											)}
											label={(
												<Typography variant="subtitle2" className={classes.black}>
													Фото\відео фіксація
												</Typography>
											)}
										/>
									)
								}
								<FormControlLabel
									control={(
										<Checkbox
											checked={data?.documentsFixationReady}
											readOnly
											disabled
											color="secondary"
											inputProps={{ 'data-test': 'documents-fixation-ready' }}
										/>
									)}
									label={(
										<Typography variant="subtitle2" className={classes.black}>
											Документальна фіксація (акт)
										</Typography>
									)}
								/>
								<FormControlLabel
									control={(
										<Checkbox
											checked={data?.documentsApplicationToLawEnforcementReady}
											readOnly
											disabled
											color="secondary"
											inputProps={{ 'data-test': 'documents-application-to-law-enforcement-ready' }}
										/>
									)}
									label={(
										<Typography variant="subtitle2" className={classes.black}>
											Заява у правоохоронні органи
										</Typography>
									)}
								/>
								<FormControlLabel
									control={(
										<Checkbox
											checked={data?.documentsFullyFormedReady}
											readOnly
											disabled
											color="secondary"
											inputProps={{ 'data-test': 'documents-fully-formed-ready' }}
										/>
									)}
									label={(
										<Typography variant="subtitle2" className={classes.black}>
											Повний пакет док. сформований
										</Typography>
									)}
								/>
							</Box>
						</Box>
					</Box>

					<Divider className={classes.blockDivider}/>
					{hasEnvironmentalRisks && (
						<>
							<Box px={3} pt={1}>
								<Box
									pb={0.5}
									className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
								>
									<Typography variant="h2">Екологiчнi збитки</Typography>
									{ToggleAllBtn(witnessesExpanded)}
								</Box>
								<Box className={classes.shadowBlock}>
									{data?.environmentalRisks.map((environmentalRisk, index) => {
										const {
											environmentalRiskId: itemEnvironmentalRiskId,
											environmentalLossView: itemEnvironmentalLoss,
											liquidationCostView: itemLiquidationCost,
										} = environmentalRisk;

										const itemEnvironmentalRiskName = environmentalRisksDictionary.find(({ id }) => itemEnvironmentalRiskId === id)?.name || '—';

										return (
											<Box className={appClasses.flexWrapBetween}>
												<Box className={classes.wrapper}>
													<Box className={classes.halfBlock}>

														<TextBlock title={`Екологiчна шкода ${index + 1}`}>
															{itemEnvironmentalRiskName}
														</TextBlock>
													</Box>
												</Box>
												<Box className={classes.wrapper}>
													<Box className={classes.halfBlock}>
														<TextBlock title="Сума шкоди, тис. ₴ (без ПДВ)">
															₴&nbsp;
															{typeof itemEnvironmentalLoss === 'number'
																? (
																	<NumberFormat
																		value={itemEnvironmentalLoss}
																		thousandSeparator=" "
																		displayType={'text'}
																	/>
																)
																: '—'
															}
														</TextBlock>
													</Box>
													<Box>
														<TextBlock title="Вартість ліквідації , тис. ₴ (без ПДВ)">
															₴&nbsp;
															{typeof itemLiquidationCost === 'number'
																? (
																	<NumberFormat
																		value={itemLiquidationCost}
																		thousandSeparator=" "
																		displayType={'text'}
																	/>
																)
																: '—'
															}
														</TextBlock>
													</Box>
												</Box>
											</Box>
										);
									})}
									<Box className={appClasses.flexWrapBetween}>
										<Box className={classes.wrapper}>
											<Box className={classes.halfBlock}>
												<TextBlock title="&nbsp;">Загалом</TextBlock>
											</Box>
										</Box>
										<Box className={classes.wrapper}>
											<Box className={classes.halfBlock}>
												<TextBlock title="Сума шкоди, тис. ₴ (без ПДВ)">
													₴&nbsp;
													{typeof totalEnvironmentalLoss === 'number'
														? (
															<NumberFormat
																value={totalEnvironmentalLoss}
																thousandSeparator=" "
																displayType={'text'}
															/>
														)
														: '—'
													}
												</TextBlock>
											</Box>
											<Box>
												<TextBlock title="Вартість ліквідації , тис. ₴ (без ПДВ)">
													₴&nbsp;
													{typeof totalLiquidationCost === 'number'
														? (
															<NumberFormat
																value={totalLiquidationCost}
																thousandSeparator=" "
																displayType={'text'}
															/>
														)
														: '—'
													}
												</TextBlock>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Divider className={classes.blockDivider}/>
						</>
					)}

					{data?.performers?.length > 0 && (
						<Box px={3} pt={1} data-test="performers-block">
							<Box
								pb={0.5}
								data-test="expand-performers-block"
								className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
								onClick={() => {
									performersExpanded < 1
										? setPerformersExpanded(data.performers.map(({ id }) => id))
										: setPerformersExpanded([])
								}}
							>
								<Typography variant="h2">Виконавці</Typography>
								{ToggleAllBtn(performersExpanded)}
							</Box>
							{(data?.performers.map((it) => {
									const isExpand = performersExpanded.includes(it.id);

									return (
										<Box key={it.id} className={classes.shadowBlock}>
											<SubIndentBlock
												title={(
													<Box
														style={{ cursor: 'pointer' }}
														data-test="expand-performers"
														onClick={() => handleChangePerformersExpand(it.id)}
														className={appClasses.flexWrapBetween}
													>
														<Typography variant="subtitle1" data-test='role-description'>
															{rolesTranslations[it?.role]}{it?.roleDescription && ` (${it?.roleDescription})`}
															{!isExpand && ` - ${it?.name}`}
														</Typography>
														{isExpand ? <ExpandLess color="secondary"/> :
															<ExpandMore color="secondary"/>}
													</Box>
												)}
											>
												{isExpand && (
													<Box className={classes.actionBlock}>
														<Box className={classes.actionBlockLeft}>
															<TextBlock title="П.І.Б (Повністю)"
																				 data-test='name'>{it.name}</TextBlock>
														</Box>
														<TextBlock title="Коментар відповідального"
																			 data-test='comment'>{it.comment || '—'}</TextBlock>
													</Box>
												)}
											</SubIndentBlock>
										</Box>
									)
								})
							)}
						</Box>
					)}

					<Box px={3} pt={1} data-test='witnesses-block'>
						<Box
							pb={0.5}
							className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
							data-test="expand-witnesses-block"
							onClick={() => {
								witnessesExpanded < 1
									? setWitnessesExpanded(data?.witnesses.map(({ id }) => id))
									: setWitnessesExpanded([])
							}}>
							<Typography variant="h2">Очевидці</Typography>
							{ToggleAllBtn(witnessesExpanded)}
						</Box>
						{data?.witnesses.map((it, index) => {
							const isExpand = witnessesExpanded.includes(it.id);

							return (
								<Box key={it.id} className={classes.shadowBlock}>
									<SubIndentBlock
										title={(
											<Box
												style={{ cursor: 'pointer' }}
												data-test="expand-witnesses"
												onClick={() => handleChangeWitnessesExpand(it.id)}
												className={appClasses.flexWrapBetween}
											>
												<Typography variant="subtitle1">
													{`Очевидець ${index + 1}`}{!isExpand && ` - ${it?.name}`}
												</Typography>
												{isExpand ? <ExpandLess color="secondary"/> :
													<ExpandMore color="secondary"/>}
											</Box>
										)}
									>
										{isExpand && (
											<>
												<Box className={appClasses.flexWrapBetween}>
													<Box className={classes.wrapper}>
														<Box className={classes.halfBlock}>
															<TextBlock title="П.І.Б (Повністю)"
																				 data-test='name'>{it.name}</TextBlock>
														</Box>
													</Box>
													<Box className={classes.wrapper}>
														<Box className={classes.halfBlock}>
															<PhoneInput
																label="Телефон"
																readOnly
																country={'ua'}
																value={it.phone}
																inputProps={{ 'data-test': 'witnesses-phone' }}
															/>
														</Box>
														<Box>
															<TextBlock title="&nbsp;">
																{it.isEmployee ? (
																	<Box className={classes.isEmployeeBlock} data-test='is-employee'>
																		<IconIsEmployee/>
																		<span>Є співробітіником</span>
																	</Box>
																) : (
																	<Box className={classes.isEmployeeBlock}
																			 data-test='is-not-employee'>
																		<IconIsNotEmployee/>
																		<span>Не є співробітіником</span>
																	</Box>
																)}
															</TextBlock>
														</Box>
													</Box>
												</Box>
												<TextBlock title="Додатові дані"
																	 data-test='additional-data'>{it.additionalData || '—'}</TextBlock>
											</>
										)}
									</SubIndentBlock>
								</Box>
							)
						})}
					</Box>

					<Box px={3} pt={1} data-test="objects-block">
						<Box
							pb={0.5}
							data-test="expand-objects-block"
							className={clsx(appClasses.flexWrapBetween, classes.toggleAllWrap)}
							onClick={() => {
								objectsExpanded < 1
									? setObjectsExpanded(data?.objects.map(({ id }) => id))
									: setObjectsExpanded([])
							}}>
							<Typography variant="h2">Об’єкти</Typography>
							{ToggleAllBtn(objectsExpanded)}
						</Box>
						{data?.objects.map((it) => {
							const isExpand = objectsExpanded.includes(it.id);

							return (
								<Box key={it.id} className={classes.shadowBlock}
										 data-test={`object-block-${it.id}`}>
									<>
										<Box
											style={{ cursor: 'pointer' }}
											onClick={() => handleChangeObjectsExpand(it.id)}
											className={appClasses.flexWrapBetween}
											data-test="expand-objects"
										>
											<Typography variant="subtitle1" data-test="object-title">
												{`ID ${it?.id}`}
												{it?.oldId && (
													<div className={classes.oldObjectId}>
														&nbsp;
														{`(ID ${it?.oldId})`}
													</div>
												)}
												{!isExpand && ` - ${it.title}`}
											</Typography>
											{isExpand ? <ExpandLess color="secondary"/> : <ExpandMore color="secondary"/>}
										</Box>
										{isExpand && (
											<>
												<SubIndentBlock>
													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															<TextBlock title="Найменування"
																				 data-test='object-title'>{it.title}</TextBlock>
														</Box>
														<Box className={classes.wrapper}>
															<TextBlock title="Тип майна" data-test='object-property-type'>
																{propertyTypesDictionary.find(({ id }) => it?.propertyType === id)?.name}
															</TextBlock>
														</Box>
													</Box>
													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															<TextBlock title="Інвентарний номер"
																				 data-test='object-inventory-number'>
																{it?.inventoryNumber || '—'}
															</TextBlock>
														</Box>
														<Box className={classes.wrapper}>
															<TextBlock title="Категорія шкоди" data-test='object-damage-type'>
																{damageCategoriesDictionary.find(({ id }) => it?.damageType === id)?.name || '—'}
															</TextBlock>
														</Box>
													</Box>

													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															<TextBlock title="Технічне місце SAP"
																				 data-test='object-tech-place-Sap'>
																{it?.techPlaceSap || '—'}
															</TextBlock>
														</Box>
														<Box className={classes.wrapper}>
															<TextBlock title="Категорія відшкодування"
																				 data-test='object-damage-type'>
																{it?.damageCompensationCategoryIds && !isEmpty(it?.damageCompensationCategoryIds) ? compensationCategoriesDictionary.filter(({ id }) => it?.damageCompensationCategoryIds.includes(id)).map(({ name }) => name).join(', ') : '—'}
															</TextBlock>
														</Box>
													</Box>

													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															<TextBlock
																title="Балансова вартість, тис. ₴ (без ПДВ)"
																className={appClasses.redColor}
																data-test='object-balance-cost'
															>
																₴&nbsp;
																{it?.balansCostView
																	? (
																		<NumberFormat
																			value={it?.balansCostView}
																			thousandSeparator=" "
																			displayType={'text'}
																		/>
																	)
																	: '—'
																}
															</TextBlock>
														</Box>
														<Box className={classes.wrapper}>
															<TextBlock
																title="Вартість відновлення/аналогу, тис. ₴ (без ПДВ)"
																className={appClasses.redColor}
																data-test='object-recovery-cost'
															>
																₴&nbsp;
																{it?.recoveryCostView
																	? (
																		<NumberFormat
																			value={it?.recoveryCostView}
																			thousandSeparator=" "
																			displayType={'text'}
																		/>
																	)
																	: '—'
																}
															</TextBlock>
														</Box>
													</Box>
													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															<TextBlock
																title="Вартість аварійно-відновлювальних робіт, тис. ₴ (без ПДВ)"
																className={appClasses.redColor}
																data-test='object-wreck-repair-cost'
															>
																₴&nbsp;
																{it?.wreckRepairCostView
																	? (
																		<NumberFormat
																			value={it?.wreckRepairCostView}
																			thousandSeparator=" "
																			displayType={'text'}
																		/>
																	)
																	: '—'
																}
															</TextBlock>
														</Box>
														<Box className={classes.wrapper}>
															<TextBlock
																title="Економічні збитки, тис. ₴ (без ПДВ)"
																className={appClasses.redColor}
																data-test='object-economical-loss'
															>
																₴&nbsp;
																{it?.economicLossView
																	? (
																		<NumberFormat
																			value={it?.economicLossView}
																			thousandSeparator=" "
																			displayType={'text'}
																		/>
																	)
																	: '—'
																}
															</TextBlock>
														</Box>
													</Box>
													<Box className={appClasses.flexWrapBetween}>
														<Box className={classes.wrapper}>
															{it?.isRepairComplete && (
																<>
																	<Box className={classes.halfBlock}>

																		<TextBlock
																			title="&nbsp;"
																			data-test='object-is-repair-completed-is-employee'
																		>
																			<Box className={classes.isEmployeeBlock}>
																				<IconIsRepairComplete/>
																				<span>Ремонт проведено</span>
																			</Box>
																		</TextBlock>

																	</Box>
																	<Box>
																		<TextBlock
																			title="Дата ремонту"
																			data-test='object-is-repair-completed-date'
																		>
																			{it?.repairCompleteDate_view || '—'}
																		</TextBlock>
																	</Box>
																</>
															)}
														</Box>
														<Box className={classes.wrapper}>
															<Box className={appClasses.flexWrapBetween}>
																<Box className={classes.halfBlock}>
																	<TextBlock
																		title="Сума збитків, тис. ₴ (без ПДВ)"
																		className={appClasses.redColor}
																		data-test='object-total-loss'
																	>
																		<b>
																			₴&nbsp;
																			{it?.totalLoss
																				? (
																					<NumberFormat
																						value={it?.totalLoss}
																						thousandSeparator=" "
																						displayType={'text'}
																					/>
																				)
																				: '—'
																			}
																		</b>
																	</TextBlock>
																</Box>
																<Box>
																	<Button
																		type="cart-primary"
																		disabled={!it?.isSharePointFolderHasItems}
																		onClick={() => window.open(it?.sharePointFolderUrl)}
																		size="medium"
																		data-test='object-show-files-buttons'
																	>
																		Переглянути файли
																	</Button>
																</Box>
															</Box>
														</Box>
													</Box>
													<TextBlock
														title="Опис пошкоджень"
														data-test='object-damages-description'
													>
														{it?.damagesDescription || '—'}
													</TextBlock>
													<TextBlock
														title="Коментар"
														data-test='object-comment'
													>
														{it?.comment || '—'}
													</TextBlock>
												</SubIndentBlock>
												{it?.businessLawyerComment && (
													<>
														<Divider className={classes.blockDivider}/>
														<SubIndentBlock
															title={<Typography variant="subtitle1">Коментар юриста
																бізнеса</Typography>}
														>
															<Box className={classes.actionBlock}
																	 data-test='object-business-lawyer-comment-block'>
																<Box className={classes.actionBlockLeft}>
																	<TextBlock title="П.І.Б (Повністю)"
																						 data-test='object-business-lawyer-name'>
																		{it?.businessLawyerName || '—'}
																	</TextBlock>
																</Box>
																<TextBlock title="Коментар відповідального"
																					 data-test='object-business-lawyer-comment'>
																	{it?.businessLawyerComment || '—'}
																</TextBlock>
															</Box>
														</SubIndentBlock>
													</>
												)}
												{it?.ecologistComment && (
													<>
														<Divider className={classes.blockDivider}/>
														<SubIndentBlock
															title={<Typography variant="subtitle1">Коментар еколога</Typography>}
														>
															<Box className={classes.actionBlock}
																	 data-test='object-ecologist-comment-block'>
																<Box className={classes.actionBlockLeft}>
																	<TextBlock title="П.І.Б (Повністю)"
																						 data-test='object-ecologist-name'>
																		{it?.ecologistName || '—'}
																	</TextBlock>
																</Box>
																<TextBlock title="Коментар відповідального"
																					 data-test='object-ecologist-comment'>{it?.ecologistComment || '—'}</TextBlock>
															</Box>
														</SubIndentBlock>
													</>
												)}
												{it?.kcLawyerComment && (
													<>
														<Divider className={classes.blockDivider}/>
														<SubIndentBlock
															title={<Typography variant="subtitle1">Коментар юриста
																КЦ</Typography>}
														>
															<Box className={classes.actionBlock}
																	 data-test='object-kcLawyer-comment-block'>
																<Box className={classes.actionBlockLeft}>
																	<TextBlock title="П.І.Б (Повністю)"
																						 data-test='object-kcLawyer-name'>
																		{it?.kcLawyerName || '—'}
																	</TextBlock>
																</Box>
																<TextBlock title="Коментар відповідального"
																					 data-test='object-kcLawyer-comment'>{it?.kcLawyerComment || '—'}</TextBlock>
															</Box>
														</SubIndentBlock>
													</>
												)}
											</>
										)}
									</>
								</Box>
							)
						})}
					</Box>
				</Box>
			)}
			<DialogDefault
				title={DELETE_EVENT_TITLE}
				open={isOpenDeleteConfirmation}
				handleClose={() => setIsOpenDeleteConfirmation(false)}
				activeActionText={DELETE}
				activeActionBtnType="default-error"
				handleActiveAction={deleteCurrentEvent}
			>
				<Typography data-test="dialog-sub-title" variant="h2">{DELETE_EVENT_PROMPT}</Typography>
				<Typography
					data-test="dialog-text"
					variant="subtitle2"
				>
					{data?.sharePointFolderUrl
						? 'Це приведе до втрати усіх даних у події та файлів у SharePoint'
						: 'Це приведе до втрати усіх даних у події'
					}

				</Typography>
			</DialogDefault>
			<DialogDefault
				title={RETURN_EVENT_TO_WORK_TITLE}
				open={isOpenReturnToWorkConfirmation}
				handleClose={() => setIsOpenReturnToWorkConfirmation(false)}
				activeActionText={RETURN}
				activeActionBtnType="default-primary"
				handleActiveAction={returnToWork}
			>
				<Typography data-test="dialog-sub-title" variant="h2">{RETURN_TO_WORK_PROMPT}</Typography>
				<Typography
					data-test="dialog-text"
					variant="subtitle2"
				>
					{THIS_WILL_RETURN_THE_EVENT_TO_PREVIOUS_STATUS}
				</Typography>
			</DialogDefault>
			<ActionButtons
				data={data}
				currentUserName={currentUserName}
				roles={roles}
				handleAssignPerformer={handleAssignPerformer}
				handleReturnFromFinalStatus={handleReturnFromFinalStatus}
			/>
		</ContentSpinner>
	)
};

export default Event;
