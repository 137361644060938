import React, { useEffect, useState } from 'react';
import {
  Box, Typography, TextField, FormControl, FormHelperText, FormControlLabel,
  InputAdornment, Divider, Tooltip,
} from '@material-ui/core';
import { Add, DeleteOutline, PersonAddOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { createSharePointFolder } from 'store/event';
import parseFloat from 'utils/parseFloat';
import { v4 as aniqueId } from 'uuid';

import useIsMount from 'utils/useIsMount';
import {
  checkRoles,
  BUSINESS_LAWYER_COMMENT,
  ALWAYS_EDITABLE_FIELD,
  KC_LAWYER_COMMENT,
  INVENTORY_NUMBER_FIELD,
  TECHPLACE_SAP_FIELD,
  BALANS_COST_FIELD,
  WRECK_REPAIR_COST_FIELD,
  RECOVERY_COST_FIELD,
  ECONOMIC_LOSS_FIELD,
  DOCUMENT_PREPARATION_CHECKBOXES,
  EVENT_COMMENT,
} from 'containers/RoleModel';
import CustomSelect from 'components/PartCustomSelect';
import Checkbox from 'components/Checkbox';
import FullWidthButton from 'components/FullWidthButton';
import Switch from 'components/Switch';
import { isValid, isBefore } from 'date-fns';
import { DialogDefault, IconButton } from 'components/ui-lib';
import { getUserRoleSelector, getCurrentUserRoleSelector } from 'store/account';
import DataPicker from 'components/DatePicker/SimpleDatePicker';
import {
  getFilteredEnterprises,
  getFilteredRegionDistricts,
  getFilteredUnits
} from 'store/dictionaries';
import StatusSelect from 'pages/EditEvent/components/StatusSelect';
import AddFilesButton from 'pages/EditEvent/components/AddFilesButton';
import ResponsibleSelect from '../ResponsibleSelect';
import PerformersBlock from './components/PerformersBlock';
import PhoneInput from 'components/PhoneInput';
import { showNotification } from 'store/notifications';
import {
  ATTACH_FILES,
  CANCEL_V2,
  CREATE_SHAREPOINT_URL_SUBTITLE,
  CREATE_SHAREPOINT_URL_TITLE,
  REQUIRED_FIELD,
  SAVE,
  FORM_HAS_ERRORS, RETURNED_FOR_REVISION,
} from 'constants/texts';

import useAppStyles from 'containers/App/AppStyles';
import useStyles from './EditEventFormStyles';
import AnchorNavigation from 'components/AnchorNavigation/AnchorNavigation';
import DamageCompensationCategoriesSelect
  from 'pages/CreateEvent/components/DamageCompensationCategoriesSelect';
import { WORKGROUP_HEAD } from 'constants/roles';

const WITNESSES_MAX_COUNT = 5;
const noEcologicalDamageOptionId = 11;

const EditEventForm = ({
  values,
  errors,
  validateForm,
  initBlockValues,
  setFieldValue,
  setDeletedObjectWithFiles,
}) => {
  const [isFolderCreation, setIsFolderCreation] = useState(false);
  const [isOpenSharePointFolderConfirmation, setIsOpenSharePointFolderConfirmation] = useState(false);
  const [isChangedPathForFolder, setIsChangedPathForFolder] = useState(false);

  const classes = useStyles();
  const appClasses = useAppStyles();
  const dispatch = useDispatch();
  const isMount = useIsMount();

  const {
    holdingsDictionary,
    filteredEnterprises,
    filteredUnits,
    filteredRegionsDistricts,
    regionsDictionary,
    damageCategoriesDictionary,
    propertyTypesDictionary,
    environmentalRisksDictionary,
    compensationCategoriesDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);
  const userRoles = useSelector(getUserRoleSelector);
  const currentUserRoles = useSelector(getCurrentUserRoleSelector);
  const { access = [] } = useSelector((state) => state.account?.user);

  const canWorkAsEcologist = values.status !== 'DocumentsFinalized' && access.some(({
    holdingId,
    role
  }) => holdingId === values.holding && role === 'Ecologist');

  const handleAddPerformer = () => {
    setFieldValue(
      'performers',
      [...values.performers, {
        ...initBlockValues.performer,
        isCustomPerformer: true,
        performerId: aniqueId(),
      }],
      false,
    );
  };
  const handleRemovePerformer = (indexForRemove) => {
    setFieldValue(
      'performers',
      values.performers.filter((item, index) => index !== indexForRemove),
      false,
    );
  };

  const handleAddWitness = () => {
    setFieldValue(
      'witnesses',
      [...values.witnesses, { ...initBlockValues.witness, witnessId: aniqueId() }],
      false,
    );
  };
  const handleRemoveWitness = (indexForRemove) => {
    setFieldValue(
      'witnesses',
      values.witnesses.filter((item, index) => index !== indexForRemove),
      false,
    );
  };

  const handleAddEcologicalDamage = () => {
    setFieldValue(
      'environmentalRisks',
      [...values.environmentalRisks, {
        ...initBlockValues.environmentalRisk,
        ecologicalDamageId: aniqueId()
      }],
      false,
    );
  };
  const handleRemoveEcologicalDamage = (indexForRemove) => {
    setFieldValue(
      'environmentalRisks',
      values.environmentalRisks.filter((item, index) => index !== indexForRemove),
      false,
    );
  };

  const handleAddObject = () => {
    setFieldValue(
      'objects',
      [...values.objects, initBlockValues.object],
      false,
    );
  };
  const handleRemoveObject = (indexForRemove, isSharePointFolderHasItems) => {
    if (isSharePointFolderHasItems) {
      setDeletedObjectWithFiles(true);
    }

    setFieldValue(
      'objects',
      values.objects.filter((item, index) => index !== indexForRemove),
      false,
    );
  };

  const handleAddFileToEvent = (folderUrl, data, formFolderPath) => {
    if (folderUrl) {
      return window.open(folderUrl);
    }

    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        dispatch(showNotification({
          type: 'error',
          message: FORM_HAS_ERRORS,
        }));
        return;
      }

      if (isChangedPathForFolder) {
        setIsOpenSharePointFolderConfirmation(true);
      } else {
        silentSaveAndCreateFilesLink(data, formFolderPath);
      }
    });
  };

  const silentSaveAndCreateFilesLink = (data, formFolderPath) => {
    setIsFolderCreation(true);
    dispatch(createSharePointFolder({
      holdingId: values.holding,
      data: { ...data, timezoneOffset: -1 * new Date().getTimezoneOffset() },
    })).then((createSharePointFolderResponse) => {
      const { payload: { url, error } = {} } = createSharePointFolderResponse;
      setIsFolderCreation(false);
      if (error) {
        dispatch(showNotification({
          type: 'error',
          message: error?.message || 'Сталася помилка створення папки',
        }));
        return;
      }

      // need correct path objects
      setFieldValue(formFolderPath, url, false);
      url && window.open(url);
    });
  };

  const getHoldingOptions = (holdings) => {
    return holdings.filter(({ id }) => userRoles.some((role) => role.holdingId === id));
  };

  const isCreatedFolders = () => {
    return Boolean(values.sharePointFolderUrl)
      || values.objects.some((it) => Boolean(it.sharePointFolderUrl));
  };

  useEffect(() => {
    if (!values.holding) return;
    dispatch(getFilteredEnterprises([values.holding]));
  }, [values.holding]);

  useEffect(() => {
    if (!values.enterprise) return;
    dispatch(getFilteredUnits([values.enterprise]));
  }, [values.enterprise]);

  useEffect(() => {
    if (!values.eventArea) return;
    dispatch(getFilteredRegionDistricts(values.eventArea));
  }, [values.eventArea]);

  useEffect(() => {
    if (isMount) return;
    setIsChangedPathForFolder(true);
  }, [
    values.eventDate,
    values.eventTime,
    values.holding,
    values.enterprise,
    values.subdivision,
  ]);

  const showDocumentsRevisedStatusCount = Boolean(values.documentsRevisedStatusCount && values.documentsRevisedStatusCount > 0);

  const getOptions = (index) => {
    return environmentalRisksDictionary.filter(
      it => {
        const isCurrentSelectedOption = values.environmentalRisks?.[index]?.environmentalRiskId === it.id;
        const ecologicalDamageCount = values.environmentalRisks?.reduce((acc, { environmentalRiskId }) => {
          if (environmentalRiskId && environmentalRiskId !== noEcologicalDamageOptionId) {
            return acc + 1;
          }
          return acc;
        }, 0);
        const isSelectedOption = values.environmentalRisks.map(({ environmentalRiskId }) => environmentalRiskId).includes(it.id);
        if (it.id === noEcologicalDamageOptionId && ecologicalDamageCount > 0 ) return false;
        return (!isSelectedOption || isCurrentSelectedOption);
      }
    )
  };

  return (
    <Box id="eventAttributes" px={3} pt={1} pb={3} className={classes.formSection}>
      <AnchorNavigation
        portalElementId='nav-menu'
        menu={[
          { id: 'eventAttributes', title: 'Атрибути події' },
          { id: 'ecologicalDamages', title: 'Екологічні збитки' },
          { id: 'performers', title: 'Виконавці' },
          { id: 'witnesses', title: 'Очевидці' },
          { id: 'objects', title: 'Об’єкти' },
          ...values.objects.map(({}, index) => ({
              id: `object-${index}`,
              title: `Об’єкт ${index + 1}`
            }
          ))
        ]}
      />
      <div className={classes.attributesHeader}>
        <Typography variant="h2" data-test="page-title">Атрибути події</Typography>
        {showDocumentsRevisedStatusCount && (
          <div className={classes.documentsRevisedStatusCount}>{`${RETURNED_FOR_REVISION}: ${values.documentsRevisedStatusCount}`}</div>
        )}
      </div>
      <Box className={classes.eventsAttrs}>
        <Box className={classes.eventsAttrsLeft}>
          <Box className={classes.formControlRow}>
            <Box className={appClasses.flexWrapBetween}>
              <DataPicker
                disabled={isCreatedFolders() || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                className={classes.halfBlock}
                minDate={new Date('2022.02.24')}
                maxDate={new Date()}
                selectedDate={values.eventDate}
                error={Boolean(errors.eventDate)}
                label="Дата"
                helperText={errors.eventDate || REQUIRED_FIELD}
                handleDateChange={(date) => {
                  setFieldValue('eventDate', isValid(date) ? date.toISOString() : date, false);
                }}
                inputProps={{ 'data-test': 'event-date' }}
              />
              <TextField
                disabled={isCreatedFolders() || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                className={classes.halfBlock}
                variant="outlined"
                label="Час"
                type="time"
                helperText={errors.eventTime || REQUIRED_FIELD}
                value={values.eventTime}
                error={Boolean(errors.eventTime)}
                onChange={({ target }) => {
                  setFieldValue('eventTime', target.value, false);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ 'data-test': 'event-time' }}
              />
            </Box>
          </Box>
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              disabled={isCreatedFolders()
                || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)
                || (values.initialStatus && currentUserRoles.includes(WORKGROUP_HEAD) && ['DocumentsFinalized', 'DocumentsPrepared', 'DocumentsCheckedWithoutRemarks'].includes(values.initialStatus)
                )}
              label="Холдинг"
              onChange={(val) => {
                setFieldValue('holding', val.id, false);
                setFieldValue('enterprise', '', false);
                setFieldValue('subdivision', '', false); // reset value if subdivision is selected
              }}
              options={getHoldingOptions(holdingsDictionary)}
              optionValue="name"
              value={holdingsDictionary.find(({ id }) => id === values.holding)}
              error={Boolean(errors.holding)}
              data-test="holding"
            />
            <FormHelperText error={errors.holding}>
              Обов&apos;язкове поле
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Підприємство"
              onChange={(val) => {
                setFieldValue('enterprise', val.id, false);
                setFieldValue('subdivision', '', false);
              }}
              options={filteredEnterprises}
              disabled={isCreatedFolders() || !Boolean(values.holding) || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
              optionValue="name"
              value={filteredEnterprises.find(({ id }) => id === values.enterprise)}
              error={Boolean(errors.enterprise)}
              data-test="enterprise"
            />
            <FormHelperText error={errors.enterprise}>
              Обов&apos;язкове поле
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Підрозділ"
              optionValue="name"
              disabled={isCreatedFolders() || !Boolean(values.enterprise) || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
              onChange={(val) => setFieldValue('subdivision', val.id, false)}
              options={filteredUnits}
              value={filteredUnits.find(({ id }) => id === values.subdivision)}
              error={Boolean(errors.subdivision)}
              data-test="unit"
            />
            <FormHelperText error={errors.subdivision}>
              Обов&apos;язкове поле
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className={classes.eventsAttrsRight}>
          <Box className={classes.formControlRow}>
            <Box className={appClasses.flexWrapBetween}>
              <FormControl
                variant="outlined"
                className={classes.halfBlock}
              >
                <ResponsibleSelect
                  onChange={({ id = null } = {}) => setFieldValue('responsibleId', id, false)}
                  value={values.responsibleId}
                  holdingId={values.holding}
                />
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes.halfBlock}
              >
                <StatusSelect
                  status={values.status}
                  initialStatus={values.initialStatus}
                  error={Boolean(errors.status)}
                  setFieldValue={setFieldValue}
                />
                {errors.status && (
                  <FormHelperText error={errors.status}>
                    {errors.status}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Область місця події"
              optionValue="name"
              disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
              options={regionsDictionary}
              error={Boolean(errors.eventArea)}
              onChange={(val) => {
                setFieldValue('eventArea', val.id, false);
                setFieldValue('regionArea', '', false);
              }}
              value={regionsDictionary.find(({ id }) => id === values.eventArea)}
              data-test="event-area"
            />
            <FormHelperText
              error={errors.eventArea}
            >
              Обов&apos;язкове поле
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControlRow}>
            <CustomSelect
              autocomplete
              label="Район місця події"
              optionValue="name"
              disabled={!Boolean(values.eventArea) || !checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
              onChange={(val) => setFieldValue('regionArea', val.id, false)}
              options={filteredRegionsDistricts}
              value={filteredRegionsDistricts.find(({ id }) => id === values.regionArea)}
              error={Boolean(errors.regionArea)}
              data-test="region-area"
            />
            <FormHelperText error={errors.regionArea}>
              Обов&apos;язкове поле
            </FormHelperText>
          </FormControl>
          <Box className={classes.formControlRow}>
            <TextField
              fullWidth
              variant="outlined"
              label="Адреса місця події"
              helperText="Обов'язкове поле"
              inputProps={{ maxLength: 512, 'data-test': 'address-area' }}
              disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
              onChange={({ target }) => setFieldValue('addressArea', target.value, false)}
              value={values.addressArea}
              error={Boolean(errors.addressArea)}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.formControlRow}>
        <TextField
          fullWidth
          variant="outlined"
          label="Опис події"
          helperText="Обов'язкове поле"
          inputProps={{ maxLength: 1024, 'data-test': 'event-description' }}
          disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
          onChange={({ target }) => setFieldValue('eventDescription', target.value, false)}
          value={values.eventDescription}
          error={Boolean(errors.eventDescription)}
        />
      </Box>
      <Box className={classes.formControlRow}>
        <TextField
          fullWidth
          disabled={!checkRoles(currentUserRoles, EVENT_COMMENT)}
          variant="outlined"
          label="Коментар"
          inputProps={{ maxLength: 1024, 'data-test': 'event-comment' }}
          value={values.eventComment}
          onChange={({ target }) => setFieldValue('eventComment', target.value, false)}
        />
      </Box>
      <Box className={classes.formControlRow}>
        <Box className={classes.eventsCheckBoxes}>

          <FormControlLabel
            control={(
              <Switch
                disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                name="photo-video-fixation-is-unavailable"
                color="primary"
                disabled={!checkRoles(currentUserRoles, DOCUMENT_PREPARATION_CHECKBOXES)}
                checked={values.photoVideoFixationIsUnavailable}
                onChange={(e, val) => {
                  setFieldValue('photoVideoFixationIsUnavailable', val, false);
                  if (val) {
                    setFieldValue('documentsPhotoVideoReady', false, false);
                  }
                }}
                color="primary"
                inputProps={{ 'data-test': 'photo-video-fixation-is-unavailable' }}
              />
            )}
            label={(
              <Typography variant="subtitle2">
                Немає можливості фото/відео фіксації
              </Typography>
            )}
          />
        </Box>
      </Box>
      <Box className={classes.formControlRow}>
        <Box className={classes.eventsCheckBoxes}>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={!checkRoles(currentUserRoles, DOCUMENT_PREPARATION_CHECKBOXES) || values.photoVideoFixationIsUnavailable}
                checked={values.documentsPhotoVideoReady}
                onChange={(e, val) => {
                  setFieldValue('documentsPhotoVideoReady', val, false);
                }}
                color="primary"
                inputProps={{ 'data-test': 'documents-photo-video-ready' }}
              />
            )}
            label={(
              <Typography variant="subtitle2">
                Фото\відео фіксація
              </Typography>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                disabled={!checkRoles(currentUserRoles, DOCUMENT_PREPARATION_CHECKBOXES)}
                checked={values.documentsFixationReady}
                onChange={(e, val) => {
                  setFieldValue('documentsFixationReady', val, false);
                }}
                color="primary"
                inputProps={{ 'data-test': 'documents-fixation-ready' }}
              />
            )}
            label={(
              <Typography variant="subtitle2">
                Документальна фіксація (акт)
              </Typography>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                disabled={!checkRoles(currentUserRoles, DOCUMENT_PREPARATION_CHECKBOXES)}
                checked={values.documentsApplicationToLawEnforcementReady}
                onChange={(e, val) => {
                  setFieldValue('documentsApplicationToLawEnforcementReady', val, false);
                }}
                color="primary"
                inputProps={{ 'data-test': 'documents-application-to-law-enforcement-ready' }}
              />
            )}
            label={(
              <Typography variant="subtitle2">
                Заява у правоохоронні органи
              </Typography>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                disabled={!checkRoles(currentUserRoles, DOCUMENT_PREPARATION_CHECKBOXES)}
                checked={values.documentsFullyFormedReady}
                onChange={(e, val) => {
                  setFieldValue('documentsFullyFormedReady', val, false);
                }}
                color="primary"
                inputProps={{ 'data-test': 'documents-fully-formed-ready' }}
              />
            )}
            label={(
              <Typography variant="subtitle2">
                Повний пакет док. сформований
              </Typography>
            )}
          />
        </Box>
      </Box>
      <Tooltip
        disableHoverListener={Boolean(values?.id)}
        placement="top"
        title="Для додавання файлів спочатку потрібно зберегти подію"
      >
        <div>
          <AddFilesButton
            initialStatus={values.initialStatus}
            authorName={values.authorName}
            isFolderCreation={isFolderCreation}
            disabled={!values?.id}
            onClick={() => handleAddFileToEvent(values.sharePointFolderUrl, {
              caseId: values.id
            }, 'sharePointFolderUrl')}
          />
        </div>
      </Tooltip>
      <Divider className={classes.blockDivider}/>
      <Box id="ecologicalDamages" data-test="ecological-damages-block">
        <Typography variant="h2">Екологічні збитки</Typography>
        {values.environmentalRisks.map((item, index) => (
          <Box key={item.ecologicalDamageId} className={classes.shadowBlock}>
            <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
              <Typography variant="subtitle1">
                {`Екологiчна шкода ${index + 1}`}
              </Typography>
              {canWorkAsEcologist && (
                <IconButton
                  type="secondary"
                  onClick={() => handleRemoveEcologicalDamage(index)}
                >
                  <DeleteOutline/>
                </IconButton>
              )}
            </Box>
            <Box className={appClasses.flexWrapBetween}>
              <Box className={classes.eventsAttrsLeft}>
                <Box className={classes.formControlRow}>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      autocomplete
                      hasClearBtn
                      label="Екологiчна шкода"
                      optionValue="name"
                      options={getOptions(index)}
                      disabled={!canWorkAsEcologist}
                      value={environmentalRisksDictionary.find(({ id }) => id === item?.environmentalRiskId)}
                      error={Boolean(errors?.environmentalRisks?.[index]?.environmentalRiskId)}
                      onChange={({ id = '' } = {}) => {
                        if (id === noEcologicalDamageOptionId) {
                          setFieldValue(`environmentalRisks[${index}].environmentalRiskId`, id, false);
                          setFieldValue(`environmentalRisks[${index}].environmentalLoss`, '', false);
                          setFieldValue(`environmentalRisks[${index}].liquidationCost`, '', false);
                        } else {
                          const noEcologicalDamageOptionIndex = values.environmentalRisks.findIndex(({ environmentalRiskId }) => environmentalRiskId ===noEcologicalDamageOptionId);
                          if (noEcologicalDamageOptionIndex > -1) {
                            setFieldValue(
                              'environmentalRisks',
                              values.environmentalRisks.filter(({ environmentalRiskId }) => environmentalRiskId !== noEcologicalDamageOptionId),
                              false,
                            );
                           }
                          const indexToUpdate = (noEcologicalDamageOptionIndex > -1 && noEcologicalDamageOptionIndex < index) ? index - 1 : index;
                          setFieldValue(`environmentalRisks[${indexToUpdate}].environmentalRiskId`, id, false);
                        }
                      }}
                    />
                    <FormHelperText
                      error={errors?.environmentalRisks?.[index]?.environmentalRiskId}>
                      Обов&apos;язкове поле
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box className={classes.eventsAttrsRight}>
                <Box className={appClasses.flexWrapBetween}>
                  <Box className={classes.halfBlock}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      disabled={!canWorkAsEcologist || item?.environmentalRiskId === noEcologicalDamageOptionId}
                      fullWidth
                      variant="outlined"
                      label="Сума шкоди"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={item?.environmentalLoss}
                      onChange={({ target }) => {
                        setFieldValue(`environmentalRisks[${index}].environmentalLoss`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                  <Box className={classes.halfBlock}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      disabled={!canWorkAsEcologist || item?.environmentalRiskId === noEcologicalDamageOptionId}
                      fullWidth
                      variant="outlined"
                      label="Вартість ліквідації"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={item?.liquidationCost}
                      onChange={({ target }) => {
                        setFieldValue(`environmentalRisks[${index}].liquidationCost`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        {canWorkAsEcologist && (
          <FullWidthButton
            fullWidth
            size="large"
            type="cart-dashed"
            startIcon={<Add/>}
            onClick={handleAddEcologicalDamage}
          >
            Додати екологiчну шкоду
          </FullWidthButton>
        )}
      </Box>
      <Divider className={classes.blockDivider}/>

      <PerformersBlock
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        currentUserRoles={currentUserRoles}
        handleRemovePerformer={handleRemovePerformer}
        handleAddPerformer={handleAddPerformer}
      />
      <Divider className={classes.blockDivider}/>

      <Box id="witnesses" data-test='witnesses-block'>
        <Typography variant="h2">Очевидці</Typography>
        {values.witnesses.map((item, index) => {
          const objectTitleAddon = index < 2 ? '(Обов’язково)' : '';
          const RemoveBtn = (
            <IconButton
              type="secondary"
              onClick={() => handleRemoveWitness(index)}
              data-test='delete-witness-button'
            >
              <DeleteOutline/>
            </IconButton>
          );
          const additionalDataProps = !values?.witnesses?.[index]?.isEmployee
            ? { helperText: "Обов'язкове поле" } : {};

          return (
            <Box
              key={item.witnessId}
              className={classes.shadowBlock}
            >
              <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
                <Typography variant="subtitle1" data-test='witness-title'>
                  {`Очевидець ${index + 1} ${objectTitleAddon}`}
                </Typography>
                {values.witnesses.length > 2 && RemoveBtn}
              </Box>

              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="П.І.Б (Повністю)"
                  helperText="Обов'язкове поле"
                  inputProps={{ maxLength: 256, 'data-test': 'witness-name' }}
                  disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                  onChange={({ target }) => {
                    setFieldValue(`witnesses[${index}].name`, target.value, false);
                  }}
                  value={values?.witnesses?.[index]?.name}
                  error={Boolean(errors?.witnesses?.[index]?.name)}
                />
              </Box>
              <Box className={appClasses.flexWrapBetween}>
                <Box className={classes.eventsAttrsLeft}>
                  <Box className={classes.formControlRow}>
                    <PhoneInput
                      label="Телефон"
                      country={'ua'}
                      disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                      value={values?.witnesses?.[index]?.phone}
                      error={errors?.witnesses?.[index]?.phone}
                      onChange={(value) => setFieldValue(`witnesses[${index}].phone`, value, false)}
                      inputProps={{ 'data-test': 'witnesses-phone' }}
                    />
                  </Box>
                </Box>
                <Box className={classes.eventsAttrsRight}>
                  <FormControlLabel
                    control={(
                      <Switch
                        disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                        name="developmentFilter"
                        color="primary"
                        checked={values?.witnesses?.[index]?.isEmployee}
                        value={values?.witnesses?.[index]?.isEmployee}
                        onChange={(e, val) => {
                          setFieldValue(`witnesses[${index}].isEmployee`, val, false);
                          if (Boolean(errors?.witnesses?.[index]?.additionalData) && val) {
                            const additionalData = values?.witnesses?.[index]?.additionalData;
                            setFieldValue(`witnesses[${index}].additionalData`, additionalData, false);
                          }
                        }}
                        inputProps={{ 'data-test': 'is-employee' }}
                      />
                    )}
                    label={(<Typography variant="subtitle2">Є співробітіником</Typography>
                    )}
                  />
                </Box>
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Додаткові дані"
                  inputProps={{ maxLength: 512, 'data-test': 'additional-data' }}
                  disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                  value={values?.witnesses?.[index]?.additionalData}
                  error={Boolean(errors?.witnesses?.[index]?.additionalData)}
                  onChange={({ target }) => {
                    setFieldValue(`witnesses[${index}].additionalData`, target.value, false);
                  }}
                  {...additionalDataProps}
                />
              </Box>
            </Box>
          );
        })}
        <FullWidthButton
          disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD) || values.witnesses?.length >= WITNESSES_MAX_COUNT}
          fullWidth
          size="large"
          type="cart-dashed"
          startIcon={<PersonAddOutlined/>}
          onClick={handleAddWitness}
          data-test="add-witness-button"
        >
          Додати очевидця
        </FullWidthButton>
      </Box>
      <Divider className={classes.blockDivider}/>

      <Box id="objects" className={classes.blockForm} data-test="objects-block">
        <Typography variant="h2">Об’єкти</Typography>
        {values.objects.map((item, index) => {
          const objectTitleAddon = index === 0 ? '(Обов’язково)' : '';
          const RemoveBtn = (
            <IconButton
              type="secondary"
              onClick={() => handleRemoveObject(index, item?.sharePointFolderUrl)}
              data-test="delete-object-button"
            >
              <DeleteOutline/>
            </IconButton>
          );

          return (
            <Box
              key={item.objectId}
              className={classes.shadowBlock}
              data-test={`object-${index}`}
              id={`object-${index}`}
            >
              <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
                <Typography variant="subtitle1">
                  {item?.id ? `ID ${item?.id}` : `Об’єкт ${index + 1} ${objectTitleAddon}`}
                </Typography>
                {values.objects.length > 1 && RemoveBtn}
              </Box>
              <Box className={appClasses.flexWrapBetween}>
                <Box className={classes.eventsAttrsLeft}>
                  <Box className={classes.formControlRow}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Найменування майна"
                      helperText="Обов'язкове поле"
                      inputProps={{ maxLength: 256, 'data-test': 'object-title' }}
                      disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                      value={values?.objects?.[index]?.title}
                      error={Boolean(errors?.objects?.[index]?.title)}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].title`, target.value, false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Інвентарний номер"
                      inputProps={{ maxLength: 128, 'data-test': 'object-inventory-number' }}
                      disabled={!checkRoles(currentUserRoles, INVENTORY_NUMBER_FIELD)}
                      value={values?.objects?.[index]?.inventoryNumber}
                      helperText={errors?.objects?.[index]?.inventoryNumber}
                      error={Boolean(errors?.objects?.[index]?.inventoryNumber)}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].inventoryNumber`, target.value, false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Технічне місце SAP"
                      inputProps={{ maxLength: 128, 'data-test': 'object-tech-place-Sap' }}
                      disabled={!checkRoles(currentUserRoles, TECHPLACE_SAP_FIELD)}
                      value={values?.objects?.[index]?.techPlaceSap}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].techPlaceSap`, target.value, false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      fullWidth
                      variant="outlined"
                      label="Балансова вартість"
                      error={Boolean(errors?.objects?.[index]?.balansCost)}
                      {...Boolean(errors?.objects?.[index]?.balansCost) && { helperText: errors?.objects?.[index]?.balansCost }}
                      inputProps={{ min: 0, 'data-test': 'object-balance-cost' }}
                      disabled={!checkRoles(currentUserRoles, BALANS_COST_FIELD)}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={values?.objects?.[index]?.balansCost}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].balansCost`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      fullWidth
                      variant="outlined"
                      label="Вартість аварійно-відновлювальних робіт"
                      error={Boolean(errors?.objects?.[index]?.wreckRepairCost)}
                      {...Boolean(errors?.objects?.[index]?.wreckRepairCost) && { helperText: errors?.objects?.[index]?.wreckRepairCost }}
                      inputProps={{ min: 0, 'data-test': 'object-wreck-repair-cost' }}
                      disabled={!checkRoles(currentUserRoles, WRECK_REPAIR_COST_FIELD)}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={values?.objects?.[index]?.wreckRepairCost}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].wreckRepairCost`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                  <Box className={classes.formControlRow}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      fullWidth
                      variant="outlined"
                      label="Економічні збитки"
                      error={Boolean(errors?.objects?.[index]?.economicLoss)}
                      {...Boolean(errors?.objects?.[index]?.economicLoss) && { helperText: errors?.objects?.[index]?.economicLoss }}
                      inputProps={{ min: 0, 'data-test': 'object-economical-loss' }}
                      disabled={!checkRoles(currentUserRoles, ECONOMIC_LOSS_FIELD)}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={values?.objects?.[index]?.economicLoss}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].economicLoss`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classes.eventsAttrsRight}>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      autocomplete
                      label="Тип майна"
                      optionValue="name"
                      onChange={({ id }) => {
                        setFieldValue(`objects[${index}].propertyType`, id, false);
                      }}
                      disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                      value={propertyTypesDictionary.find(({ id }) => id === values?.objects?.[index]?.propertyType)}
                      options={propertyTypesDictionary}
                      error={Boolean(errors?.objects?.[index]?.propertyType)}
                      data-test="object-property-type"
                    />
                    <FormHelperText error={errors?.objects?.[index]?.propertyType}>
                      Обов&apos;язкове поле
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      autocomplete
                      label="Категорія шкоди"
                      optionValue="name"
                      onChange={({ id }) => {
                        setFieldValue(`objects[${index}].damageType`, id, false);
                      }}
                      disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                      options={damageCategoriesDictionary}
                      value={damageCategoriesDictionary.find(({ id }) => id === values?.objects?.[index]?.damageType)}
                      error={Boolean(errors?.objects?.[index]?.damageType)}
                      data-test="object-damage-type"
                    />
                    <FormHelperText error={errors?.objects?.[index]?.damageType}>
                      Обов&apos;язкове поле
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.customTagsWrapper}>
                    <DamageCompensationCategoriesSelect
                      shouldUseRoleModel
                      initialResponsibleId={values?.initialResponsibleId}
                      value={values?.objects?.[index]?.damageCompensationCategoryIds}
                      onChange={(enumValue) => {
                        if (values?.objects?.[index]?.damageCompensationCategoryIds?.includes(enumValue)) {
                          setFieldValue(`objects[${index}].damageCompensationCategoryIds`, values?.objects?.[index]?.damageCompensationCategoryIds?.filter(id => id !== enumValue), false);
                        } else {
                          setFieldValue(`objects[${index}].damageCompensationCategoryIds`, [...values?.objects?.[index]?.damageCompensationCategoryIds, enumValue], false);
                        }
                      }}
                      options={compensationCategoriesDictionary}
                    />
                  </FormControl>

                  <Box className={classes.formControlRow}>
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator="."
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator={" "}
                      fullWidth
                      variant="outlined"
                      label="Вартість відновлення/аналогу"
                      error={Boolean(errors?.objects?.[index]?.recoveryCost)}
                      {...Boolean(errors?.objects?.[index]?.recoveryCost) && { helperText: errors?.objects?.[index]?.recoveryCost }}
                      inputProps={{ min: 0, 'data-test': 'object-recovery-cost' }}
                      disabled={!checkRoles(currentUserRoles, RECOVERY_COST_FIELD)}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">тис. ₴ (без
                          ПДВ)</InputAdornment>,
                      }}
                      value={values?.objects?.[index]?.recoveryCost}
                      onChange={({ target }) => {
                        setFieldValue(`objects[${index}].recoveryCost`, parseFloat(target.value), false);
                      }}
                    />
                  </Box>
                  <Box
                    className={appClasses.flexWrapBetween}
                    style={{ alignItems: 'flex-start' }}
                  >
                    <FormControlLabel
                      control={(
                        <Switch
                          name="developmentFilter"
                          color="primary"
                          disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                          checked={values?.objects?.[index]?.isRepairComplete}
                          value={values?.objects?.[index]?.isRepairComplete}
                          onChange={(e, val) => {
                            setFieldValue(`objects[${index}].isRepairComplete`, val, false);
                            if (!val) setFieldValue(`objects[${index}].repairCompleteDate`, undefined, false);
                          }}
                          inputProps={{ 'data-test': 'object-is-repair-completed' }}
                        />
                      )}
                      label={(<Typography variant="subtitle2">Ремонт проведено</Typography>
                      )}
                    />
                    {values?.objects?.[index]?.isRepairComplete && (
                      <DataPicker
                        disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                        className={classes.halfBlock}
                        minDate={
                          values.eventDate
                          && isValid(new Date(values.eventDate))
                          && isBefore(new Date(2022, 1, 24, 0, 0, 0), new Date(values.eventDate))
                            ? values.eventDate
                            : new Date('2022.02.24')
                        }
                        maxDate={new Date()}
                        selectedDate={values?.objects?.[index]?.repairCompleteDate || null}
                        error={Boolean(errors?.objects?.[index]?.repairCompleteDate)}
                        label="Завершення ремонту"
                        helperText={errors?.objects?.[index]?.repairCompleteDate || REQUIRED_FIELD}
                        handleDateChange={(date) => {
                          setFieldValue(`objects[${index}].repairCompleteDate`, isValid(date) ? new Date(date.setHours(0, 0, 0)).toISOString() : date, false);
                        }}
                        inputProps={{ 'data-test': 'object-repair-completion-date' }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Опис пошкоджень"
                  helperText="Обов'язкове поле"
                  disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
                  error={Boolean(errors?.objects?.[index]?.damagesDescription)}
                  value={values?.objects?.[index]?.damagesDescription}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].damagesDescription`, target.value, false);
                  }}
                  inputProps={{ 'data-test': 'object-damages-description' }}
                />
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Коментар"
                  inputProps={{ maxLength: 1024, 'data-test': 'object-damages-comment' }}
                  value={values?.objects?.[index]?.comment}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].comment`, target.value, false);
                  }}
                />
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Коментар юриста бізнес"
                  inputProps={{ maxLength: 1024, 'data-test': 'object-business-lawyer-comment' }}
                  disabled={!checkRoles(currentUserRoles, BUSINESS_LAWYER_COMMENT)}
                  value={values?.objects?.[index]?.businessLawyerComment}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].businessLawyerComment`, target.value, false);
                  }}
                />
              </Box>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Коментар юриста КЦ"
                  inputProps={{ maxLength: 1024, 'data-test': 'object-kcLawyer-comment' }}
                  disabled={!checkRoles(currentUserRoles, KC_LAWYER_COMMENT)}
                  value={values?.objects?.[index]?.kcLawyerComment}
                  onChange={({ target }) => {
                    setFieldValue(`objects[${index}].kcLawyerComment`, target.value, false);
                  }}
                />
              </Box>
              <Tooltip
                disableHoverListener={Boolean(item?.id)}
                placement="top"
                title="Для додавання файлів спочатку потрібно зберегти подію"
              >
                <div>
                  <AddFilesButton
                    initialStatus={values.initialStatus}
                    authorName={values.authorName}
                    isFolderCreation={isFolderCreation}
                    disabled={!item?.id}
                    onClick={() => handleAddFileToEvent(item?.sharePointFolderUrl, {
                      caseId: values.id,
                      objectId: item.id,
                    }, `objects[${index}].sharePointFolderUrl`)}
                  />
                </div>
              </Tooltip>
            </Box>
          );
        })}
        <FullWidthButton
          disabled={!checkRoles(currentUserRoles, ALWAYS_EDITABLE_FIELD)}
          fullWidth
          size="large"
          type="cart-dashed"
          startIcon={<Add/>}
          onClick={handleAddObject}
          data-test="add-object-button"
        >
          Додати об’єкт
        </FullWidthButton>
      </Box>
      <DialogDefault
        title={ATTACH_FILES}
        open={isOpenSharePointFolderConfirmation}
        handleClose={() => setIsOpenSharePointFolderConfirmation(false)}
        activeActionText={SAVE}
        cancelActionText={CANCEL_V2}
        handleActiveAction={() => {
          setIsOpenSharePointFolderConfirmation(false);
          silentSaveAndCreateFilesLink({
            caseId: values.id,
          });
        }}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{ lineHeight: '32px', paddingBottom: 6 }}
        >
          {CREATE_SHAREPOINT_URL_TITLE}
        </Typography>
        <Typography data-test="dialog-text"
                    variant="subtitle2">{CREATE_SHAREPOINT_URL_SUBTITLE}</Typography>
      </DialogDefault>
    </Box>
  );
};

export default EditEventForm;
