import React, { useState, useEffect } from 'react';
import { get, isEmpty, uniq } from 'lodash';
import CustomSelect from 'components/PartCustomSelect';
import { EVENT_STATUS } from 'constants/texts';
import { useSelector } from 'react-redux';
import TextBlock from 'components/TextBlock';
import {
  BUSINESS_LAWYER,
  EVENT_REGISTRATION_RESPONSIBLE,
  WORKGROUP_HEAD,
  KC_LAWYER
} from 'constants/roles';

const StatusSelect = ({ setFieldValue, error, status, initialStatus }) => {
  const { roles = [] } = useSelector((state) => state.account?.user);
  const {
    caseStatusesDictionary,
    caseStatusesNamesObject,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  const [filteredCaseStatusesDictionary, setFilteredCaseStatusesDictionary] = useState([]);

  useEffect(() => {
    const availableOptions = [];
    if (roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus === 'InWork') {
      availableOptions.push('InWork', 'DocumentsPrepared');
    }
    if (roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus === 'DocumentsRevised') {
      availableOptions.push('DocumentsRevised', 'DocumentsPrepared');
    }
    if (roles.includes(WORKGROUP_HEAD) && initialStatus === 'InWork') {
      availableOptions.push('InWork', 'DocumentsPrepared');
    }
    if (roles.includes(WORKGROUP_HEAD) && initialStatus === 'DocumentsRevised') {
      availableOptions.push('DocumentsRevised', 'DocumentsPrepared');
    }

    if (roles.includes(BUSINESS_LAWYER) && initialStatus === 'DocumentsPrepared') {
      availableOptions.push('DocumentsPrepared', 'DocumentsCheckedWithoutRemarks', 'DocumentsRevised');
    }
    if (roles.includes(KC_LAWYER) && initialStatus === 'DocumentsCheckedWithoutRemarks') {
      availableOptions.push('DocumentsCheckedWithoutRemarks', 'DocumentsFinalized', 'DocumentsRevised');
    }
    if (!isEmpty(availableOptions)) {
      const uniqAvailableOptions = uniq(availableOptions);
      setFilteredCaseStatusesDictionary(caseStatusesDictionary.filter(({ enumValue = '' } = {}) => uniqAvailableOptions.includes(enumValue)));
    }
  }, [initialStatus]);

  const renderNotEditableStatus = (
    <TextBlock title={EVENT_STATUS}
               data-test="status">{get(caseStatusesNamesObject, `${status}.name`, '')}</TextBlock>
  );

  if (!initialStatus) return null;
  if (isEmpty(filteredCaseStatusesDictionary)) return renderNotEditableStatus;

  return (
    <CustomSelect
      error={error}
      optionValue="name"
      label={EVENT_STATUS}
      onChange={(val) => setFieldValue('status', val.enumValue, false)}
      options={filteredCaseStatusesDictionary}
      value={get(caseStatusesNamesObject, status, '')}
      data-test="status"
    />
  );
};

export default StatusSelect;
