import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import rules from './rules';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const hasPermission = (role, perform, data) => check(rules, role, perform, data);
const checkRoles = (roles, perform, data) =>
  roles?.some((role) => hasPermission(role, perform, data));

const CanWithRole = ({
  perform,
  data,
  roles,
  no,
  yes,
  render,
}) => {
  const acceptRole = checkRoles(roles, perform, data);

  if (render) {
    return render(acceptRole);
  }

  return acceptRole ? yes() : no();
};

CanWithRole.defaultProps = {
  yes: () => null,
  no: () => null,
};
CanWithRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  perform: PropTypes.string.isRequired,
  yes: PropTypes.func,
  no: PropTypes.func,
  render: PropTypes.func,
};

export { CanWithRole, hasPermission, checkRoles };
export default connect((state) => ({
    roles: state.account?.user?.roles,
  }
))(CanWithRole);
