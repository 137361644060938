import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import useStyles from './styles';


const StructureCell = ({ holdingId, enterpriseId, unitId }) => {
  const classes = useStyles();
  const {
    holdingsNamesObject,
    enterprisesNamesObject,
    unitsNamesObject,
  } = useSelector((state) => state.dictionaries?.dictionaries);
  const holdingName = get(holdingsNamesObject, `${holdingId}.name`, '');
  const enterpriseName = get(enterprisesNamesObject, `${enterpriseId}.name`, '');
  const unitName = get(unitsNamesObject, `${unitId}.name`, '');

  return (
    <div>
      {holdingName && <div className={classes.holding} data-test="holding">{holdingName}</div>}
      {enterpriseName &&
        <div className={classes.company} data-test="enterprise">{enterpriseName}</div>}
      {unitName && <div className={classes.unit} data-test="unit">{unitName}</div>}
    </div>
  );
};

StructureCell.propTypes = {
  holdingId: PropTypes.number,
  enterpriseId: PropTypes.number,
  unitId: PropTypes.number,
};

StructureCell.defaultProps = {
  holdingId: undefined,
  enterpriseId: undefined,
  unitId: undefined,
};

export default StructureCell;
