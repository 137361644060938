import { otherRoleName } from 'constants/main';

export const KC_LAWYER = 'KCLawyer';
export const ECOLOGIST = 'Ecologist';
export const BUSINESS_LAWYER = 'BusinessLawyer';
export const WORKGROUP_HEAD = 'WorkGroupHead';
export const EVENT_REGISTRATION_RESPONSIBLE = 'EventRegistrationResponsible';
export const FINANCIER = 'Financier';
export const DISPATCHER = 'Dispatcher';
export const READER = 'Reader';
export const ASSISTANT = 'Assistant';

export const rolesTranslations = {
  [DISPATCHER]: 'Диспетчер',
  [FINANCIER]: 'Фінансист',
  [EVENT_REGISTRATION_RESPONSIBLE]: 'Відповідальний',
  [WORKGROUP_HEAD]: 'Керівник РГ',
  [BUSINESS_LAWYER]: 'Юрист Бізнес',
  [KC_LAWYER]: 'Юрист КЦ',
  [ECOLOGIST]: 'Еколог',
  [READER]: 'Оглядач',
  [otherRoleName]: 'Інша роль',
  [ASSISTANT]: 'Помічник',
};
