import {
  Switch as MuiSwitch,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Switch = withStyles((theme) => ({
  switchBase: {
    color: theme.colors.midGray,
    '&$checked': {},
    '&$checked + $track': {
      backgroundColor: theme.colors.lightSelection,
      opacity: 1,
    },
  },
  checked: {},
  track: {},
  thumb: {
    boxShadow: 'none',
  },
}
))(MuiSwitch);

export default Switch;
