import React, { useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import useStyles from './styles';
import ua from './ua.json';
import { ERROR, REQUIRED_FIELD } from 'constants/texts';
import { FormHelperText } from "@material-ui/core";

const PhoneInput = ({ value, onChange, label, error, readOnly, disabled, inputProps }) => {
  const classes = useStyles({ error: Boolean(error), readOnly });
  const [focused, setFocused] = useState(false);
  const [countryData, setCountryData] = useState({});
  const format = get(countryData, 'format', '()');
  const dialCode = get(countryData, 'dialCode', '');

  const getPlaceholder = () => {
    const placeholder = `+${dialCode} ${format.slice(dialCode.length + 1).replace(/\./g, 'x')}`;
    return format || placeholder;
  };

  return (<div className={classes.wrapper}>
      <ReactPhoneInput
        disableDropdown={disabled || readOnly}
        disabled={disabled || readOnly}
        defaultErrorMessage={ERROR}
        localization={ua}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={getPlaceholder()}
        specialLabel={label}
        country={'ua'}
        value={value}
        onChange={(value, data) => {
          onChange(value);
          const { dialCode: newDialCode } = data;
          if (newDialCode !== dialCode) {
            setCountryData(data);
          }
        }}
        {...inputProps && { inputProps }}
        inputClass={clsx(classes.inputClass, (value === dialCode || !value
        ) && classes.greyColored)}
        containerClass={clsx(classes.containerClass, focused && classes.focused)}
        onMount={(value, data) => setCountryData(data)}
      />
      {(!disabled || readOnly
      ) && (<FormHelperText error={error} className={classes.formHelperText}>
          {error || REQUIRED_FIELD}
        </FormHelperText>
      )}
    </div>
  );
};

export default PhoneInput;