import { format } from 'date-fns';
import { get, toSafeInteger, isEmpty, isNull } from 'lodash';
import { v4 as aniqueId } from 'uuid';
import { otherRoleName } from 'constants/main';
import {
  CHECKED_WITHOUT_REMARKS,
  DOCUMENTS_FINALIZED,
  DOCUMENTS_PREPARED,
  DOCUMENTS_REVISED,
  IN_WORK,
  REGISTERED,
} from 'constants/texts';
import {
  PERFORMERS_FINANCIER_COMMENT,
  PERFORMERS_KC_LAWYER_COMMENT,
  PERFORMERS_RESPONSIBLE_COMMENT,
  PERFORMERS_HEAD_COMMENT,
  PERFORMERS_BUSINESS_LAWYER_COMMENT,
  PERFORMERS_ECOLOGIST_COMMENT,
} from 'constants/permissions';
import { DISPATCHER, EVENT_REGISTRATION_RESPONSIBLE } from '../constants/roles';

export function mapEventData(data) {
  return {
    eventDate: data.eventDate || new Date(),
    eventTime: data.eventTime || '-',
    holding: data.holding || '-',
    enterprise: data.enterprise || '-',
    subdivision: data.subdivision || '-',
    responsible: data.responsible || '-',
    status: data.status || '-',
    eventArea: data.eventArea || '-',
    regionArea: data.regionArea || '-',
    addressArea: data.addressArea || '-',
    eventDescription: data.eventDescription || '-',
    eventComment: data.eventComment || '-',
    witnesses: data.witnesses.map((witness) => ({
        name: witness.name,
        phone: witness.phone,
        additionalData: witness.additionalData,
        isEmployee: witness.isEmployee,
      }
    )),
    objects: data.objects.map((object) => ({
        title: object.title,
        inventoryNumber: object.inventoryNumber,
        techPlaceSap: object.techPlaceSap,
        balansCost: object.balansCost,
        emergencyRepairsCost: object.emergencyRepairsCost,
        economicDamage: object.economicDamage,
        propertyType: object.propertyType,
        damageType: object.damageType,
        environmentalDamage: object.environmentalDamage,
        recoveryCost: object.recoveryCost,
        isRepairComplete: object.isRepairComplete,
        repairCompleteDate: object.repairCompleteDate,
        damagesDescription: object.damagesDescription,
        lawyerComment: object.lawyerComment,
        lawyerCommentKC: object.lawyerCommentKC,
      }
    )),
  }
}

export function mapEventToServerData(formData, isEdit) {
  if (!formData) return;

  const getDate = ({ eventDate, eventTime }) => {
    let date = eventDate;
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const [hours, minutes] = eventTime.split(':');
    const parsedMinutes = toSafeInteger(minutes);
    const parsedHours = toSafeInteger(hours);
    date.setHours(parsedHours, parsedMinutes, 0);
    return date.toISOString();
  };
  const editParams = isEdit
    ? {
      holdingId: formData.holding,
      status: formData.status,
      performers: formData.performers.map((it) => ({
          id: it.id || null,
          name: it.name,
          role: it.role,
          roleDescription: it.roleDescription || null,
          comment: it.comment || null,
        }
      )),
      environmentalRisks: formData.environmentalRisks.map((it) => ({
          environmentalLoss: it?.environmentalLoss,
          environmentalRiskId: it?.environmentalRiskId,
          liquidationCost: it?.liquidationCost,
        }
      )),
      documentsPhotoVideoReady: formData.documentsPhotoVideoReady,
      documentsFixationReady: formData.documentsFixationReady,
      documentsApplicationToLawEnforcementReady: formData.documentsApplicationToLawEnforcementReady,
      photoVideoFixationIsUnavailable: formData.photoVideoFixationIsUnavailable,
      documentsFullyFormedReady: formData.documentsFullyFormedReady,
    } : {
      responsibleId: formData.responsibleId,
    };
  const editObjectParams = (it) => isEdit
    ? {
      id: it.id,
      wreckRepairCost: it.wreckRepairCost || null,
      recoveryCost: it.recoveryCost || null,
      economicLoss: it.economicLoss || null,
      businessLawyerComment: it.businessLawyerComment || null,
      kcLawyerComment: it.kcLawyerComment || null,
    } : {};

  return {
    ...editParams,
    date: getDate({ eventDate: formData.eventDate, eventTime: formData.eventTime }),
    enterpriseId: formData.enterprise,
    unitId: formData.subdivision,
    regionId: formData.eventArea,
    districtId: formData.regionArea,
    address: formData.addressArea || null,
    description: formData.eventDescription || null,
    comment: formData.eventComment || null,
    witnesses: formData.witnesses.map((it) => ({
        name: it.name,
        phone: it.phone,
        additionalData: it.additionalData,
        isEmployee: it.isEmployee,
      }
    )),
    objects: formData.objects.map((it) => (
      {
        ...editObjectParams(it),
        name: it.title,
        inventoryNumber: it.inventoryNumber || null,
        technicalPlaceSAP: it.techPlaceSap || null,
        propertyTypeId: it.propertyType,
        damageCategoryId: it.damageType,
        damageDescription: it.damagesDescription || null,
        wasRepaired: it.isRepairComplete,
        repairedDate: it.repairCompleteDate || null,
        bookValue: it.balansCost || null,
        comment: it.comment || null,
        damageCompensationCategoryIds: !isEmpty(it?.damageCompensationCategoryIds) ? it?.damageCompensationCategoryIds : null,
      }
    ))
  }
}

const preparePerformersToClient = (performers, user, currentUserRoles, isView) => {
  let isCurrentPerformerByName = false;

  let mappedPerformers = performers.map((it) => {
    const performer = {
      performerId: aniqueId(),
      id: it.id || null,
      name: it.name,
      role: it.role,
      roleDescription: it.roleDescription || null,
      comment: it.comment || '',
      isNotEditable: it.role === otherRoleName,
      isCurrentPerformer: it.name === user?.name
    };
    if (!isView && performers.some(({ name }) => name === user?.name)) {
      isCurrentPerformerByName = true;
    }
    return performer;
  });

  if (isView) {
    return mappedPerformers;
  }

  const isResponsible = currentUserRoles.includes(EVENT_REGISTRATION_RESPONSIBLE);
  const isDispatcher = currentUserRoles.includes(DISPATCHER);
  let initialPerformers = mappedPerformers;
  if (!isResponsible && !isDispatcher) {
    initialPerformers = isCurrentPerformerByName
      ? mappedPerformers
      : [{
        performerId: aniqueId(),
        name: user?.name,
        role: user?.roles?.[0], // should display first role by default
        roleDescription: null,
        comment: '',
        isCurrentPerformer: true,
      }, ...mappedPerformers];
  }

  return initialPerformers;
};

export function mapEventDataToClient({ formData, user, currentUserRoles, isView }) {
  return {
    status: formData.status,
    initialStatus: formData.status,
    documentsRevisedStatusCount: formData.documentsRevisedStatusCount,
    initialResponsibleId: formData.responsibleId|| null,
    responsibleId: formData.responsibleId || null,
    responsibleName: formData.responsibleName,
    initialResponsibleName: formData.responsibleName,
    authorName: formData.authorName,
    authorId: formData.authorId,
    id: formData.id,
    eventDate: new Date(new Date(formData.date).setHours(0, 0, 0, 0)).toISOString(),
    eventDate_view: format(new Date(formData.date), 'dd/MM/yyyy'),
    eventTime: format(new Date(formData.date), 'HH:mm'),
    holding: formData.holdingId,
    enterprise: formData.enterpriseId,
    subdivision: formData.unitId,
    eventArea: formData.regionId,
    regionArea: formData.districtId,
    addressArea: formData.address,
    eventDescription: formData.description,
    eventComment: formData.comment || '',
    businessLawyerComment: formData.businessLawyerComment,
    kcLawyerComment: formData.kcLawyerComment,
    documentsPhotoVideoReady: formData.documentsPhotoVideoReady,
    documentsFixationReady: formData.documentsFixationReady,
    documentsApplicationToLawEnforcementReady: formData.documentsApplicationToLawEnforcementReady,
    photoVideoFixationIsUnavailable: formData.photoVideoFixationIsUnavailable,
    documentsFullyFormedReady: formData.documentsFullyFormedReady,
    isSharePointFolderHasItems: formData.isSharePointFolderHasItems,
    sharePointFolderUrl: formData.sharePointFolderUrl,
    totalLoss: formData.totalLoss,
    allObjectsLossFilled: formData.allObjectsLossFilled,
    environmentalRisks: formData?.environmentalRisks?.map((it) => ({
        ...it,
        environmentalLoss: it?.environmentalLoss ? it?.environmentalLoss.toFixed(2).toString() : '',
        environmentalLossView: it?.environmentalLoss,
        liquidationCost: it?.liquidationCost ? it?.liquidationCost.toFixed(2).toString() : '',
        liquidationCostView: it?.liquidationCost,
        ecologicalDamageId: aniqueId()
      }
    )) || [],
    witnesses: formData.witnesses.map((it) => ({
        ...it,
        additionalData: it.additionalData || '',
        phone: it?.phone ? it?.phone.replaceAll(/[\(\)\s-]/g, "") : '',
        witnessId: aniqueId()
      }
    )),
    performers: preparePerformersToClient(formData?.performers, user, currentUserRoles, isView),
    objects: formData.objects.map((it) => ({
        objectId: aniqueId(),
        id: it?.id,
        oldId: it?.oldId,
        title: it?.name,
        inventoryNumber: it?.inventoryNumber || '',
        techPlaceSap: it?.technicalPlaceSAP || '',
        balansCost: it?.bookValue ? it?.bookValue.toFixed(2).toString() : '',
        balansCostView: it?.bookValue,
        propertyType: it?.propertyTypeId,
        damageType: it?.damageCategoryId,
        isRepairComplete: it?.wasRepaired || false,
        repairCompleteDate: it?.repairedDate && new Date(new Date(it.repairedDate).setHours(0, 0, 0, 0)).toISOString(),
        repairCompleteDate_view: it?.repairedDate && format(new Date(it.repairedDate), 'dd/MM/yyyy'),
        damagesDescription: it?.damageDescription || '',
        comment: it?.comment  || '',
        businessLawyerComment: it?.businessLawyerComment || '',
        businessLawyerName: it?.businessLawyerName || '',
        ecologistName: it?.ecologistName || '',
        kcLawyerComment: it?.kcLawyerComment || '',
        kcLawyerName: it?.kcLawyerName || '',
        wreckRepairCost: it?.wreckRepairCost ? it?.wreckRepairCost.toFixed(2).toString() : '',
        wreckRepairCostView: it?.wreckRepairCost,
        economicLoss: it?.economicLoss ? it?.economicLoss.toFixed(2).toString() : '',
        economicLossView: it?.economicLoss,
        recoveryCost: it?.recoveryCost ? it?.recoveryCost.toFixed(2).toString() : '',
        recoveryCostView: it?.recoveryCost,
        totalLoss: it?.totalLoss || '',
        isSharePointFolderHasItems: it?.isSharePointFolderHasItems,
        sharePointFolderUrl: it?.sharePointFolderUrl,
        damageCompensationCategoryIds: (!isNull(it?.damageCompensationCategoryIds) && it?.damageCompensationCategoryIds
        ) ? it?.damageCompensationCategoryIds : [],
      }
    )),
  }
}

export function assignStatusesTranslations(statuses) {
  const statusesTranslations = {
    1: REGISTERED,
    2: IN_WORK,
    3: DOCUMENTS_PREPARED,
    4: DOCUMENTS_REVISED,
    5: CHECKED_WITHOUT_REMARKS,
    6: DOCUMENTS_FINALIZED
  };
  return statuses.map(({ id, name }) => ({
      id,
      enumValue: name,
      name: get(statusesTranslations, id, name)
    }
  ));
}

export function rolesTranslations(roles) {
  const rolesTranslations = {
    0: { text: 'Інша роль', permission: null },
    1: { text: 'Диспетчер', permission: null },
    2: { text: 'Фінансист', permission: PERFORMERS_FINANCIER_COMMENT },
    3: { text: 'Відповідальний', permission: PERFORMERS_RESPONSIBLE_COMMENT },
    4: { text: 'Керівник РГ', permission: PERFORMERS_HEAD_COMMENT },
    5: { text: 'Юрист Бізнес', permission: PERFORMERS_BUSINESS_LAWYER_COMMENT },
    6: { text: 'Юрист КЦ', permission: PERFORMERS_KC_LAWYER_COMMENT },
    7: { text: 'Еколог', permission: PERFORMERS_ECOLOGIST_COMMENT },
    8: { text: 'Рідер', permission: null },
    9: { text: 'Помічник', permission: null },
  };
  const translatedRoles = roles.map(({ id, name }) => ({
      id,
      enumValue: name,
      name: rolesTranslations?.[id]?.text,
      permission: rolesTranslations?.[id]?.permission,
    }
  ));

  return translatedRoles;
}
