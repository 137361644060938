import {
  CREATE_EVENT,
  EDIT_STATUS_FIELD,
  ADD_FILES_TO_EVENT_ON_CREATION,
  ADD_FILES_TO_OBJECT_ON_CREATION,
  BUSINESS_LAWYER_COMMENT,
  ALWAYS_EDITABLE_FIELD,
  KC_LAWYER_COMMENT,
  ENVIRONMENTAL_RISKS_SELECT,
  ENVIRONMENTAL_FIELD_LOSS,
  ECOLOGIST_COMMENT_FIELD,
  INVENTORY_NUMBER_FIELD,
  TECHPLACE_SAP_FIELD,
  BALANS_COST_FIELD,
  WRECK_REPAIR_COST_FIELD,
  RECOVERY_COST_FIELD,
  ECONOMIC_LOSS_FIELD,
  DOCUMENT_PREPARATION_CHECKBOXES,
  RESPONSIBLE_SELECT,
  PERFORMERS_BLOCK,
  PERFORMERS_FINANCIER_COMMENT,
  PERFORMERS_KC_LAWYER_COMMENT,
  PERFORMERS_RESPONSIBLE_COMMENT,
  PERFORMERS_HEAD_COMMENT,
  PERFORMERS_BUSINESS_LAWYER_COMMENT,
  PERFORMERS_ECOLOGIST_COMMENT,
  EVENT_COMMENT,
  RESPONSIBLE_FILTER,
  MULTIPLE_RESPONSIBLE_SELECT,
} from 'constants/permissions';
import {
  KC_LAWYER,
  ECOLOGIST,
  BUSINESS_LAWYER,
  WORKGROUP_HEAD,
  EVENT_REGISTRATION_RESPONSIBLE,
  FINANCIER,
  DISPATCHER,
  ASSISTANT,
} from 'constants/roles';

const rules = {
  [KC_LAWYER]: {
    static: [
      EDIT_STATUS_FIELD,
      KC_LAWYER_COMMENT,
      DOCUMENT_PREPARATION_CHECKBOXES,
      PERFORMERS_BLOCK,
      PERFORMERS_KC_LAWYER_COMMENT,
      EVENT_COMMENT,
    ],
  },
  [ECOLOGIST]: {
    static: [
      ENVIRONMENTAL_RISKS_SELECT,
      ENVIRONMENTAL_FIELD_LOSS,
      ECOLOGIST_COMMENT_FIELD,
      PERFORMERS_BLOCK,
      PERFORMERS_ECOLOGIST_COMMENT,
    ],
  },
  [BUSINESS_LAWYER]: {
    static: [
      EDIT_STATUS_FIELD,
      BUSINESS_LAWYER_COMMENT,
      DOCUMENT_PREPARATION_CHECKBOXES,
      PERFORMERS_BLOCK,
      PERFORMERS_BUSINESS_LAWYER_COMMENT,
      EVENT_COMMENT,
    ],
  },
  [WORKGROUP_HEAD]: {
    static: [
      CREATE_EVENT,
      EDIT_STATUS_FIELD,
      ENVIRONMENTAL_RISKS_SELECT,
      ENVIRONMENTAL_FIELD_LOSS,
      ECOLOGIST_COMMENT_FIELD,
      ALWAYS_EDITABLE_FIELD,
      BUSINESS_LAWYER_COMMENT,
      KC_LAWYER_COMMENT,
      INVENTORY_NUMBER_FIELD,
      TECHPLACE_SAP_FIELD,
      BALANS_COST_FIELD,
      WRECK_REPAIR_COST_FIELD,
      RECOVERY_COST_FIELD,
      ECONOMIC_LOSS_FIELD,
      DOCUMENT_PREPARATION_CHECKBOXES,
      PERFORMERS_BLOCK,
      PERFORMERS_HEAD_COMMENT,
      EVENT_COMMENT,
      RESPONSIBLE_FILTER,
      RESPONSIBLE_SELECT,
      MULTIPLE_RESPONSIBLE_SELECT
    ],
  },
  [EVENT_REGISTRATION_RESPONSIBLE]: {
    static: [
      CREATE_EVENT,
      EDIT_STATUS_FIELD,
      ADD_FILES_TO_EVENT_ON_CREATION,
      ADD_FILES_TO_OBJECT_ON_CREATION,
      ALWAYS_EDITABLE_FIELD,
      ENVIRONMENTAL_RISKS_SELECT,
      ENVIRONMENTAL_FIELD_LOSS,
      ECOLOGIST_COMMENT_FIELD,
      BUSINESS_LAWYER_COMMENT,
      KC_LAWYER_COMMENT,
      INVENTORY_NUMBER_FIELD,
      TECHPLACE_SAP_FIELD,
      BALANS_COST_FIELD,
      WRECK_REPAIR_COST_FIELD,
      RECOVERY_COST_FIELD,
      ECONOMIC_LOSS_FIELD,
      DOCUMENT_PREPARATION_CHECKBOXES,
      RESPONSIBLE_SELECT,
      PERFORMERS_BLOCK,
      PERFORMERS_RESPONSIBLE_COMMENT,
      EVENT_COMMENT,
    ],
  },
  [FINANCIER]: {
    static: [
      ENVIRONMENTAL_FIELD_LOSS,
      INVENTORY_NUMBER_FIELD,
      TECHPLACE_SAP_FIELD,
      BALANS_COST_FIELD,
      WRECK_REPAIR_COST_FIELD,
      RECOVERY_COST_FIELD,
      ECONOMIC_LOSS_FIELD,
      PERFORMERS_BLOCK,
      PERFORMERS_FINANCIER_COMMENT,
      EVENT_COMMENT,
    ],
  },
  [DISPATCHER]: {
    static: [
      CREATE_EVENT,
      ADD_FILES_TO_EVENT_ON_CREATION,
      ADD_FILES_TO_OBJECT_ON_CREATION,
      ALWAYS_EDITABLE_FIELD,
      ENVIRONMENTAL_RISKS_SELECT,
      INVENTORY_NUMBER_FIELD,
      TECHPLACE_SAP_FIELD,
      BALANS_COST_FIELD,
      EVENT_COMMENT,
    ],
  },
  [ASSISTANT]: {
    static: [
      DOCUMENT_PREPARATION_CHECKBOXES,
    ],
  },
};

export default rules;
