import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import account from './account';
import casesList from './casesList';
import event from './event';
import dictionaries from './dictionaries';
import notifications from './notifications';
import polling from './pollingSlice';

const reducer = combineReducers({
  account,
  dictionaries,
  casesList,
  event,
  notifications,
  polling,
});
const store = configureStore({
  reducer,
});

export default store;
