import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
}
));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open timeout={0}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
}
