import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  lossesAmountWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  currencyUnit: {
    color: theme.colors.darkGray,
    fontSize: 14,
    marginRight: 4,
  },
  lossesAmount: {
    color: theme.colors.error,
    fontSize: 14,
  },
}
));

export default useStyles;
