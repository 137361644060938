import React from 'react';

import { Button } from 'components/ui-lib';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    height: 80,
  },
}
));

const FullWidthButton = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      {...props}
    >
      {children}
    </Button>
  );
};

export default FullWidthButton;
