import React from 'react';
import { useSelector } from 'react-redux';
import { groupBy, mapValues, keys, get, head } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { rolesTranslations } from 'constants/roles';

import useStyles from './styles';

const UsersRoles = () => {
  const classes = useStyles();
  const {
    access = [],
    roles = [],
  } = useSelector((state) => state.account?.user);

  const {
    holdingsDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  let userRoles = '—';

  const rolesCount = access.length;
  const rolesGroupByHoldings = groupBy(access, ({ holdingId }) => holdingId ? holdingId : 'withoutHolding');
  const mappedRolesGroupByHoldings = mapValues(rolesGroupByHoldings, (rolesArray = []) => rolesArray.map(({ role }) => role));
  const holdings = keys(rolesGroupByHoldings).filter(it => it !== 'withoutHolding');
  const holdingsCount = holdings.length;
  const hasRolesWithoutHolding = rolesGroupByHoldings.withoutHolding?.length;
  if (access.length === 1) {
    userRoles = get(rolesTranslations, get(head(access), 'role'), 'Інша роль');
  } else {
    userRoles = (
      <Tooltip className={classes.tooltip} title={(
        <div>
          {holdings.map((holdingId) => (
            <div key={holdingId} className={classes.holdingWrapper}>
              <div className={classes.holdingTitle}>{get(holdingsDictionary.find(({ id }) => id.toString() === holdingId.toString()), 'name', holdingId)}</div>
              {mappedRolesGroupByHoldings[holdingId].map((role) => get(rolesTranslations, role, 'Інша роль' )).join(', ')}
            </div>
          ))}
          {hasRolesWithoutHolding && (
            <div className={classes.holdingWrapper}>
              <div className={classes.holdingTitle}>Ролі без холдингу</div>
              <div>
                {mappedRolesGroupByHoldings['withoutHolding'].map((role) => get(rolesTranslations, role, 'Інша роль' )).join(', ')}
              </div>
            </div>
          )}
        </div>
      )}>
        <div>{`Холдингів: ${holdingsCount}, Ролей: ${roles.length || rolesCount}`}</div>
      </Tooltip>
    )
  }

  return (<div className={classes.usersRolesRoot}>{userRoles}</div>
  );
};

export default UsersRoles;
