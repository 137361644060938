const routeBits = {
  toContainer: '/containers/container',
  event: '/event',
};

const routes = {
  main: '/',
  event: `${routeBits.event}/:eventId/:holdingId`,
  editEvent: `${routeBits.event}/:eventId/:holdingId/edit`,
  createEvent: '/create-event',
  noAccess: '/no-access',
  notFound: '/404',
};

const getRouterPaths = () => Object.keys(routes).map((key) => routes[key]);

export { routeBits, getRouterPaths };
export default routes;
