import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, isEmpty, head } from 'lodash';
import CustomSelect from 'components/PartCustomSelect';
import { RESPONSIBLE } from 'constants/texts';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'store/casesList';
import TextBlock from 'components/TextBlock';
import { EVENT_REGISTRATION_RESPONSIBLE, WORKGROUP_HEAD } from 'constants/roles';
import DottedLoader from 'components/DottedLoader';
import { checkRoles, RESPONSIBLE_FILTER } from 'containers/RoleModel';

const MainPageResponsibleSelect = ({ onChange, value }) => {
  const dispatch = useDispatch();
  const {
    roles = [],
    id: currentUserId,
    name: currentUserName
  } = useSelector((state) => state.account?.user);

  const {
    data: { initialResponsibleId, initialResponsibleName, initialStatus } = {},
  } = useSelector((state) => state.event?.event);
  const { holdings = [] } = useSelector((state) => state.account?.user);

  const [isLoadingResponsible, setIsLoadingResponsible] = useState(false);
  const [responsibleList, setResponsibleList] = useState([]);

  useEffect(() => {
    setIsLoadingResponsible(true);
    dispatch(getUsers({ role: EVENT_REGISTRATION_RESPONSIBLE, holdingId: head(holdings) })).then((data) => {
      const list = get(data, 'payload', []);
      setIsLoadingResponsible(false);
      if (list.some(({ id }) => id === currentUserId)) {
        setResponsibleList(list);
        return;
      }
      setResponsibleList(list);
    });
  }, []);

  return (
    <DottedLoader isLoading={isLoadingResponsible}>
      <CustomSelect
        autocomplete={checkRoles(roles, RESPONSIBLE_FILTER)}
        hasClearBtn
        label={RESPONSIBLE}
        optionValue="name"
        value={responsibleList.find(({ id }) => id === value)}
        onChange={onChange}
        options={responsibleList}
        data-test="responsible"
      />
    </DottedLoader>
  );
};

export default MainPageResponsibleSelect;
