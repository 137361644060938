import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';

import {
  editEvent,
  loadEvent,
  resetError,
  checkOnUniqueInventoryNumber,
  resetEventStore,
} from 'store/event';
import { getCurrentUserRoleSelector, getUserSelector } from 'store/account';
import { setResponsibleForCases } from 'store/casesList';
import { LOADING, SUCCEEDED } from 'constants/main';
import { showNotification } from 'store/notifications';
import { mapEventToServerData } from 'utils/mappers';
import routes, { routeBits } from 'routes';
import NoAccess from 'pages/NoAccess';
import {
  EDIT_EVENT_TITLE,
  CANCEL,
  SAVE,
  FORM_HAS_ERRORS, ERROR_ASSIGNING_RESPONSIBLE,
} from 'constants/texts';
import { editEventFormValidation } from 'utils/validation';
import { Typography, Button, DialogDefault } from 'components/ui-lib';
import ContentSpinner from 'components/ContentSpinner';
import PageHeader from 'components/PageHeader';
import UniqueInventoryNumberDialog from 'components/UniqueInventoryNumberDialog';
import CanEditEvent from 'containers/CanEditEvent';

import EditEventForm from './components/EditEventForm';
import useAppStyles from 'containers/App/AppStyles';

const initBlockValues = {
  environmentalRisk: {
    environmentalRiskId: null,
    environmentalLoss: null,
    liquidationCost: null
  },
  performer: {
    name: null,
    role: null,
    roleDescription: null,
    comment: null,
  },
  witness: {
    name: null,
    phone: null,
    additionalData: null,
    isEmployee: true,
  },
  object: {
    title: null,
    inventoryNumber: null,
    techPlaceSap: null,
    balansCost: null,
    wreckRepairCost: null,
    economicLoss: null,
    propertyType: null,
    damageType: null,
    environmentalRisks: null,
    environmentalLoss: null,
    recoveryCost: null,
    isRepairComplete: false,
    repairCompleteDate: new Date(),
    damagesDescription: null,
    comment: null,
    businessLawyerComment: null,
    kcLawyerComment: null,
    ecologistComment: null,
  },
};

const EditEvent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uniqueInventoryNumberList, setUniqueInventoryNumberList] = useState(null);
  const [isDeletedObjectWithFiles, setDeletedObjectWithFiles] = useState(false);
  const [isVisibleModalWithDeletedFolder, setVisibleModalWithDeletedFolder] = useState(false);
  const appClasses = useAppStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector(getUserSelector);
  const currentUserRoles = useSelector(getCurrentUserRoleSelector);
  const {
    data,
    initialData,
    status,
    error,
  } = useSelector((state) => state.event?.event);

  const formik = useFormik({
    initialValues: {
      eventDate: new Date(),
      eventTime: null,
      holding: null,
      enterprise: null,
      subdivision: null,
      responsibleId: null,
      status: null,
      eventArea: null,
      regionArea: null,
      addressArea: null,
      eventDescription: null,
      eventComment: null,
      businessLawyerComment: null,
      kcLawyerComment: null,
      documentsPhotoVideoReady: false,
      photoVideoFixationIsUnavailable: false,
      documentsFixationReady: false,
      documentsApplicationToLawEnforcementReady: false,
      documentsFullyFormedReady: false,
      isSharePointFolderHasItems: false,
      sharePointFolderUrl: null,
      environmentalRisks: [],
      performers: [],
      witnesses: [initBlockValues.witness, initBlockValues.witness],
      objects: [initBlockValues.object],
    },
    validationSchema: editEventFormValidation,
    onSubmit: async (values) => {
      const inventoryNumbersIsEmpty = !values?.objects?.some((it) => Boolean(it?.inventoryNumber));

      if (isDeletedObjectWithFiles) {
        // modal for submit by click on Save button
        return setVisibleModalWithDeletedFolder(true);
      }

      if (inventoryNumbersIsEmpty) {
        return handleSubmitForm(values);
      }

      setIsSubmitting(true);
      const { payload = [] } = await dispatch(checkOnUniqueInventoryNumber({
        holdingId: values.holding,
        data: values?.objects.map((it) => it?.inventoryNumber).filter((it) => it),
      }));

      const filteredPayload = payload.map(({ cases = [], ...rest }) =>
        ({
          cases: cases.filter(({ caseId } = {}) =>
            caseId.toString() !== params.eventId.toString()), ...rest
        }
        ))
        .filter(({ cases = [] }) => !isEmpty(cases));

      if (!isEmpty(filteredPayload)) {
        setIsSubmitting(false);
        setUniqueInventoryNumberList(filteredPayload);
      } else {
        handleSubmitForm(values);
      }
    },
  });

  const makeRequestToUpdateEvent = (values) => {
    dispatch(editEvent({
      id: values.id,
      responsibleId: values.responsibleId,
      initialResponsibleId: values.initialResponsibleId,
      data: mapEventToServerData(values, true),
    })).then(async (editEventRespData) => {
      if (editEventRespData.error) {
        setIsSubmitting(false);
        dispatch(showNotification({
          type: 'error',
          message: editEventRespData?.payload?.error?.message || 'Сталася помилка',
        }));
      } else {
        setIsSubmitting(false);
        const { holdingId, id } = editEventRespData.payload;
        history.push(`${routeBits.event}/${id}/${holdingId}`);
        dispatch(showNotification({
          type: 'success',
          message: 'Подію успішно змінено',
        }));
      }
    });
  };

  const handleSubmitForm = (values) => {
    setUniqueInventoryNumberList(null);
    setIsSubmitting(true);

    const responsibleId = get(values, 'responsibleId');
    const isResponsibleChanged = responsibleId && data.initialResponsibleId !== responsibleId;
    if (isResponsibleChanged) {
      dispatch(setResponsibleForCases({
        selectedCases: [{ id: data.id }],
        responsibleId: responsibleId,
        holdingId: data.holding,
      })).then((setResponsibleRespData) => {
        if (setResponsibleRespData.error) {
          dispatch(showNotification({
            type: 'error',
            message: setResponsibleRespData?.payload?.error?.message || 'Сталася помилка',
          }));
        } else {
          makeRequestToUpdateEvent(values);
        }
      });
    } else {
      makeRequestToUpdateEvent(values);
    }
  };

  const handleUpdateFormWithCallBack = (field, value, shouldValidate) => {
    formik.setFieldValue(field, value, shouldValidate);
    const fieldError = get(formik.errors, field);
    if (fieldError) formik.setFieldError(field);
  };

  const showNotValidFormNotification = () => {
    dispatch(showNotification({
      type: 'error',
      message: FORM_HAS_ERRORS,
    }));
  };

  useEffect(() => {
    dispatch(loadEvent({ ...params, user, currentUserRoles }));
    return () => {
      dispatch(resetError());
      dispatch(resetEventStore());
    }
  }, []);


  useEffect(() => {
    if (status !== SUCCEEDED) return;
    formik.setValues(data, false);
  }, [status]);

  useEffect(() => {
    if (!error) return;
    const errorStatus = get(error, 'status', '');
    if (errorStatus === 404) {
      history.replace(routes.notFound);
    }
  }, [error]);

  return (
    <>
      <ContentSpinner isLoading={status === LOADING || isSubmitting}>
        {status === SUCCEEDED && (
          <CanEditEvent renderIfHidden={<NoAccess/>}>
            <Box className={appClasses.root}>
              <div>

              </div>
              <PageHeader
                title={EDIT_EVENT_TITLE}
                extraLine={(
                  <div id="nav-menu" />
                )}
              >
                <div className={appClasses.pageHeader}>
                  <Typography variant="h1">{EDIT_EVENT_TITLE}</Typography>
                  <Box>
                    <Button
                      startIcon={<CancelOutlined/>}
                      size="large"
                      type="cart-secondary"
                      component={Link}
                      to={`${routeBits.event}/${data?.id}/${data?.holding}`}
                      disabled={isSubmitting}
                      data-test="cancel-create-event-btn"
                    >
                      {CANCEL}
                    </Button>
                    <Button
                      startIcon={<CheckCircleOutline/>}
                      size="large"
                      type="cart-secondary"
                      className={appClasses.actionControl}
                      disabled={isSubmitting || isEqual(initialData, formik.values)}
                      onClick={() => {
                        formik.validateForm()
                          .then(errors => {
                            const isValid = isEmpty(errors);
                            if (isValid) {
                              // formik.setFieldValue('silentSave', false, false);
                              formik.submitForm()
                            } else {
                              console.log(errors);
                              showNotValidFormNotification();
                            }
                          })
                          .catch(() => {
                            showNotValidFormNotification();
                          });
                      }}
                      data-test="create-event-btn"
                    >
                      {SAVE}
                    </Button>
                  </Box>
                </div>
              </PageHeader>
              <EditEventForm
                initBlockValues={initBlockValues}
                setFieldValue={handleUpdateFormWithCallBack}
                setDeletedObjectWithFiles={setDeletedObjectWithFiles}
                validateForm={formik.validateForm}
                values={formik.values}
                errors={formik.errors}
                params={params}
              />
            </Box>
          </CanEditEvent>
        )}
      </ContentSpinner>
      <DialogDefault
        title="Збереження події"
        open={isVisibleModalWithDeletedFolder}
        handleClose={() => setVisibleModalWithDeletedFolder(false)}
        activeActionText={SAVE}
        activeActionBtnType="default-error"
        handleActiveAction={() => {
          setDeletedObjectWithFiles(false);
          setVisibleModalWithDeletedFolder(false);
          formik.submitForm();
        }}
      >
        <Typography
          data-test="dialog-sub-title"
          variant="h2"
          style={{
            lineHeight: '28px',
          }}
        >
          Увага, ви намагаєтесь зберегти подію де був видалений об'єкт або об'єкти
        </Typography>
        <Typography
          data-test="dialog-text"
          variant="subtitle2"
        >
          Це приведе до втрати усіх даних об'єкту або об'єктів та файлів у SharePoint
        </Typography>
      </DialogDefault>
      <UniqueInventoryNumberDialog
        isOpen={Boolean(uniqueInventoryNumberList)}
        uniqueInventoryNumberList={uniqueInventoryNumberList}
        handleActiveAction={() => handleSubmitForm(formik.values)}
        handleClose={() => setUniqueInventoryNumberList(null)}
      />
    </>
  );
};

export default EditEvent;
