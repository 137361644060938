import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { white } from "../ui-lib/ThemeProvider/theme/colors";

const DottedLoader = ({
                        children,
                        opacity,
                        isLoading,
                        customClass,
                      }) => {
  const useStyles = makeStyles(theme => ({
    componentWrapper: {
      position: 'relative',
      width: '100%'
    },
    overlay: {
      position: 'absolute',
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      backgroundColor: theme.colors.white,
      opacity,
      zIndex: 1,
    },
    '@keyframes dotsAnimation': {
      '0%': {
        opacity: '1',
        transform: 'scale(1)',
      },
      '20%': {
        opacity: '0.8',
        transform: 'scale(0.6)',
      },
      '50%': {
        opacity: '1',
        transform: 'scale(1)',
      },
    },
    dots: {
      position: 'absolute',
      zIndex: 1,
      top: 'calc(50% - 4px)',
      left: 'calc(50% - 4px)',
      '& div': {
        position: 'absolute',
        width: 8,
        height: 8,
        backgroundColor: theme.colors.primaryLight,
        borderRadius: '50%',
        transform: 'translate(0, 0)',
        animationName: '$dotsAnimation',
        animationDuration: '3s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        '&:nth-child(1)': {
          animationDelay: '-2s',
          left: 10,
        },
        '&:nth-child(2)': {
          animationDelay: '-1s',
          left: 20,
        },
        '&:nth-child(3)': {
          animationDelay: '0s',
        },
      },
    },
  }
  ));

  const classes = useStyles();
  return (
    <div className={clsx(classes.componentWrapper, customClass)}>
      {children}
      {isLoading && (
        <>
          <div className={classes.overlay}/>
          <div className={classes.dots}>
            <div/>
            <div/>
            <div/>
          </div>
        </>
      )}
    </div>
  );
};
DottedLoader.defaultProps = {
  opacity: 0.5,
  isLoading: true,
  customClass: '',
};

DottedLoader.propTypes = {
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
  isLoading: PropTypes.bool,
  customClass: PropTypes.string,
};
export default DottedLoader;
