import { makeStyles } from '@material-ui/core/styles';
import { primaryLight } from '../../components/ui-lib/ThemeProvider/theme/colors';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
  },
  label: {
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.colors.black,
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
  },
  subLabel: {
    paddingLeft: 24,
    color: theme.colors.darkGrey,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    '& a:link': {
      textDecoration: 'none',
    }
  },
  toHomePageLink: {
    paddingRight: 24,
    color: theme.colors.primaryLight,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
  },
}
));

export default useStyles;
