import React from 'react';
import { Provider } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Switch,
  BrowserRouter as Router,
  Route, Redirect,
} from 'react-router-dom';
import clsx from 'clsx';

import store from 'store';
import routes from 'routes';
import routesList from 'routes/routeConfig';
import { ThemeProvider } from 'components/ui-lib';
import { PrivateRoute } from 'containers/RoleModel';
import MSAuth from 'containers/MSAuth';
import Header from 'components/Header';
import NoAccess from 'pages/NoAccess';
import NotFound from 'pages/NotFound';
import { headerHeight } from 'constants/main';

import './App.css';
import DictionariesLoader from 'containers/DictionariesLoader';
import NotificationsManager from 'containers/NotificationsManager';

const mainTopMargin = 32;
const mainBottomMargin = 24;
const bordersWidth = 2;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: `${headerHeight}px 0 0`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f9f9f9',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 3, 3, 3),
    background: '#fff',
    borderRadius: 3,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: '1px solid #e0e0e0',
    minHeight: `calc(100vh - ${headerHeight + mainBottomMargin + bordersWidth + mainTopMargin}px)`,
  },
}
));

function App() {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <MSAuth>
        <ThemeProvider>
          <NotificationsManager/>
          <Router>
            <section className={clsx(classes.mainContainer, 'main-container')}>
              <Header/>
              <main className={classes.mainContent}>
                <DictionariesLoader>
                  <Switch>
                    <Route path={routes.noAccess} component={NoAccess}/>
                    {routesList.map((route) => {
                      const RouteComponent = route.permission ? PrivateRoute : Route;

                      return (
                        <RouteComponent
                          key={route.path}
                          {...route}
                        />
                      );
                    })}
                    <Route path={routes.notFound} component={NotFound}/>
                    <Redirect from="*" to={routes.notFound}/>
                  </Switch>
                </DictionariesLoader>
              </main>
              <div id="pageFooter" />
            </section>
          </Router>
        </ThemeProvider>
      </MSAuth>
    </Provider>
  );
}

export default App;
