import req from 'API/axiosSetting';

const API = {
	user: {
		getUserData: () => req({
			url: 'cases/users/me',
		}),
		getUsers: ({ role, holdingId }) => req({
			url: `cases/users/roles/${role}`,
			method: 'GET',
			...holdingId && { params: { holdingId } }
		}),
		getInitiators: (params) => req({
			url: 'cases/users/initiators',
			method: 'GET',
			params,
		}),
	},
	cases: {
		getCasesList: (params) => req({
			url: 'cases/cases',
			method: 'GET',
			params: params,
		}),
		export: ({ withDocuments, ...rest }) => req({
			url: withDocuments ? 'cases/cases/export-with-archive' : 'cases/cases/export',
			method: 'GET',
			params: rest,
		}),
		assignResponsible: ({ holdingId, ...rest }) => req({
			url: `cases/holdings/${holdingId}/cases/assign-responsible`,
			method: 'PUT',
			data: rest,
		}),
	},
	event: {
		getEvent: (eventId, holdingId) => req({
			url: `cases/holdings/${holdingId}/cases/${eventId}`,
		}),
		checkOnUniqueInventoryNumber: (holdingId, params) => req({
			url: `cases/holdings/${holdingId}/cases/by-inventory-numbers`,
			method: 'GET',
			params: { values: params },
		}),
		createEvent: (holdingId, data) => req({
			url: `cases/holdings/${holdingId}/cases`,
			method: 'POST',
			data,
		}),
		editEvent: (caseId, data) => req({
			url: `cases/cases/${caseId}`,
			method: 'PUT',
			data,
		}),
		deleteEvent: (eventId, holdingId) => req({
			url: `cases/holdings/${holdingId}/cases/${eventId}`,
			method: 'DELETE',
		}),
		returnEvent: (eventId, holdingId) => req({
			url: `cases/holdings/${holdingId}/cases/${eventId}/return-event-from-final-status`,
			method: 'PUT',
		}),
		exportDocuments: (eventId, holdingId) => req({
			url: `cases/holdings/${holdingId}/cases/${eventId}/export-with-archive`,
			method: 'GET',
		}),
		getArchiveStatus: (archiveCorrelationId) => req({
			url: `cases/cases/archive-uploading-status/${archiveCorrelationId}`,
		}),
		getExcelStatus: (correlationId, type) => {
			let url = '';
			switch (type) {
				case 'case': {
					url = `cases/cases/excel-with-archive/${correlationId}`;
					break;
				}
				case 'casesWithDocuments': {
					url = `cases/cases/excel-with-archive/${correlationId}`;
					break;
				}
				case 'cases': {
					url = `cases/cases/excel/${correlationId}`;
					break;
				}
				default: {
					url = '';
				}
			}
			return req({
				url,
				responseType: 'blob',
				headers: {
					'Content-Disposition': 'attachment',
					Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				},
				method: 'GET',
			})
		},
		createSharePointFolder: (holdingId, data) => req({
			url: `cases/structure/${holdingId}`,
			method: 'PUT',
			data,
		}),
	},
	dictionary: {
		getRolesList: () => req({
			url: 'cases/directories/roles',
		}),
		getHoldingList: () => req({
			url: 'cases/directories/holdings',
		}),
		getEnterpriseList: () => req({
			url: 'cases/directories/holdings-enterprises',
		}),
		getUnitsList: () => req({
			url: 'cases/directories/holdings-enterprises-units',
		}),
		getRegionsList: () => req({
			url: 'cases/directories/regions'
		}),
		getRegionsDistrictsList: () => req({
			url: 'cases/directories/regions-districts'
		}),
		getDamageCategoriesList: () => req({
			url: 'cases/directories/damage-categories'
		}),
		getCompensationCategoriesList: () => req({
			url: 'cases/directories/damage-compensation-categories'
		}),
		getPropertyCategoriesList: () => req({
			url: 'cases/directories/property-categories'
		}),
		getPropertyTypesList: () => req({
			url: 'cases/directories/property-categories-property-types'
		}),
		getEnvironmentalRisksList: () => req({
			url: 'cases/directories/environmental-risks'
		}),
		getCaseStatusesList: () => req({
			url: 'cases/directories/case-statuses',
		}),
	}
};

export default API;
