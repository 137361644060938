import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ErrorOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import { PART_TOTAL_LOSS_DATA } from 'constants/texts';
import useStyles from './styles';

const AmountCell = ({ value, allObjectsLossFilled }) => {
  const classes = useStyles();

  return (
    <div className={classes.lossesAmountWrapper}>
      <div style={{ display: 'flex' }}>
        <div className={classes.currencyUnit}>₴</div>
        <NumberFormat
          value={value}
          thousandSeparator=" "
          displayType={'text'}
          renderText={(value) => <div className={classes.lossesAmount}>{value || '—'}</div>}
          data-test="amount"
        />
      </div>
      {allObjectsLossFilled !== undefined && !allObjectsLossFilled && (
        <Tooltip title={PART_TOTAL_LOSS_DATA}>
          <ErrorOutline color="error" />
        </Tooltip>
      )}
    </div>
  );
};

AmountCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allObjectsLossFilled: PropTypes.bool,
};

AmountCell.defaultProps = {
  value: '',
  allObjectsLossFilled: undefined,
};

export default AmountCell;
