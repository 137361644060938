import React from 'react';
import { Typography } from 'components/ui-lib';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as NoAccessSvg } from './assets/noAccess.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
}
));

const NoAccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <NoAccessSvg/>
      <Typography variant="h2" data-test="no-access-title">Поточна сторінка не співпадає з правами
        доступу</Typography>
      <Typography
        variant="subtitle2"
        color="secondary"
        data-test="no-access-subtitle"
      >
        Зверніться у службу підтримки для управління доступом і відображенням даних
      </Typography>
    </div>
  );
};

export default NoAccess;
