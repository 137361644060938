import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button, DialogDefault, Typography } from 'components/ui-lib';
import { routeBits } from 'routes';
import useAppStyles from 'containers/App/AppStyles';

const UniqueInventoryNumberDialog = ({ isOpen, handleClose, handleActiveAction, uniqueInventoryNumberList }) => {
  const appClasses = useAppStyles();

  return (
    <DialogDefault
      title="Дублювання обʼєкту"
      open={isOpen}
      handleClose={handleClose}
      cancelActionText="Відміна"
      activeActionText="Зберегти подію"
      handleActiveAction={handleActiveAction}
    >
      <Typography data-test="dialog-sub-title" variant="h2">
        Один або декілька обʼєків вже додано до іншої події:
      </Typography>
      {uniqueInventoryNumberList?.map((it) => (
        <Fragment key={it.inventoryNumber}>
          <Typography
            data-test="dialog-text"
            variant="subtitle2"
            className={appClasses.caseBtnSubTitle}
          >
            Інвентарний номер <b>{it.inventoryNumber}</b> вже присутній у
          </Typography>
          {it?.cases.map((item, index) => (
            <Button
              key={index}
              type="cart-primary"
              className={appClasses.caseBtn}
              component={Link}
              to={`${routeBits.event}/${item?.caseId}/${item?.holdingId}`}
              target="_blank"
              size="large"
              data-test="edit-button"
            >
              Подія {item?.caseId}
            </Button>
          ))}
        </Fragment>
      ))}
    </DialogDefault>
  );
};

export default UniqueInventoryNumberDialog;
