import React, { useEffect, useState } from 'react';
import { AssignmentInd } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { Button } from 'components/ui-lib';
import {
  EVENT_REGISTRATION_RESPONSIBLE,
} from 'constants/roles';
import { BECOME_RESPONSIBLE } from 'constants/texts';

const AssignToMeButton = ({ onClick }) => {
  const { roles = [] } = useSelector((state) => state.account?.user);
  const {
    data: { responsibleId, initialStatus } = {},
  } = useSelector((state) => state.event?.event);
  const [canView, setCanView] = useState(false);

  useEffect(() => {
    let canViewBtn = false;
    if (!responsibleId && roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus !== 'DocumentsFinalized') {
      canViewBtn = true;
    }
    if (canViewBtn) setCanView(canViewBtn);
  }, [initialStatus]);

  if (!canView) return null;

  return (
    <Button
      startIcon={<AssignmentInd/>}
      type="cart-secondary"
      disabled={false}
      onClick={onClick}
      size="large"
      data-test="assign-to-me-button"
    >
      {BECOME_RESPONSIBLE}
    </Button>
  )
};

export default AssignToMeButton;
