import React from 'react';
import { Box, FormControl, FormHelperText, TextField, Typography } from '@material-ui/core';
import { DeleteOutline, PersonAddOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import CustomSelect from 'components/PartCustomSelect';
import FullWidthButton from 'components/FullWidthButton';
import { IconButton } from 'components/ui-lib';
import { PERFORMERS_BLOCK, checkRoles } from 'containers/RoleModel';
import { rolesTranslations } from 'utils/mappers';
import { otherRoleName } from 'constants/main';

import useAppStyles from 'containers/App/AppStyles';
import useStyles from '../EditEventFormStyles';

const PerformersBlock = ({
  values,
  errors,
  setFieldValue,
  currentUserRoles,
  handleAddPerformer,
  handleRemovePerformer,
}) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const {
    rolesDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);

  const rolesList = rolesTranslations(rolesDictionary);
  const filteredRolesList = rolesList.filter((role) => {
    if (role.enumValue === otherRoleName) return role;

    return currentUserRoles.find((item) => item === role.enumValue);
  });

  return (
    <Box id="performers">
      <Typography variant="h2">Виконавці</Typography>
      {values.performers.map((item, index) => {
        const RemoveBtn = (
          <IconButton
            type="secondary"
            onClick={() => handleRemovePerformer(index)}
            data-test={`remove-performer-button-${index}`}
          >
            <DeleteOutline/>
          </IconButton>
        );
        const roleOptions = item.isCustomPerformer || item.roleDescription
          ? filteredRolesList.filter(({ enumValue }) => enumValue === otherRoleName)
          : filteredRolesList;
        const permission = rolesList.find(({ enumValue }) => enumValue === item.role)?.permission;

        return (
          <Box key={item.performerId} className={classes.shadowBlock}
               data-test={`performer-block-${index}`}>
            <Box className={clsx(appClasses.flexWrapBetween, classes.shadowBlockTitle)}>
              <Typography variant="subtitle1">{`Виконавець ${index + 1}`}</Typography>
              {(item.isCustomPerformer || item.roleDescription
              ) && RemoveBtn}
            </Box>
            <Box className={classes.formControlRow}>
              <TextField
                disabled={!item.isCustomPerformer}
                fullWidth
                variant="outlined"
                label="П.І.Б (Повністю)"
                helperText="Обов'язкове поле"
                inputProps={{ 'data-test': 'performer-name' }}
                value={item?.name}
                error={errors?.performers?.[index]?.name}
                onChange={({ target }) => {
                  setFieldValue(`performers[${index}].name`, target.value, false);
                }}
              />
            </Box>
            <Box className={appClasses.flexWrapBetween}>
              <Box className={classes.eventsAttrsLeft}>
                <Box className={classes.formControlRow}>
                  <FormControl variant="outlined" className={classes.formControlRow}>
                    <CustomSelect
                      disabled={!item.isCustomPerformer}
                      label="Роль виконавця"
                      optionValue="name"
                      options={roleOptions}
                      value={rolesList.find(({ enumValue }) => enumValue === values?.performers?.[index]?.role)}
                      error={errors?.performers?.[index]?.role}
                      onChange={({ enumValue }) => {
                        setFieldValue(`performers[${index}].role`, enumValue, false);
                      }}
                      data-test="performer-role"
                    />
                    <FormHelperText error={errors?.performers?.[index]?.role}>
                      Обов&apos;язкове поле
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              {item?.role === otherRoleName && (
                <Box className={classes.eventsAttrsRight}>
                  <Box className={classes.formControlRow}>
                    <TextField
                      disabled={item.isNotEditable || permission && !checkRoles(currentUserRoles, permission)}
                      fullWidth
                      variant="outlined"
                      label="Назва ролі"
                      helperText="Обов'язкове поле"
                      inputProps={{ maxLength: 256, 'data-test': 'performer-role-description' }}
                      value={item?.roleDescription}
                      error={errors?.performers?.[index]?.roleDescription}
                      onChange={({ target }) => {
                        setFieldValue(`performers[${index}].roleDescription`, target.value, false);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.formControlRow}>
              <TextField
                disabled={!item.isCurrentPerformer && !item.isCustomPerformer}
                fullWidth
                variant="outlined"
                label="Коментар виконавця"
                inputProps={{ maxLength: 1024, 'data-test': 'performer-comment' }}
                value={item?.comment}
                onChange={({ target }) => {
                  setFieldValue(`performers[${index}].comment`, target.value, false);
                }}
              />
            </Box>
          </Box>
        )
      })}
      <FullWidthButton
        disabled={!checkRoles(currentUserRoles, PERFORMERS_BLOCK)}
        fullWidth
        size="large"
        type="cart-dashed"
        startIcon={<PersonAddOutlined/>}
        onClick={handleAddPerformer}
        data-test="add-performer-button"
      >
        Додати виконавця
      </FullWidthButton>
    </Box>
  );
};

export default PerformersBlock;