import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { get, head } from 'lodash';
import Tabs from '../ui-lib/Tabs';
import MaterialIconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useStyles from './styles';

const AnchorNavigation = ({ portalElementId, menu }) => {
  const classes = useStyles();
  const menuRef = useRef(menu);

  const firstMenuItem = head(menu);
  const [activeItem, setActiveItem] = useState(firstMenuItem);
  const activeItemRef = useRef(activeItem);

  useEffect(() => {
    activeItemRef.current = activeItem;
  }, [activeItem]);

  useEffect(() => {
    menuRef.current = menu;
  }, [menu.length]);

  function handleScroll() {
    const curPos = window.scrollY;
    let curSection = null;
    const currentAnchor = get(activeItemRef, 'current');
    const menu = get(menuRef, 'current');

    for (let i = 0; i < menu.length; i += 1) {
      if (i === 0 && curPos <= menu[i].position) {
        curSection = menu[i].id;
        break;
      }
      if (curPos >= menu[i].position && menu[i + 1] && curPos < menu[i + 1].position) {
        curSection = menu[i].id;
        break;
      }
      if (i === menu.length - 1 && curPos >= menu[i].position) {
        curSection = menu[i].id;
      }
    }
    if (curSection && curSection !== currentAnchor) setActiveItem(curSection);
  };

  const getAnchorPoints = () => {
    const menu = get(menuRef, 'current');
    const curScroll = window.scrollY;
    let firstElementOffsetTop = 0;
    for (let i = 0; i < menu.length; i += 1) {
      const element = document.getElementById(menu[i].id);
      if (element) {
        const elementTopBounding = element.getBoundingClientRect().top;
        if (i === 0) {
          firstElementOffsetTop = elementTopBounding;
        }
        menu[i].position = elementTopBounding - firstElementOffsetTop;
      }
    }
    handleScroll();
  };

  useEffect(() => {
    const observer = new MutationObserver(getAnchorPoints);
    observer.observe(document.getElementById('root'), {
      childList: true,
      subtree: true,
    });
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const isDisabledUpButton = () => {
    const activeAnchorIndex = menu.findIndex(({ id }) => id === activeItem);
    return activeAnchorIndex === 0;
  };

  const isDisabledDownButton = () => {
    const activeAnchorIndex = menu.findIndex(({ id }) => id === activeItem);
    return activeAnchorIndex === menu.length - 1;
  };

  const scrollToElementById = (elementId) => {
    const menu = get(menuRef, 'current');
    const activeAnchor = menu.find(({ id }) => id === elementId);
    const extraYOffset = 1;
    const newActiveAnchorPosition = activeAnchor.position;
    window.scrollTo({ behavior: 'smooth', top: newActiveAnchorPosition + extraYOffset, left: 0 });
  };

  const renderNavButtons = () => {
    return (
      <>
        <MaterialIconButton
          disabled={isDisabledUpButton()}
          className={classes.upAnchorButton}
          aria-label="up-anchor-button"
          onClick={() => {
            const menu = get(menuRef, 'current');
            const activeAnchorId = get(activeItemRef, 'current');
            const activeAnchorIndex = menu.findIndex(({ id }) => id === activeAnchorId);
            const newActiveAnchorIndex = activeAnchorIndex - 1;
            if (newActiveAnchorIndex === 0) {
              window.scrollTo({ top: 0, left: 0 });
              return;
            }
            const newActiveAnchorId = menu[newActiveAnchorIndex].id;
            scrollToElementById(newActiveAnchorId);
          }}
          data-test="up-anchor-button"
        >
          <KeyboardArrowUpIcon/>
        </MaterialIconButton>
        <MaterialIconButton
          disabled={isDisabledDownButton()}
          className={classes.downAnchorButton}
          aria-label="down-anchor-button"
          onClick={() => {
            const menu = get(menuRef, 'current');
            const activeAnchorId = get(activeItemRef, 'current');
            const activeAnchorIndex = menu.findIndex(({ id }) => id === activeAnchorId);
            const newActiveAnchorId = menu[activeAnchorIndex + 1].id;
            scrollToElementById(newActiveAnchorId);
          }}
          data-test="down-anchor-button"
        >
          <KeyboardArrowDownIcon/>
        </MaterialIconButton>
      </>
    );
  };

  return (
  <>
    {document.getElementById(portalElementId) && ReactDOM.createPortal(
      <Tabs
        value={activeItem}
        options={menu}
        onChange={(menuId) => {
          if (menuId === activeItem) return;
          scrollToElementById(menuId);
        }}
      />,
      document.getElementById(portalElementId)
    )}
    {renderNavButtons()}
  </>
  );
};

export default AnchorNavigation;
