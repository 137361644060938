import { createSlice } from '@reduxjs/toolkit';

const pollingSlice = createSlice({
	name: 'polling',
	initialState: {
		processes: [],
	},
	reducers: {
		startPolling(state, action) {
			state.processes.push(action.payload.process);
		},
		stopPolling(state, action) {
			state.processes = state.processes.filter(process => process.processId !== action.payload.processId);
		},
	},
});

export const { startPolling, stopPolling } = pollingSlice.actions;
export default pollingSlice.reducer;
