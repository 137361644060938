import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 2,
    boxShadow: '0 -1px 4px 0 #ccc',
    padding: 24,
    justifyContent: 'flex-end',
  },
  placeholderElement: {
    height: 96,
  },
  assignPerformerButton: {
    marginLeft: 24,
  },
}));

export default useStyles;
