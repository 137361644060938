import React from 'react';
import { Typography } from 'components/ui-lib';

import { Box } from '@material-ui/core';


const TextBlock = ({ title, children, 'data-test': dataTest, ...props }) => {
  return (
    <Box {...props} mb={3} style={{ minHeight: 48 }}>
      <Typography
        variant="caption"
        color="secondary"
        {...dataTest && { 'data-test': `${dataTest}-title` }}
      >{title}</Typography>
      <Typography
        variant="subtitle2"
        {...dataTest && { 'data-test': `${dataTest}-value` }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default TextBlock;