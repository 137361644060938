import React, { useEffect, useState } from 'react';
import { DeleteOutline } from '@material-ui/icons';
import { DELETE } from 'constants/texts';
import { Button } from 'components/ui-lib';
import { useSelector } from 'react-redux';
import {
  DISPATCHER,
  EVENT_REGISTRATION_RESPONSIBLE,
  WORKGROUP_HEAD
} from 'constants/roles';

const DeleteCaseButton = ({ onClick }) => {
  const {
    roles = [],
    name: currentUserName,
    id: currentUserId
  } = useSelector((state) => state.account?.user);
  const {
    data: { authorName = '', responsibleName = '', initialResponsibleId, initialStatus } = {},
  } = useSelector((state) => state.event?.event);

  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    let canDeleteEvent = false;
    if ((roles.includes(DISPATCHER) && initialStatus === 'Registered' && currentUserName === authorName
      )
      || (roles.includes(DISPATCHER) && responsibleName === currentUserName && initialStatus !== 'DocumentsFinalized'
      )) {
      canDeleteEvent = true;
    }
    if ((roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus === 'Registered' && currentUserName === authorName
      )
      || (roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus !== 'DocumentsFinalized' && initialResponsibleId === currentUserId
      )) {
      canDeleteEvent = true;
    }
    if (roles.includes(WORKGROUP_HEAD) && initialStatus !== 'DocumentsFinalized') {
      canDeleteEvent = true;
    }
    if (canDeleteEvent) setCanDelete(canDeleteEvent);
  }, [initialStatus]);

  if (!canDelete) return null;
  return (
    <Button
      startIcon={<DeleteOutline/>}
      type="cart-error"
      onClick={onClick}
      size="large"
      data-test="delete-button"
    >
      {DELETE}
    </Button>
  );
};

export default DeleteCaseButton;
