import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, formatTime } from 'utils/dateTime';

import useStyles from './styles';


const DateTimeCell = ({ date }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.date} data-test="date">{formatDate(date)}</div>
      <div className={classes.time} data-test="time">{formatTime(date)}</div>
    </>
  );
};

DateTimeCell.propTypes = {
  value: PropTypes.string,
};

DateTimeCell.defaultProps = {
  value: '',
};

export default DateTimeCell;
