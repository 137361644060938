import * as Yup from 'yup';
import 'yup-phone';
import { get, toSafeInteger } from 'lodash';
import { isBefore, isValid } from 'date-fns';
import { otherRoleName } from 'constants/main';
import {
  NOT_VALID_DATE,
  REQUIRED_FIELD,
  NOT_VALID_STATUS,
  NOT_VALID_PHONE,
  NOT_VALID_DATE_TIME,
  MUST_NOT_BE_ZERO
} from 'constants/texts';

const minDate = new Date(2022, 1, 24, 0, 0, 0);

const isValidDateTime = ({ eventDate, eventTime }) => {
  if (!isValid(eventDate) || !eventDate || !eventTime) return true;
  const date = eventDate;
  const [hours, minutes] = eventTime.split(':');
  const parsedMinutes = toSafeInteger(minutes);
  const parsedHours = toSafeInteger(hours);
  date.setHours(parsedHours, parsedMinutes, 0);
  return isBefore(minDate, date) && isBefore(date, new Date());
};

Yup.addMethod(Yup.array, 'unique', function(field, message) {
  return this.test('unique', message, function(array) {
    const uniqueData = Array.from(
      new Set(array.map((row) => row?.[field]?.toLowerCase())),
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row?.[field]?.toLowerCase() !== uniqueData[i],
    );
    if (!Boolean(array[index]?.[field])) {
      return true;
    }

    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

export const createEventFormValidation = Yup.object({
  eventDate: Yup.date()
    .nullable()
    .typeError(NOT_VALID_DATE)
    .required(REQUIRED_FIELD)
    .test("", NOT_VALID_DATE_TIME, (value, props) => {
      const eventTime = get(props, 'parent.eventTime');
      return isValidDateTime({ eventDate: value, eventTime });
    }),
  eventTime: Yup.string()
    .test("", REQUIRED_FIELD, (value) => {
      return Boolean(value);
    })
    .nullable()
    .test("", NOT_VALID_DATE_TIME, (value, props) => {
      const eventDate = get(props, 'parent.eventDate');
      return isValidDateTime({ eventDate, eventTime: value });
    }),
  holding: Yup.string()
    .required(),
  enterprise: Yup.string()
    .required(),
  subdivision: Yup.string()
    .required(),
  eventArea: Yup.string()
    .required(),
  regionArea: Yup.string()
    .required(),
  addressArea: Yup.string()
    .required(),
  eventDescription: Yup.string()
    .required(),
  witnesses: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required(),
      phone: Yup.string()
        .test("", REQUIRED_FIELD, (value) => {
          return Boolean(value)
        })
        .nullable()
        .test("", NOT_VALID_PHONE, (value, props) => {
          const phone = get(props, 'parent.phone');
          const phoneSchema = Yup.string()
            .phone()
            .required();
          const isValidPhone = phoneSchema.isValidSync(`+${phone}`);
          return isValidPhone;
        }),
      isEmployee: Yup.bool(),
      additionalData: Yup.mixed().when('isEmployee', {
        is: false,
        then: (schema) => schema.required(),
      }),
    })),
  objects: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required(),
      propertyType: Yup.string()
        .required(),
      damageType: Yup.string()
        .required(),
      inventoryNumber: Yup.mixed(),
      damagesDescription: Yup.string()
        .required(),
      isRepairComplete: Yup.bool(),
      repairCompleteDate: Yup.date()
        .nullable()
        .typeError(NOT_VALID_DATE)
        .test("", REQUIRED_FIELD, (value, props) => {
          const isRepairComplete = get(props, 'parent.isRepairComplete');
          return !isRepairComplete || (isRepairComplete && Boolean(value)
          );
        })
        .test("", NOT_VALID_DATE, (value, props) => {
          const [parentForm, allFieldsForm] = get(props, 'from', []);
          const isRepairComplete = get(parentForm, 'value.isRepairComplete');
          const eventDate = get(allFieldsForm, 'value.eventDate');
          return !isRepairComplete || (isRepairComplete && isValid(value) && isBefore(minDate, value) && isBefore(new Date(eventDate), value.setHours(23, 59, 59)) && isBefore(value.setHours(0, 0, 0), new Date())
          );
        }),
      balansCost: Yup.number()
        .nullable()
        .test(
          "",
          MUST_NOT_BE_ZERO,
          (value) => {
            return value !== 0;
          }
        ),
    })).unique('inventoryNumber', 'Номер вже введено раніше на цій сторінці'),
});

export const editEventFormValidation = Yup.object({
  status: Yup.mixed()
    .test("", NOT_VALID_STATUS, (value, props) => {
      return !(value === 'DocumentsFinalized' && props?.parent?.totalLoss === 0
      );
    }),
  eventDate: Yup.date()
    .nullable()
    .typeError(NOT_VALID_DATE)
    .required(REQUIRED_FIELD)
    .test("", NOT_VALID_DATE_TIME, (value, props) => {
      const eventTime = get(props, 'parent.eventTime');
      return isValidDateTime({ eventDate: value, eventTime });
    }),
  eventTime: Yup.string()
    .test("", REQUIRED_FIELD, (value) => {
      return Boolean(value);
    })
    .nullable()
    .test("", NOT_VALID_DATE_TIME, (value, props) => {
      const eventDate = get(props, 'parent.eventDate');
      return isValidDateTime({ eventDate, eventTime: value });
    }),
  holding: Yup.string()
    .required(),
  enterprise: Yup.string()
    .required(),
  subdivision: Yup.string()
    .required(),
  // responsible: Yup.string()
  //   .required(),
  // status: Yup.string()
  //   .required(),
  eventArea: Yup.string()
    .required(),
  regionArea: Yup.string()
    .required(),
  addressArea: Yup.string()
    .required(),
  eventDescription: Yup.string()
    .required(),
  // eventComment: Yup.string(),
  environmentalRisks: Yup.array().of(
    Yup.object().shape({
      environmentalRiskId: Yup.number().required(),
    })
  ),
  witnesses: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required(),
      phone: Yup.string()
        .test("", REQUIRED_FIELD, (value) => {
          return Boolean(value)
        })
        .nullable()
        .test("", NOT_VALID_PHONE, (value, props) => {
          const phone = get(props, 'parent.phone');
          const phoneSchema = Yup.string()
            .phone()
            .required();
          const isValidPhone = phoneSchema.isValidSync(`+${phone}`);
          return isValidPhone;
        }),
      isEmployee: Yup.bool(),
      additionalData: Yup.mixed().when('isEmployee', {
        is: false,
        then: (schema) => schema.required(),
      }),
    })),
  performers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required(),
      role: Yup.string()
        .required('Email is required'),
      roleDescription: Yup.mixed().when('role', {
        is: otherRoleName,
        then: (schema) => schema.required(),
      }),
      comment: Yup.mixed(),
    })
  ),
  objects: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required(),
      propertyType: Yup.string()
        .required(),
      damageType: Yup.string()
        .required(),
      inventoryNumber: Yup.mixed(),
      damagesDescription: Yup.string()
        .required(),
      isRepairComplete: Yup.bool(),
      repairCompleteDate: Yup.date()
        .nullable()
        .typeError(NOT_VALID_DATE)
        .test("", REQUIRED_FIELD, (value, props) => {
          const isRepairComplete = get(props, 'parent.isRepairComplete');
          return !isRepairComplete || (isRepairComplete && Boolean(value)
          );
        })
        .test("", NOT_VALID_DATE, (value, props) => {
          const [parentForm, allFieldsForm] = get(props, 'from', []);
          const isRepairComplete = get(parentForm, 'value.isRepairComplete');
          const eventDate = get(allFieldsForm, 'value.eventDate');
          return !isRepairComplete || (isRepairComplete && isValid(value) && isBefore(minDate, value) && isBefore(new Date(eventDate), value.setHours(23, 59, 59)) && isBefore(value.setHours(0, 0, 0), new Date())
          );
        }),
      recoveryCost: Yup.number()
        .nullable()
        .test(
          "",
          MUST_NOT_BE_ZERO,
          (value) => {
            return value !== 0;
          }
        ),
      balansCost: Yup.number()
        .nullable()
        .test(
          "",
          MUST_NOT_BE_ZERO,
          (value) => {
            return value !== 0;
          }
        ),
      wreckRepairCost: Yup.number()
        .nullable()
        .test(
          "",
          MUST_NOT_BE_ZERO,
          (value) => {
            return value !== 0;
          }
        ),
      economicLoss: Yup.number()
        .nullable()
        .test(
          "",
          MUST_NOT_BE_ZERO,
          (value) => {
            return value !== 0;
          }
        ),
    })).unique('inventoryNumber', 'Номер вже введено раніше на цій сторінці'),
});