import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEqual, isEmpty, get, find } from 'lodash';
import parseFloat from 'utils/parseFloat';
import { isValid, isBefore, add } from 'date-fns';
import {
  FormControlLabel,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Typography,
  FormControl,
  TextField,
  Slide,
} from '@material-ui/core';
import { CheckCircleOutlined, Close, HighlightOffOutlined } from '@material-ui/icons';
import {
  FILTRATION,
  APPLY,
  CANCEL,
  CLEAR_FILTERS,
  ID_NUMBER,
  HOLDING,
  COMPANY,
  UNIT,
  RESPONSIBLE,
  EVENT_REGION,
  VENUE_DISTRICT,
  TOTAL_AMOUNT_OF_LOSSES,
  OBJECTS,
  EVENT_ATTRIBUTES,
  INVENTORY_NUMBER,
  SAP_TECHNICAL_PLACE,
  BOOK_VALUE,
  ECONOMIC_LOSSES,
  ECOLOGIST,
  PROPERTY_TYPE,
  DAMAGE_CATEGORY,
  ECOLOGICAL_DAMAGE,
  LAWYER_BUSINESS,
  LAWYER_K,
  DATA_ENTRY_PERIOD,
  EVENT_PERIOD,
  FROM,
  TO,
  COST_OF_EMERGENCY_REPAIRS,
  RECOVERY_ANALOG,
  REPAIR_COMPLETION_PERIOD,
  LOSSES_AMOUNT_END_ADORNMENT,
  SINCE,
  BY,
  EVENT_STATUS,
  OLD,
  FORM_HAS_ERRORS,
  NOT_VALID_DATE,
  TOTAL_ECOLOGICAL_LOSSES,
  TOTAL_LIQUIDATION_COST, INITIATOR,
  DISPATCHER,
  NOT_PROVIDED,
  RETURNED_FOR_REVISION,
} from 'constants/texts';
import DottedLoader from 'components/DottedLoader';
import CustomSelect from 'components/PartCustomSelect';
import DataPicker from 'components/DatePicker/SimpleDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilteredEnterprises,
  getFilteredUnits,
  getFilteredRegionDistricts
} from 'store/dictionaries';
import { getUsers, getInitiators } from 'store/casesList';
import {
  BUSINESS_LAWYER,
  EVENT_REGISTRATION_RESPONSIBLE,
  KC_LAWYER,
  ECOLOGIST as ECOLOGIST_ROLE,
  READER,
  DISPATCHER as DISPATCHER_ROLE
} from 'constants/roles';
import TextBlock from 'components/TextBlock';

import useStyles from './styles';
import AmountGroupTitle from '../AmountGroupTitle';
import CustomTags from 'components/FilterElement';
import NumberFormat from "react-number-format";
import DamageCompensationCategoriesSelect
  from 'pages/CreateEvent/components/DamageCompensationCategoriesSelect';
import { showNotification } from 'store/notifications';
import Checkbox from '@material-ui/core/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FiltersModal = ({ open, handleClose, handleApplyFilters, onFilterChange, resetFilters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { temporaryFiltersState, filtersState } = useSelector(({
    casesList: {
      temporaryFiltersState: tempFilters,
      filtersState: filters
    }
  }) => ({
      temporaryFiltersState: tempFilters,
      filtersState: filters
    }
  ));

  const {
    holdingsDictionary,
    enterprisesDictionary,
    unitsDictionary,
    filteredEnterprises,
    filteredUnits,
    regionsDictionary,
    filteredRegionsDistricts,
    damageCategoriesDictionary,
    propertyTypesDictionary,
    environmentalRisksDictionary,
    caseStatusesDictionary,
    compensationCategoriesDictionary,
  } = useSelector((state) => state.dictionaries?.dictionaries);
  const { roles = [], holdings = [] } = useSelector((state) => state.account?.user);

  const creationDateFromInputRef = useRef(null);
  const creationDateToInputRef = useRef(null);
  const caseDateFromInputRef = useRef(null);
  const caseDateToInputRef = useRef(null);
  const repairedDateFromInputRef = useRef(null);
  const repairedDateToInputRef = useRef(null);

  const {
    id = '',
    caseDateFrom = null,
    caseDateTo = null,
    creationDateFrom = null,
    creationDateTo = null,
    holdingIds = [],
    enterpriseIds = [],
    unitIds = [],
    responsibleId = '',
    dispatcherId = '',
    initiatorId = '',
    status = [],
    regionId = '',
    districtId = '',
    totalLossFrom = '',
    totalLossTo = '',
    inventoryNumber = '',
    technicalPlaceSAP = '',
    objectId = '',
    oldObjectId = '',
    wreckRepairCostFrom = '',
    wreckRepairCostTo = '',
    totalEcologicalLossesFrom = '',
    totalEcologicalLossesTo = '',
    isEcologicalLossesEmpty = false,
    totalLiquidationCostFrom = '',
    totalLiquidationCostTo = '',
    isTotalLiquidationCostEmpty = false,
    economicLossFrom = '',
    economicLossTo = '',
    bookValueFrom = '',
    bookValueTo = '',
    objectTotalLossFrom = '',
    objectTotalLossTo = '',
    ecologistIds = [],
    propertyTypeIds = [],
    damageCategoryIds = [],
    ecologicalDamageIds = [],
    damageCompensationCategoryIds = [],
    recoveryCostFrom = '',
    recoveryCostTo = '',
    repairedDateFrom = null,
    repairedDateTo = null,
    businessLawyerId = '',
    kCLawyerId = '',
    photoVideoFixationIsUnavailable,
    documentsPhotoVideoReady,
    documentsFixationReady,
    documentsApplicationToLawEnforcementReady,
    documentsFullyFormedReady,
    documentsRevisedStatusCountFrom,
    documentsRevisedStatusCountTo,
  } = temporaryFiltersState;

  const isDisabledApply = isEqual(temporaryFiltersState, filtersState);
  const isDisabledReset = isEmpty(temporaryFiltersState) && isEmpty(filtersState);
  const isKC_LAWYER = roles.includes(KC_LAWYER);
  const isREADER = roles.includes(READER);
  const canSelectHolding = isKC_LAWYER || isREADER || holdings.length > 1;
  const [isLoadingResponsible, setIsLoadingResponsible] = useState(false);
  const [isLoadingBusinessLawyers, setIsLoadingBusinessLawyers] = useState(false);
  const [isLoadingKCLawyers, setIsLoadingKCLawyers] = useState(false);
  const [isLoadingEcologists, setIsLoadingEcologists] = useState(false);
  const [responsibleList, setResponsibleList] = useState([]);
  const [isLoadingDispatchers, setIsLoadingDispatchers] = useState(false);
  const [dispatchersList, setDispatchersList] = useState([]);
  const [isLoadingInitiators, setIsLoadingInitiators] = useState(false);
  const [initiatorsList, setInitiatorsList] = useState([]);
  const [businessLawyersList, setBusinessLawyersList] = useState([]);
  const [KCLawyersList, setKCLawyersList] = useState([]);
  const [ecologistsList, setEcologistsList] = useState([]);
  const [datesValidationState, setDatesValidationState] = useState({});

  useEffect(() => {
    if (!open) return;
    setDatesValidationState({
      ...Boolean(filtersState.creationDateFrom) && { creationDateFrom: { value: filtersState.creationDateFrom } },
    });
  }, [open]);

  useEffect(() => {
    if (!canSelectHolding && holdings.length > 0) {
      const [holdingId] = holdings;
      dispatch(getFilteredEnterprises([holdingId]));
    }
    setIsLoadingResponsible(true);
    dispatch(getUsers({ role: EVENT_REGISTRATION_RESPONSIBLE })).then((data) => {
      setIsLoadingResponsible(false);
      const list = get(data, 'payload', []);
      setResponsibleList(list);
    });

    setIsLoadingDispatchers(true);
    dispatch(getUsers({ role: DISPATCHER_ROLE })).then((data) => {
      setIsLoadingDispatchers(false);
      const list = get(data, 'payload', []);
      setDispatchersList(list);
    });

    setIsLoadingInitiators(true);
    const availableHoldings = (isKC_LAWYER || isREADER
    )
      ? holdingsDictionary
      : holdingsDictionary.filter(({ id: holdingId } = {}) => holdings.includes(holdingId));

    dispatch(getInitiators({ holdingIds: availableHoldings.map(({ id }) => id) })).then((data) => {
      setIsLoadingInitiators(false);
      const list = get(data, 'payload', []);
      setInitiatorsList(list);
    });

    setIsLoadingBusinessLawyers(true);
    dispatch(getUsers({ role: BUSINESS_LAWYER })).then((data) => {
      setIsLoadingBusinessLawyers(false);
      const list = get(data, 'payload', []);
      setBusinessLawyersList(list);
    });

    setIsLoadingKCLawyers(true);
    dispatch(getUsers({ role: KC_LAWYER })).then((data) => {
      setIsLoadingKCLawyers(false);
      const list = get(data, 'payload', []);
      setKCLawyersList(list);
    });

    setIsLoadingEcologists(true);
    dispatch(getUsers({ role: ECOLOGIST_ROLE })).then((data) => {
      setIsLoadingEcologists(false);
      const list = get(data, 'payload', []);
      setEcologistsList(list);
    });
  }, []);

  useEffect(() => {
    if (!regionId) return;
    dispatch(getFilteredRegionDistricts(regionId));
  }, [regionId]);

  const onChangeHolding = (id) => {
    let newHoldingIds;
    if (holdingIds.includes(id)) {
      newHoldingIds = holdingIds.filter(it => it !== id);
    } else {
      newHoldingIds = [...holdingIds, id];
    }
    onFilterChange('holdingIds', newHoldingIds);
    dispatch(getFilteredEnterprises(newHoldingIds));
    if (!isEmpty(enterpriseIds)) {
      let newEnterpriseIds = enterpriseIds.filter((newEnterpriseId) => {
        const enterprise = find(enterprisesDictionary, { id: newEnterpriseId });
        const enterpriseHolding = get(enterprise, 'holdingId');
        return newHoldingIds.includes(enterpriseHolding);
      });
      onFilterChange('enterpriseIds', newEnterpriseIds);
      dispatch(getFilteredUnits(newEnterpriseIds));
      if (!isEmpty(unitIds)) {
        let newUnitsIds = unitIds.filter((newUnitId) => {
          const unit = find(unitsDictionary, { id: newUnitId });
          const unitEnterprise = get(unit, 'enterpriseId');
          return newEnterpriseIds.includes(unitEnterprise);
        });
        onFilterChange('unitIds', newUnitsIds);
      }
    }

    if (responsibleId && id) {
      const responsibleHoldings = get(responsibleList.find(({ id: respId } = {}) => respId === responsibleId), 'holdings', []);
      if (!isEmpty(newHoldingIds) && !responsibleHoldings.some(responsibleHolding => newHoldingIds.includes(responsibleHolding)) && responsibleId !== 'isResponsibleEmpty') {
        onFilterChange('responsibleId', '');
      }
    }

    if (dispatcherId && id) {
      const dispatchersHoldings = get(dispatchersList.find(({ id: respId } = {}) => respId === dispatcherId), 'holdings', []);
      if (!isEmpty(newHoldingIds) && !dispatchersHoldings.some(dispatchersHolding => newHoldingIds.includes(dispatchersHolding))) {
        onFilterChange('dispatcherId', '');
      }
    }
    if (initiatorId && id) {
      const initiatorsHoldings = get(initiatorsList.find(({ id: respId } = {}) => respId === initiatorId), 'holdings', []);
      if (!isEmpty(newHoldingIds) && !initiatorsHoldings.some(initiatorsHolding => newHoldingIds.includes(initiatorsHolding))) {
        onFilterChange('initiatorId', '');
      }
    }

    if (businessLawyerId && id) {
      const businessLawyerHoldings = get(businessLawyersList.find(({ id: respId } = {}) => respId === businessLawyerId), 'holdings', []);
      if (businessLawyerId !== 'isBusinessLawyerEmpty' && !isEmpty(newHoldingIds) && !businessLawyerHoldings.some(businessLawyerHolding => newHoldingIds.includes(businessLawyerHolding))) {
        onFilterChange('businessLawyerId', '');
      }
    }

    if (!isEmpty(ecologistIds) && id) {
      onFilterChange('ecologistIds', ecologistIds.filter((ecologistId) => {
        if (ecologistId === 'isEcologistEmpty' || isEmpty(newHoldingIds)) return true;
        const ecologistHoldings = get(ecologistsList.find(({ id: respId } = {}) => respId === ecologistId), 'holdings', []);
        return ecologistHoldings.some(ecologistHolding => newHoldingIds.includes(ecologistHolding));
      }));
    }
  };

  const onChangeEnterprise = (enterpriseId) => {
    let newEnterpriseIds;
    if (enterpriseIds.includes(enterpriseId)) {
      newEnterpriseIds = enterpriseIds.filter(it => it !== enterpriseId);
    } else {
      newEnterpriseIds = [...enterpriseIds, enterpriseId];
    }
    if (holdings.length === 1) {
      const [holding] = holdings;
      onFilterChange('holdingIds', !isEmpty(newEnterpriseIds) ? [holding] : '');
    }
    onFilterChange('enterpriseIds', newEnterpriseIds);
    dispatch(getFilteredUnits(newEnterpriseIds));
    if (!isEmpty(unitIds)) {
      let newUnitsIds = unitIds.filter((newUnitId) => {
        const unit = find(unitsDictionary, { id: newUnitId });
        const unitEnterprise = get(unit, 'enterpriseId');
        return newEnterpriseIds.includes(unitEnterprise);
      });
      onFilterChange('unitIds', newUnitsIds);
    }
  };

  const onChangeUnit = (unitId) => {
    let newUnitIds;
    if (unitIds.includes(unitId)) {
      newUnitIds = unitIds.filter(it => it !== unitId);
    } else {
      newUnitIds = [...unitIds, unitId];
    }
    onFilterChange('unitIds', newUnitIds);
  };

  const renderHolding = () => {
    if (canSelectHolding) {
      return (
        <CustomTags
          multiple
          label={HOLDING}
          optionValue="name"
          hasClearBtn
          onChange={onChangeHolding}
          value={holdingIds}
          options={isKC_LAWYER || isREADER ? holdingsDictionary : holdingsDictionary.filter(({ id: holdingId } = {}) => holdings.includes(holdingId))}
          data-test="filter-holding"
        />
      );
    }

    const [holding] = holdings;
    if (holding) {
      return (
        <TextBlock title={HOLDING}
                   data-test="filter-holding">{get(holdingsDictionary.find(({ id }) => id === holding), 'name', '')}</TextBlock>
      );
    }
    return null;
  };

  const isStoreDateValid = (date) => {
    return Boolean(date) && isValid(new Date(date))
      && isBefore(new Date(2022, 1, 24, 0, 0, 0), new Date(date).setHours(0, 0, 0))
      && isBefore(new Date(date), add(new Date().setHours(0, 0, 0), { days: 1 }));
  };

  const isValidFormInputDate = (date) => {
    const [day, month, year] = date.split('/');
    return day.length === 2 && month.length === 2 && year.length === 4 && isValid(new Date(`${month}/${day}/${year}`));
  };

  const isEndDateBeforeStartDate = (startDate, endDate) => {
    return !isBefore(new Date(startDate), new Date(endDate).setHours(23, 59, 59));
  };

  const validateFiltersForm = () => {
    let isValidForm = true;
    const creationDateFromValue = get(creationDateFromInputRef, 'current.value', '');
    const creationDateToValue = get(creationDateToInputRef, 'current.value', '');

    if (creationDateFromValue) {
      const isValidInputCreationDateFrom = isValidFormInputDate(creationDateFromValue);
      if (!isValidInputCreationDateFrom || !isStoreDateValid(creationDateFrom)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          creationDateFrom: { ...datesValidationState.creationDateFrom, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(creationDateTo) && isEndDateBeforeStartDate(creationDateFrom, creationDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          creationDateFrom: { ...datesValidationState.creationDateFrom, error: NOT_VALID_DATE },
          creationDateTo: { ...datesValidationState.creationDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    if (creationDateToValue) {
      const isValidInputCreationDateTo = isValidFormInputDate(creationDateToValue);
      if (!isValidInputCreationDateTo || !isStoreDateValid(creationDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          creationDateTo: { ...datesValidationState.creationDateTo, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(creationDateFrom) && isEndDateBeforeStartDate(creationDateFrom, creationDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          creationDateFrom: { ...datesValidationState.creationDateFrom, error: NOT_VALID_DATE },
          creationDateTo: { ...datesValidationState.creationDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    const caseDateFromValue = get(caseDateFromInputRef, 'current.value', '');
    const caseDateToValue = get(caseDateToInputRef, 'current.value', '');

    if (caseDateFromValue) {
      const isValidInputCaseDateFrom = isValidFormInputDate(caseDateFromValue);
      if (!isValidInputCaseDateFrom || !isStoreDateValid(caseDateFrom)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          caseDateFrom: { ...datesValidationState.caseDateFrom, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(caseDateTo) && isEndDateBeforeStartDate(caseDateFrom, caseDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          caseDateFrom: { ...datesValidationState.caseDateFrom, error: NOT_VALID_DATE },
          caseDateTo: { ...datesValidationState.caseDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    if (caseDateToValue) {
      const isValidInputCaseDateTo = isValidFormInputDate(caseDateToValue);
      if (!isValidInputCaseDateTo || !isStoreDateValid(caseDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          caseDateTo: { ...datesValidationState.caseDateTo, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(caseDateFrom) && isEndDateBeforeStartDate(caseDateFrom, caseDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          caseDateFrom: { ...datesValidationState.caseDateFrom, error: NOT_VALID_DATE },
          caseDateTo: { ...datesValidationState.caseDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    const repairedDateFromValue = get(repairedDateFromInputRef, 'current.value', '');
    const repairedDateToValue = get(repairedDateToInputRef, 'current.value', '');

    if (repairedDateFromValue) {
      const isValidInputRepairedDateFrom = isValidFormInputDate(repairedDateFromValue);
      if (!isValidInputRepairedDateFrom || !isStoreDateValid(repairedDateFrom)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          repairedDateFrom: { ...datesValidationState.repairedDateFrom, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(repairedDateTo) && isEndDateBeforeStartDate(repairedDateFrom, repairedDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          repairedDateFrom: { ...datesValidationState.repairedDateFrom, error: NOT_VALID_DATE },
          repairedDateTo: { ...datesValidationState.repairedDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    if (repairedDateToValue) {
      const isValidInputRepairedDateTo = isValidFormInputDate(repairedDateToValue);
      if (!isValidInputRepairedDateTo || !isStoreDateValid(repairedDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          repairedDateTo: { ...datesValidationState.repairedDateTo, error: NOT_VALID_DATE }
        })
      } else if (isStoreDateValid(repairedDateFrom) && isEndDateBeforeStartDate(repairedDateFrom, repairedDateTo)) {
        isValidForm = false;
        setDatesValidationState({
          ...datesValidationState,
          repairedDateFrom: { ...datesValidationState.repairedDateFrom, error: NOT_VALID_DATE },
          repairedDateTo: { ...datesValidationState.repairedDateTo, error: NOT_VALID_DATE },
        })
      }
    }

    return isValidForm;
  };

  const apply = () => {
    validateFiltersForm();
    const isValidForm = validateFiltersForm();
    if (isValidForm) {
      handleApplyFilters();
      return;
    }
    dispatch(showNotification({
      type: 'error',
      message: FORM_HAS_ERRORS,
    }));
  };

  const compareNamesFunc = (responsibleA, responsibleB) => {
    const responsibleNameA = get(responsibleA, 'name', '').toLowerCase();
    const responsibleNameB = get(responsibleB, 'name', '').toLowerCase();
    ;
    if (responsibleNameA < responsibleNameB) {
      return -1;
    }
    if (responsibleNameA > responsibleNameB) {
      return 1;
    }
    return 0;
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-test="filters-dialog"
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h1" className={classes.headerTitle}
                      data-test="dialog-title">{FILTRATION}</Typography>
          <Box>
            <Button
              className={classes.cancelButton}
              size="large"
              onClick={handleClose}
              startIcon={<Close/>}
              type="cart-secondary"
              data-test="cancel-button"
            >
              {CANCEL}
            </Button>
            <Button
              className={classes.clearFiltersButton}
              size="large"
              startIcon={<HighlightOffOutlined/>}
              type="cart-secondary"
              data-test="clear-filters-button"
              onClick={resetFilters}
              disabled={isDisabledReset}
            >
              {CLEAR_FILTERS}
            </Button>
            <Button
              className={clsx(classes.applyButton, isDisabledApply ? classes.disabled : classes.enabled)}
              onClick={apply}
              startIcon={<CheckCircleOutlined/>}
              type="cart-secondary"
              data-test="apply-filters-button"
              disabled={isDisabledApply}
            >
              {APPLY}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <div className={classes.section}>
          <Typography
            variant="h2"
            className={classes.sectionTitle}
            data-test="event-attributes-title"
          >
            {EVENT_ATTRIBUTES}
          </Typography>
          <Box className={classes.eventsAttrs}>
            <Box className={classes.eventsAttrsLeft}>
              <Box className={classes.formControlRow}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={ID_NUMBER}
                  value={id}
                  onChange={(event) => onFilterChange('id', parseFloat(event.target.value))}
                  inputProps={{ 'data-test': 'filter-id' }}
                />
              </Box>
              <FormControl variant="outlined" className={classes.formControlRow}>
                {renderHolding()}
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomTags
                  multiple
                  disabled={isEmpty(holdingIds) && canSelectHolding}
                  label={COMPANY}
                  optionValue="name"
                  value={enterpriseIds}
                  onChange={onChangeEnterprise}
                  options={filteredEnterprises}
                  data-test="filter-enterprise"
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomTags
                  multiple
                  disabled={isEmpty(enterpriseIds)}
                  label={UNIT}
                  optionValue="name"
                  value={unitIds}
                  onChange={onChangeUnit}
                  options={filteredUnits}
                  data-test="filter-unit"
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomSelect
                  autocomplete
                  hasClearBtn
                  label={EVENT_REGION}
                  optionValue="name"
                  value={regionsDictionary.find(({ id }) => id === regionId)}
                  onChange={({ id = '' } = {}) => {
                    onFilterChange('regionId', id);
                    onFilterChange('districtId', '');
                  }}
                  options={regionsDictionary}
                  data-test="filter-region"
                />
              </FormControl>
              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomSelect
                  autocomplete
                  hasClearBtn
                  disabled={!regionId}
                  label={VENUE_DISTRICT}
                  optionValue="name"
                  value={filteredRegionsDistricts.find(({ id }) => id === districtId)}
                  onChange={({ id = '' } = {}) => onFilterChange('districtId', id)}
                  options={filteredRegionsDistricts}
                  data-test="filter-district"
                />
              </FormControl>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={DATA_ENTRY_PERIOD}
                />
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  selectedDate={creationDateFrom}
                  label={SINCE}
                  minDate={new Date(2022, 1, 24)}
                  maxDate={creationDateTo || new Date()}
                  handleDateChange={(value) => {// new Date(date.setHours(0, 0)).toISOString()
                    onFilterChange('creationDateFrom', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    setDatesValidationState({
                      ...datesValidationState,
                      creationDateFrom: { value: get(datesValidationState, 'creationDateFrom.value', null) }
                    });
                    const isCreationDateToValid = isStoreDateValid(creationDateTo);
                    setDatesValidationState({
                      ...datesValidationState,
                      creationDateFrom: { value: get(datesValidationState, 'creationDateFrom.value', null) },
                      ...isCreationDateToValid && { creationDateTo: { value: get(datesValidationState, 'creationDateTo.value', null) } }
                    });
                  }}
                  helperText={datesValidationState?.creationDateFrom?.error}
                  error={Boolean(datesValidationState?.creationDateFrom?.error)}
                  inputProps={{
                    ref: creationDateFromInputRef,
                    'data-test': "filter-creation-date-from",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      creationDateFrom: { value: event.target.value }
                    }),
                  }}
                />
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  selectedDate={creationDateTo}
                  label={BY}
                  minDate={creationDateFrom || new Date(2022, 1, 24)}
                  maxDate={new Date()}
                  handleDateChange={value => {
                    onFilterChange('creationDateTo', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    const isCreationDateFromValid = isStoreDateValid(creationDateFrom);
                    setDatesValidationState({
                      ...datesValidationState,
                      creationDateTo: { value: get(datesValidationState, 'creationDateTo.value', null) },
                      ...isCreationDateFromValid && { creationDateFrom: { value: get(datesValidationState, 'creationDateFrom.value', null) } }
                    });
                  }}
                  helperText={datesValidationState?.creationDateTo?.error}
                  error={Boolean(datesValidationState?.creationDateTo?.error)}
                  inputProps={{
                    ref: creationDateToInputRef,
                    'data-test': "filter-creation-date-to",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      creationDateTo: { value: event.target.value }
                    }),
                  }}
                />
              </Box>
              <Box className={classes.formControlRow}>
                <AmountGroupTitle title={EVENT_PERIOD}/>
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  minDate={new Date(2022, 1, 24)}
                  maxDate={caseDateTo || new Date()}
                  selectedDate={caseDateFrom}
                  label={SINCE}
                  handleDateChange={(value) => {
                    onFilterChange('caseDateFrom', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    const isCaseDateToValid = isStoreDateValid(caseDateTo);
                    setDatesValidationState({
                      ...datesValidationState,
                      caseDateFrom: { value: get(datesValidationState, 'caseDateFrom.value', null) },
                      ...isCaseDateToValid && { caseDateTo: { value: get(datesValidationState, 'caseDateTo.value', null) } }
                    });
                  }}
                  helperText={datesValidationState?.caseDateFrom?.error}
                  error={Boolean(datesValidationState?.caseDateFrom?.error)}
                  inputProps={{
                    ref: caseDateFromInputRef,
                    'data-test': "filter-case-date-from",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      caseDateFrom: { value: event.target.value }
                    }),
                  }}
                />
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  minDate={caseDateFrom || new Date(2022, 1, 24)}
                  maxDate={new Date()}
                  selectedDate={caseDateTo}
                  label={BY}
                  handleDateChange={(value) => {
                    onFilterChange('caseDateTo', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    const isCaseDateFromValid = isStoreDateValid(caseDateFrom);
                    setDatesValidationState({
                      ...datesValidationState,
                      caseDateTo: { value: get(datesValidationState, 'caseDateTo.value', null) },
                      ...isCaseDateFromValid && { caseDateFrom: { value: get(datesValidationState, 'caseDateFrom.value', null) } }
                    });
                  }}
                  helperText={datesValidationState?.caseDateTo?.error}
                  error={Boolean(datesValidationState?.caseDateTo?.error)}
                  inputProps={{
                    ref: caseDateToInputRef,
                    'data-test': "filter-case-date-to",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      caseDateTo: { value: event.target.value }
                    }),
                  }}
                />
              </Box>
              <Box className={classes.formControlRow}>
                <AmountGroupTitle title={RETURNED_FOR_REVISION} />
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={0}
                  variant="outlined"
                  label={SINCE}
                  inputProps={{ min: 0, 'data-test': 'filter-documents-revised-status-count-from' }}
                  className={classes.amountInput}
                  value={documentsRevisedStatusCountFrom}
                  onChange={event => onFilterChange('documentsRevisedStatusCountFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={0}
                  variant="outlined"
                  label={BY}
                  inputProps={{ min: 0, 'data-test': 'filter-documents-revised-status-count-to' }}
                  className={classes.amountInput}
                  value={documentsRevisedStatusCountTo}
                  onChange={event => onFilterChange('documentsRevisedStatusCountTo', parseFloat(event.target.value))}
                />
              </Box>
            </Box>
            <Box className={classes.eventsAttrsRight}>
              <Box className={classes.formControlRow}>
                <Box className={classes.flexWrapBetween}>
                  <Box className={classes.halfBlock}>
                    <DottedLoader isLoading={isLoadingInitiators}>
                      <CustomSelect
                        autocomplete
                        hasClearBtn
                        label={INITIATOR}
                        optionValue="name"
                        value={initiatorsList.find(({ id }) => id === initiatorId)}
                        onChange={({ id = '' } = {}) => onFilterChange('initiatorId', id)}
                        options={
                          !isEmpty(holdingIds)
                            ? initiatorsList.filter(({ holdings = [] } = {}) => holdings.some(holding => holdingIds.includes(holding))).sort(compareNamesFunc)
                            : initiatorsList.sort(compareNamesFunc)
                        }
                        data-test="filter-responsible"
                      />
                    </DottedLoader>
                  </Box>
                  <Box className={classes.halfBlock}>
                    <FormControl variant="outlined" className={classes.customTagsWrapper}>
                      <CustomTags
                        multiple
                        filterTitle={EVENT_STATUS}
                        valueKey="enumValue"
                        label={EVENT_STATUS}
                        optionValue="name"
                        value={status}
                        onChange={(enumValue) => {
                          if (status.includes(enumValue)) {
                            onFilterChange('status', status.filter(it => it !== enumValue));
                          } else {
                            onFilterChange('status', [...status, enumValue]);
                          }

                        }}
                        options={caseStatusesDictionary}
                        data-test="filter-status"
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.formControlRow}>
                <Box className={classes.flexWrapBetween}>
                  <Box className={classes.halfBlock}>
                    <DottedLoader isLoading={isLoadingDispatchers}>
                      <CustomSelect
                        autocomplete
                        hasClearBtn
                        label={DISPATCHER}
                        optionValue="name"
                        value={dispatchersList.find(({ id }) => id === dispatcherId)}
                        onChange={({
                          id = ''
                        } = {}) => onFilterChange('dispatcherId', id)}
                        options={
                          !isEmpty(holdingIds)
                            ? dispatchersList.filter(({ holdings = [] } = {}) => holdings.some(holding => holdingIds.includes(holding))).sort(compareNamesFunc)
                            : dispatchersList.sort(compareNamesFunc)
                        }
                        data-test="filter-dispatcher"
                      />
                    </DottedLoader>
                  </Box>
                  <Box className={classes.halfBlock}>
                    <DottedLoader isLoading={isLoadingResponsible}>
                      <CustomSelect
                        autocomplete
                        hasClearBtn
                        label={RESPONSIBLE}
                        optionValue="name"
                        value={[{ id: 'isResponsibleEmpty', name: NOT_PROVIDED }, ...responsibleList].find(({ id }) => id === responsibleId)}
                        onChange={({ id = '' } = {}) => onFilterChange('responsibleId', id)}
                        options={
                          !isEmpty(holdingIds)
                            ? [{ id: 'isResponsibleEmpty', name: NOT_PROVIDED }, ...responsibleList.filter(({ holdings = [] } = {}) => holdings.some(holding => holdingIds.includes(holding))).sort(compareNamesFunc)]
                            : [{ id: 'isResponsibleEmpty', name: NOT_PROVIDED }, ...responsibleList.sort(compareNamesFunc)]
                        }
                        data-test="filter-responsible"
                      />
                    </DottedLoader>
                  </Box>
                </Box>
              </Box>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <DottedLoader isLoading={isLoadingEcologists}>
                  <div style={{ zIndex: 10 }}>
                    <CustomTags
                      multiple
                      filterTitle={ECOLOGIST}
                      valueKey="id"
                      label={ECOLOGIST}
                      optionValue="name"
                      value={ecologistIds}
                      onChange={(enumValue) => {
                        if (ecologistIds.includes(enumValue)) {
                          onFilterChange('ecologistIds', ecologistIds.filter(it => it !== enumValue));
                        } else {
                          onFilterChange('ecologistIds', [...ecologistIds, enumValue]);
                        }

                      }}
                      options={
                        !isEmpty(holdingIds)
                          ? [{ id: 'isEcologistEmpty', name: NOT_PROVIDED }, ...ecologistsList.filter(({ holdings = [] } = {}) => holdings.some(holding => holdingIds.includes(holding))).sort(compareNamesFunc)]
                          : [{ id: 'isEcologistEmpty', name: NOT_PROVIDED }, ...ecologistsList.sort(compareNamesFunc)]
                      }
                      data-test="filter-ecologists"
                    />
                  </div>
                </DottedLoader>
              </FormControl>

              <FormControl variant="outlined" className={classes.customTagsWrapper}>
                <CustomTags
                  multiple
                  filterTitle={ECOLOGICAL_DAMAGE}
                  valueKey="id"
                  label={ECOLOGICAL_DAMAGE}
                  optionValue="name"
                  value={ecologicalDamageIds}
                  onChange={(enumValue) => {
                    if (ecologicalDamageIds.includes(enumValue)) {
                      onFilterChange('ecologicalDamageIds', ecologicalDamageIds.filter(it => it !== enumValue));
                    } else {
                      onFilterChange('ecologicalDamageIds', [...ecologicalDamageIds, enumValue]);
                    }

                  }}
                  options={[{
                    id: 'isEcologicalDamageEmpty',
                    name: NOT_PROVIDED
                  }, ...environmentalRisksDictionary]}
                  data-test="filter-environmental-risk"
                />
              </FormControl>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${TOTAL_ECOLOGICAL_LOSSES},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  disabled={isEcologicalLossesEmpty}
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-total-ecological-loss-from' }}
                  className={classes.amountInput}
                  value={totalEcologicalLossesFrom}
                  onChange={event => onFilterChange('totalEcologicalLossesFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  disabled={isEcologicalLossesEmpty}
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-total-ecological-loss-to' }}
                  className={classes.amountInput}
                  value={totalEcologicalLossesTo}
                  onChange={event => onFilterChange('totalEcologicalLossesTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isEcologicalLossesEmpty}
                      onChange={() => {
                        onFilterChange('isEcologicalLossesEmpty', !isEcologicalLossesEmpty);
                        if (totalEcologicalLossesFrom) {
                          onFilterChange('totalEcologicalLossesFrom', '')
                        }
                        if (totalEcologicalLossesTo) {
                          onFilterChange('totalEcologicalLossesTo', '')
                        }
                      }}
                      inputProps={{
                        'aria-label': 'is ecological losses empty',
                        'data-test': 'is-ecological-losses-empty-checkbox',
                        'data-value': isEcologicalLossesEmpty,
                      }}
                    />
                  }
                  label="Загальну екологічну шкоду не введено"
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${TOTAL_LIQUIDATION_COST},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  // InputAttributes ???
                  disabled={isTotalLiquidationCostEmpty}
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-total-liquidation-cost-from' }}
                  className={classes.amountInput}
                  value={totalLiquidationCostFrom}
                  onChange={event => onFilterChange('totalLiquidationCostFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  disabled={isTotalLiquidationCostEmpty}
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-total-liquidation-cost-to' }}
                  className={classes.amountInput}
                  value={totalLiquidationCostTo}
                  onChange={event => onFilterChange('totalLiquidationCostTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isTotalLiquidationCostEmpty}
                      onChange={() => {
                        onFilterChange('isTotalLiquidationCostEmpty', !isTotalLiquidationCostEmpty);
                        if (totalLiquidationCostFrom) {
                          onFilterChange('totalLiquidationCostFrom', '')
                        }
                        if (totalLiquidationCostTo) {
                          onFilterChange('totalLiquidationCostTo', '')
                        }
                      }}
                      inputProps={{
                        'aria-label': 'is total liquidation cost empty',
                        'data-test': 'is-total-liquidation-cost-empty-checkbox',
                        'data-value': isTotalLiquidationCostEmpty,
                      }}
                    />
                  }
                  label="Загальну вартість ліквідації не введено"
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${TOTAL_AMOUNT_OF_LOSSES},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-total-loss-from' }}
                  className={classes.amountInput}
                  value={totalLossFrom}
                  onChange={event => onFilterChange('totalLossFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-total-loss-to' }}
                  className={classes.amountInput}
                  value={totalLossTo}
                  onChange={event => onFilterChange('totalLossTo', parseFloat(event.target.value))}
                />
              </Box>

            </Box>
          </Box>

          <Box className={classes.eventsCheckBoxes}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={photoVideoFixationIsUnavailable}
                  onChange={(e, val) => {
                    onFilterChange('photoVideoFixationIsUnavailable', !photoVideoFixationIsUnavailable);
                  }}
                  color="primary"
                  inputProps={{ 'data-test': 'filter-photo-video-fixation-is-unavailable' }}
                />
              )}
              label={(
                <Typography variant="subtitle2">
                  Немає можливості фото/відео фіксації
                </Typography>
              )}
            />
          </Box>
          <Box className={classes.eventsCheckBoxes}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={documentsPhotoVideoReady}
                  onChange={(e, val) => {
                    onFilterChange('documentsPhotoVideoReady', !documentsPhotoVideoReady);
                  }}
                  color="primary"
                  inputProps={{ 'data-test': 'filter-documents-photo-video-ready' }}
                />
              )}
              label={(
                <Typography variant="subtitle2">
                  Фото\відео фіксація
                </Typography>
              )}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={documentsFixationReady}
                  onChange={(e, val) => {
                    onFilterChange('documentsFixationReady', !documentsFixationReady);
                  }}
                  color="primary"
                  inputProps={{ 'data-test': 'filter-documents-fixation-ready' }}
                />
              )}
              label={(
                <Typography variant="subtitle2">
                  Документальна фіксація (акт)
                </Typography>
              )}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={documentsApplicationToLawEnforcementReady}
                  onChange={(e, val) => {
                    onFilterChange('documentsApplicationToLawEnforcementReady', !documentsApplicationToLawEnforcementReady);
                  }}
                  color="primary"
                  inputProps={{ 'data-test': 'filter-documents-application-to-law-enforcement-ready' }}
                />
              )}
              label={(
                <Typography variant="subtitle2">
                  Заява у правоохоронні органи
                </Typography>
              )}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={documentsFullyFormedReady}
                  onChange={(e, val) => {
                    onFilterChange('documentsFullyFormedReady', !documentsFullyFormedReady);

                  }}
                  color="primary"
                  inputProps={{ 'data-test': 'filter-documents-fully-formed-ready' }}
                />
              )}
              label={(
                <Typography variant="subtitle2">
                  Повний пакет док. сформований
                </Typography>
              )}
            />
          </Box>

        </div>
        <div className={classes.section}>
          <Typography
            variant="h2"
            className={classes.sectionTitle}
            data-test="event-objects-title"
          >
            {OBJECTS}
          </Typography>
          <Box className={classes.eventsAttrs}>
            <Box className={classes.eventsAttrsLeft}>
              <Box className={classes.formControlRow}>
                <Box className={classes.flexWrapBetween}>
                  <Box className={classes.halfBlock}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={ID_NUMBER}
                      value={objectId}
                      inputProps={{ 'data-test': "filter-object-id" }}
                      onChange={event => onFilterChange('objectId', +event.target.value.replace(/\D/g, ""))}
                    />
                  </Box>
                  <Box className={classes.halfBlock}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={`${OLD} ${ID_NUMBER}`}
                      value={oldObjectId}
                      inputProps={{ 'data-test': "filter-old-object-id" }}
                      onChange={event => onFilterChange('oldObjectId', event.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.formControlRow}>
                <Box className={classes.flexWrapBetween}>
                  <Box className={classes.halfBlock}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={INVENTORY_NUMBER}
                      value={inventoryNumber}
                      inputProps={{ 'data-test': "filter-inventory-number" }}
                      onChange={event => onFilterChange('inventoryNumber', event.target.value)}
                    />
                  </Box>
                  <Box className={classes.halfBlock}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={SAP_TECHNICAL_PLACE}
                      value={technicalPlaceSAP}
                      inputProps={{ 'data-test': "filter-technical-place-SAP" }}
                      onChange={event => onFilterChange('technicalPlaceSAP', event.target.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle title={REPAIR_COMPLETION_PERIOD}/>
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  selectedDate={repairedDateFrom}
                  minDate={new Date(2022, 1, 24)}
                  maxDate={repairedDateTo || new Date()}
                  label={SINCE}
                  handleDateChange={(value) => {
                    onFilterChange('repairedDateFrom', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    const isRepairedDateToValid = isStoreDateValid(repairedDateTo);
                    setDatesValidationState({
                      ...datesValidationState,
                      repairedDateFrom: { value: get(datesValidationState, 'repairedDateFrom.value', null) },
                      ...isRepairedDateToValid && { repairedDateTo: { value: get(datesValidationState, 'repairedDateTo.value', null) } }
                    });

                  }}
                  helperText={datesValidationState?.repairedDateFrom?.error}
                  error={Boolean(datesValidationState?.repairedDateFrom?.error)}
                  inputProps={{
                    ref: repairedDateFromInputRef,
                    'data-test': "filter-repaired-date-from",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      repairedDateFrom: { value: event.target.value }
                    }),
                  }}
                />
                <DataPicker
                  invalidDateMessage=""
                  withCalendarIcon={false}
                  className={classes.amountInput}
                  selectedDate={repairedDateTo}
                  minDate={repairedDateFrom || new Date(2022, 1, 24)}
                  maxDate={new Date()}
                  label={BY}
                  handleDateChange={(value) => {
                    onFilterChange('repairedDateTo', isValid(value) ? new Date(value.setHours(0, 0)).toISOString() : value);
                    const isRepairedDateFromValid = isStoreDateValid(repairedDateFrom);
                    setDatesValidationState({
                      ...datesValidationState,
                      repairedDateTo: { value: get(datesValidationState, 'repairedDateTo.value', null) },
                      ...isRepairedDateFromValid && { repairedDateFrom: { value: get(datesValidationState, 'repairedDateFrom.value', null) } }
                    });
                  }}
                  helperText={datesValidationState?.repairedDateTo?.error}
                  error={Boolean(datesValidationState?.repairedDateTo?.error)}
                  inputProps={{
                    ref: repairedDateToInputRef,
                    'data-test': "filter-repaired-date-to",
                    onChange: (event) => setDatesValidationState({
                      ...datesValidationState,
                      repairedDateTo: { value: event.target.value }
                    }),
                  }}
                />
              </Box>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomTags
                  multiple
                  label={PROPERTY_TYPE}
                  optionValue="name"
                  value={propertyTypeIds}
                  onChange={(id) => {
                    if (propertyTypeIds.includes(id)) {
                      onFilterChange('propertyTypeIds', propertyTypeIds.filter(it => it !== id));
                    } else {
                      onFilterChange('propertyTypeIds', [...propertyTypeIds, id]);
                    }
                  }}
                  options={propertyTypesDictionary}
                  data-test="filter-property-type"
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <CustomTags
                  multiple
                  label={DAMAGE_CATEGORY}
                  optionValue="name"
                  value={damageCategoryIds}
                  onChange={(id) => {
                    if (damageCategoryIds.includes(id)) {
                      onFilterChange('damageCategoryIds', damageCategoryIds.filter(it => it !== id));
                    } else {
                      onFilterChange('damageCategoryIds', [...damageCategoryIds, id]);
                    }
                  }}
                  options={damageCategoriesDictionary}
                  data-test="filter-damage-category"
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.customTagsWrapper}>
                <DamageCompensationCategoriesSelect
                  value={damageCompensationCategoryIds}
                  onChange={(enumValue) => {
                    if (damageCompensationCategoryIds.includes(enumValue)) {
                      const filteredDamageCompensationCategoryIds = damageCompensationCategoryIds.filter(id => id !== enumValue);
                      onFilterChange('damageCompensationCategoryIds', !isEmpty(filteredDamageCompensationCategoryIds) ? filteredDamageCompensationCategoryIds : null);
                    } else {
                      onFilterChange('damageCompensationCategoryIds', [...damageCompensationCategoryIds, enumValue]);
                    }
                  }}
                  options={compensationCategoriesDictionary}
                />
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <DottedLoader isLoading={isLoadingBusinessLawyers}>
                  <CustomSelect
                    autocomplete
                    hasClearBtn
                    label={LAWYER_BUSINESS}
                    optionValue="name"
                    value={[{
                      id: 'isBusinessLawyerEmpty',
                      name: NOT_PROVIDED
                    }, ...businessLawyersList].find(({ id }) => id === businessLawyerId)}
                    onChange={({ id = '' } = {}) => onFilterChange('businessLawyerId', id)}
                    options={
                      !isEmpty(holdingIds)
                        ? [{ id: 'isBusinessLawyerEmpty', name: NOT_PROVIDED }, ...businessLawyersList.filter(({ holdings = [] } = {}) => holdings.some(holding => holdingIds.includes(holding))).sort(compareNamesFunc)]
                        : [{ id: 'isBusinessLawyerEmpty', name: NOT_PROVIDED }, ...businessLawyersList.sort(compareNamesFunc)]
                    }
                    data-test="filter-businessLawyer"
                  />
                </DottedLoader>
              </FormControl>

              <FormControl variant="outlined" className={classes.formControlRow}>
                <DottedLoader isLoading={isLoadingKCLawyers}>
                  <CustomSelect
                    autocomplete
                    hasClearBtn
                    maxHeight={180}
                    label={LAWYER_K}
                    optionValue="name"
                    value={[{
                      id: 'isKCLawyerEmpty',
                      name: NOT_PROVIDED
                    }, ...KCLawyersList].find(({ id }) => id === kCLawyerId)}
                    onChange={({ id = '' } = {}) => onFilterChange('kCLawyerId', id)}
                    options={[{ id: 'isKCLawyerEmpty', name: NOT_PROVIDED }, ...KCLawyersList]}
                    data-test="filter-KCLawyer"
                  />
                </DottedLoader>
              </FormControl>

            </Box>
            <Box className={classes.eventsAttrsRight}>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${BOOK_VALUE},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-book-value-from' }}
                  className={classes.amountInput}
                  value={bookValueFrom}
                  onChange={event => onFilterChange('bookValueFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-book-value-to' }}
                  className={classes.amountInput}
                  value={bookValueTo}
                  onChange={event => onFilterChange('bookValueTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${COST_OF_EMERGENCY_REPAIRS},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-wreck-repair-cost-from' }}
                  className={classes.amountInput}
                  value={wreckRepairCostFrom}
                  onChange={event => onFilterChange('wreckRepairCostFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-wreck-repair-cost-to' }}
                  className={classes.amountInput}
                  value={wreckRepairCostTo}
                  onChange={event => onFilterChange('wreckRepairCostTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${RECOVERY_ANALOG},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-recovery-cost-from' }}
                  className={classes.amountInput}
                  value={recoveryCostFrom}
                  onChange={event => onFilterChange('recoveryCostFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-recovery-cost-to' }}
                  className={classes.amountInput}
                  value={recoveryCostTo}
                  onChange={event => onFilterChange('recoveryCostTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${ECONOMIC_LOSSES},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-economic-loss-from' }}
                  className={classes.amountInput}
                  value={economicLossFrom}
                  onChange={event => onFilterChange('economicLossFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-economic-loss-to' }}
                  className={classes.amountInput}
                  value={economicLossTo}
                  onChange={event => onFilterChange('economicLossTo', parseFloat(event.target.value))}
                />
              </Box>

              <Box className={classes.formControlRow}>
                <AmountGroupTitle
                  title={`${TOTAL_AMOUNT_OF_LOSSES},`}
                  subTitle={LOSSES_AMOUNT_END_ADORNMENT}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={FROM}
                  inputProps={{ min: 0, 'data-test': 'filter-object-total-loss-from' }}
                  className={classes.amountInput}
                  value={objectTotalLossFrom}
                  onChange={event => onFilterChange('objectTotalLossFrom', parseFloat(event.target.value))}
                />
                <NumberFormat
                  customInput={TextField}
                  decimalSeparator="."
                  thousandSeparator={" "}
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  variant="outlined"
                  label={TO}
                  inputProps={{ min: 0, 'data-test': 'filter-object-total-loss-to' }}
                  className={classes.amountInput}
                  value={objectTotalLossTo}
                  onChange={event => onFilterChange('objectTotalLossTo', parseFloat(event.target.value))}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

FiltersModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

FiltersModal.defaultProps = {
  open: false,
  handleClose: () => {
  },
};

export default FiltersModal;

