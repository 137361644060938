/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'API';
import { LOADING, SUCCEEDED, INIT_STATUS, FAILED } from 'constants/main';
import { mapEventDataToClient } from 'utils/mappers';
import { get } from 'lodash';
import { showNotification } from './notifications';

export const loadEvent = createAsyncThunk('event/loadEvent', async (args, { rejectWithValue }) => {
    try {
      const { eventId, holdingId, user, currentUserRoles, isView } = args;
      const response = await API.event.getEvent(eventId, holdingId);
      return mapEventDataToClient({ formData: response.data, user, currentUserRoles, isView });
    } catch (err) {
      return rejectWithValue({
        ...err.payload,
        status: err.response?.status,
      });
    }
  },
);

export const deleteEvent = createAsyncThunk('event/deleteEvent', async (args, { rejectWithValue }) => {
    try {
      const { eventId, holdingId } = args;
      await API.event.deleteEvent(eventId, holdingId);
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message
        },
      });
    }
  },
);

export const returnEvent= createAsyncThunk('event/returnEvent', async (args, { rejectWithValue }) => {
    try {
      const { eventId, holdingId } = args;
      await API.event.returnEvent(eventId, holdingId);
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message
        },
      });
    }
  },
);

export const exportDocuments = createAsyncThunk('event/exportDocuments', async (args, { rejectWithValue }) => {
    try {
      const { eventId, holdingId } = args;
      const response = await API.event.exportDocuments(eventId, holdingId);
      return response;
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message
        },
      });
    }
  },
);

export const getArchiveStatus = createAsyncThunk('event/exportDocumentsData', async (args, { rejectWithValue }) => {
    try {
      const { archiveCorrelationId } = args;
      const response = await API.event.getArchiveStatus(archiveCorrelationId);
      return response;
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message
        },
      });
    }
  },
);

export const getExcelStatus = createAsyncThunk('event/exportExcel', async (args, { rejectWithValue }) => {
    try {
      const { correlationId, type } = args;
      const response = await API.event.getExcelStatus(correlationId, type);
      return response;
    } catch (err) {
      return rejectWithValue({
        error: {
          message: err?.response?.data?.message
        },
      });
    }
  },
);

export const checkOnUniqueInventoryNumber = createAsyncThunk('event/checkOnUniqueInventoryNumber', async (arg, { rejectWithValue }) => {
  try {
    const { holdingId, data } = arg;
    const response = await API.event.checkOnUniqueInventoryNumber(holdingId, data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        message: err?.response?.data?.message
      },
    });
  }
});

export const createEvent = createAsyncThunk('event/createEvent', async (arg, { rejectWithValue }) => {
  try {
    const { holdingId, data } = arg;
    const response = await API.event.createEvent(holdingId, data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        message: err?.response?.data?.message
      },
    });
  }
});

export const editEvent = createAsyncThunk('event/editEvent', async (arg, { rejectWithValue }) => {
  try {
    const { id, data = {} } = arg;
    const { environmentalRisks, ...rest } = data;
    const response = await API.event.editEvent(id, { ...rest, environmentalRisks: environmentalRisks.map((environmentalRisk = {}) => {
      const environmentalLoss = environmentalRisk?.environmentalLoss;
      const liquidationCost = environmentalRisk?.liquidationCost;
      const environmentalRiskId = environmentalRisk?.environmentalRiskId;
      return {
        ...environmentalLoss && { environmentalLoss },
        ...liquidationCost && { liquidationCost },
        environmentalRiskId,
      };
      })
    });
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        message: err?.response?.data?.message
      },
    });
  }
});

export const createSharePointFolder = createAsyncThunk('event/createFolder', async (arg, { rejectWithValue }) => {
  try {
    const { holdingId, data } = arg;
    const response = await API.event.createSharePointFolder(holdingId, data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      error: {
        message: err?.response?.data?.message
      },
    });
  }
});

const initialStateMetadata = {
  status: INIT_STATUS,
  error: null,
};

const eventSlice = createSlice({
  name: 'event',
  initialState: {
    event: {
      data: undefined,
      initialData: undefined,
      error: null,
      ...initialStateMetadata,
    },
  },
  reducers: {
    setPendingEventLoad: (state) => {
      state.event.status = LOADING;
    },
    resetError: (state) => {
      state.event.error = null;
    },
    resetEventStore: (state) => {
      state.event.data = undefined;
      state.event.status = INIT_STATUS;
    },
    updateFormDataStart: (state) => {
      state.event.status = LOADING;
    },
    updateFormData: (state, action) => {
      state.event.data = mapEventDataToClient(action.payload);
      state.event.status = SUCCEEDED;
    }
  },
  extraReducers: {
    [loadEvent.pending]: (state) => {
      state.event.status = LOADING;
      state.event.error = null;
    },
    [loadEvent.fulfilled]: (state, action) => {
      state.event.data = action.payload;
      state.event.initialData = action.payload;
      state.event.status = SUCCEEDED;
    },
    [loadEvent.rejected]: (state, action) => {
      state.event.status = FAILED;
      state.event.error = action.payload;
    },
  },
});

export const { setPendingEventLoad, resetError, resetEventStore, updateFormData, updateFormDataStart } = eventSlice.actions;
export default eventSlice.reducer;
