import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes rotate_down': {
    '0%': {
      transform: 'rotate(270deg)',
    },
    '50%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(270deg)',
    },
  },
  '@keyframes rotate_up': {
    '0%': {
      transform: 'rotate(90deg)',
    },
    '50%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(90deg)',
    },
  },
  '@keyframes balls': {
    '0%': {
      opacity: '1',
    },
    '80%': {
      opacity: '0.7',
    },
    '100%': {
      opacity: '0',
      transform: 'translate(-100px, 0)',
    },
  },
  container: {
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.88)',
    zIndex: 1000000,
  },
  loader: {
    margin: theme.spacing(2),
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 10,
    color: '#ffe300',
    backgroundColor: '#fff',
    width: 320,
    height: 148,
  },
  pacman: {
    position: 'relative',
    left: '3em',
    width: '5em',
    '&::before': {
      position: 'absolute',
      top: 0,
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderRight: '3em solid transparent',
      borderTop: '3em solid currentColor',
      borderBottom: '3em solid currentColor',
      borderLeft: '3em solid currentColor',
      borderRadius: '50%',
      animationName: '$rotate_up',
      animationDuration: '1s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
    '&::after': {
      position: 'absolute',
      top: 0,
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderRight: '3em solid transparent',
      borderTop: '3em solid currentColor',
      borderBottom: '3em solid currentColor',
      borderLeft: '3em solid currentColor',
      borderRadius: '50%',
      animationName: '$rotate_down',
      animationDuration: '1s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
  },
  bols: {
    position: 'relative',
    left: '15.5em',
    top: '2.2em',
    '& div': {
      position: 'absolute',
      width: '1.5em',
      height: '1.5em',
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      transform: 'translate(0, 0)',
      animationName: '$balls',
      animationDuration: '2s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      '&:nth-child(1)': {
        animationDelay: '-2s',
      },
      '&:nth-child(2)': {
        animationDelay: '-1s',
      },
      '&:nth-child(4)': {
        animationDelay: '0s',
      },
    },
  },
  logo: {
    position: 'absolute',
    width: '116px',
    right: '2em',
    top: 8,
  },
  text: {
    textAlign: 'center',
    fontSize: '1.4em',
    color: '#282828',
    bottom: '2em',
    left: 0,
    right: 0,
    position: 'absolute',
  },
}
));

export default useStyles;
