import { makeStyles } from '@material-ui/core/styles';
import { black70 } from '../../components/ui-lib/ThemeProvider/theme/colors';

const useStyles = makeStyles((theme) => ({
  halfBlock: {
    width: '45%',
  },
  wrapper: {
    display: 'flex',
    width: '60%',
  },
  formControlRow: {
    width: '100%',
    display: 'flex',
    height: 48,
    margin: 0,
  },
  eventsCheckBoxes: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  blockDivider: {
    margin: '24px 0',
  },
  shadowBlock: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: '1px solid #f3f3f3',
    padding: 16,
    borderRadius: 4,
    marginBottom: 16,
  },
  actionBlock: {
    display: 'flex',
    width: '100%',
  },
  actionBlockLeft: {
    width: '20%'
  },
  documentsRevisedStatusCount: {
    color: theme.colors.darkGray,
    margin: '10px 24px 0 0',
    float: 'right',
  },
  eventStatus: {
    color: '#ff9900',
    float: 'right',
    margin: '10px 0 0',
  },
  attrFileButton: {
    flex: 'none',
    alignSelf: 'center',
    marginLeft: 16,
  },
  isEmployeeBlock: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: 14,
    }
  },
  expandAllBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryLight,
    '&:hover': {
      color: theme.colors.lightPrimaryHover,
    },
  },
  toggleAllWrap: {
    cursor: 'pointer',
    position: 'sticky',
    top: 129,
    background: '#fff',
    boxShadow: '3px 0px 0px 0px #fff, -3px 0px 0px 0px #fff',
    zIndex: 2,
  },
  totalLossWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  oldObjectId: {
    display: 'inline-block',
    color: theme.colors.darkGray,
  },
  reportProblemIcon: {
    color: theme.colors.black70,
    marginRight: 16,
  },
  formControlLabel: {
    width: 150,
    color: theme.colors.black,
  },
  black: {
    color: theme.colors.black,
  },
}));

export default useStyles;
