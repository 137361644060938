import { makeStyles } from '@material-ui/core/styles';
import { white, black20, black5 } from '../ThemeProvider/theme/colors';

const defaultButtonBaseStyles = {
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
};
const cartButtonBaseStyles = {
  border: `1px solid ${black20} !important`,
  '&:hover': {
    background: black5,
  },
};

const useStyles = makeStyles(theme => ({
  cartPrimary: cartButtonBaseStyles,
  cartDashed: {
    ...cartButtonBaseStyles,
    borderStyle: 'dashed !important',
  },
  cartSecondary: {
    border: `1px solid transparent !important`,
    background: 'transparent',
  },
  cartError: {
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    color: theme.colors.error,
    '&:hover': {
      backgroundColor: 'rgba(200, 0, 0, 0.04)',
      boxShadow: 'none',
    },
  },
  defaultPrimary: {
    minWidth: 160, // only for large
    ...defaultButtonBaseStyles,
  },
  defaultError: {
    minWidth: 160, // only for large
    ...defaultButtonBaseStyles,
    color: '#fff',
    backgroundColor: `${theme.colors.error} !important`,
  },
  defaultPrimaryDisabled: {
    backgroundColor: `${black20} !important`,
    color: `${white} !important`,
  },
  defaultSecondary: {
    minWidth: 160, // only for large
    ...defaultButtonBaseStyles,
  },
}
));

export default useStyles;
