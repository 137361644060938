import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  DISPATCHER,
  FINANCIER,
  EVENT_REGISTRATION_RESPONSIBLE,
  BUSINESS_LAWYER,
  KC_LAWYER,
  ECOLOGIST,
  WORKGROUP_HEAD, ASSISTANT,
} from 'constants/roles';

const CanEditEvent = ({ children, renderIfHidden }) => {
  const { roles = [], id: currentUserId, name: currentUserName } = useSelector((state) => state.account?.user);
  const {
    data: { authorId = '', responsibleId = '', initialStatus, performers } = {},
  } = useSelector((state) => state.event?.event);

  const [canEdit, setCanEdit] = useState(false);
  const [isCompleteHook, setIsCompleteHook] = useState(false);

  useEffect(() => {
    let canEditEvent = false;
    if (roles.includes(DISPATCHER) && initialStatus === 'Registered' && currentUserId === authorId) {
      canEditEvent = true;
    }
    if (roles.includes(FINANCIER) && initialStatus !== 'DocumentsFinalized') {
      canEditEvent = true;
    }
    if ((roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus === 'Registered' && currentUserId === authorId
      )
      || ((roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && initialStatus !== 'DocumentsFinalized' && currentUserId === responsibleId
      )
      )) {
      canEditEvent = true;
    }
    if (roles.includes(BUSINESS_LAWYER) && initialStatus !== 'DocumentsFinalized' && performers.some((it) => it?.name === currentUserName)) {
      canEditEvent = true;
    }
    if (roles.includes(KC_LAWYER) && initialStatus !== 'DocumentsFinalized' && performers.some((it) => it?.name === currentUserName)) {
      canEditEvent = true;
    }
    if (roles.includes(ECOLOGIST) && initialStatus !== 'DocumentsFinalized') {
      canEditEvent = true;
    }
    if (roles.includes(WORKGROUP_HEAD) && initialStatus !== 'DocumentsFinalized') {
      canEditEvent = true;
    }
    if (roles.includes(ASSISTANT) && (initialStatus === 'InWork' || initialStatus === 'DocumentsRevised')) {
      canEditEvent = true;
    }
    if (canEditEvent) setCanEdit(canEditEvent);
    setIsCompleteHook(true);
  }, [initialStatus]);

  if (!canEdit && !isCompleteHook) return null;
  if (!canEdit && isCompleteHook) return renderIfHidden || null;
  return canEdit && children;
};

export default memo(CanEditEvent);
