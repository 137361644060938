import React from 'react';
import { Box } from '@material-ui/core';

const SubIndentBlock = ({ title, children }) => {
  return (
    <Box>
      {title && title}
      <Box px={2}>
        {children}
      </Box>
    </Box>
  );
};

export default SubIndentBlock;
