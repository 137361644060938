import React from 'react';
import useStyles from './styles';

const AmountGroupTitle = ({ title, subTitle }) => {
  const classes = useStyles({ subTitle });
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      {/*<div className={classes.subTitle}>{LOSSES_AMOUNT_END_ADORNMENT}</div>*/}
      {Boolean(subTitle) && <div className={classes.subTitle}>{subTitle}</div>}
    </div>
  );
};

export default AmountGroupTitle;
