import React, { useState, useEffect } from 'react';
import { get, isEmpty, head } from 'lodash';
import CustomSelect from 'components/PartCustomSelect';
import { RESPONSIBLE } from 'constants/texts';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'store/casesList';
import TextBlock from 'components/TextBlock';
import { EVENT_REGISTRATION_RESPONSIBLE, WORKGROUP_HEAD } from 'constants/roles';
import DottedLoader from 'components/DottedLoader';
import { checkRoles, RESPONSIBLE_FILTER } from 'containers/RoleModel';

const ResponsibleSelect = ({ onChange, value, mode, holdingId }) => {
  const dispatch = useDispatch();
  const {
    roles = [],
    holdings: currentUserHoldings,
    id: currentUserId,
    name: currentUserName
  } = useSelector((state) => state.account?.user);

  const {
    data: { initialResponsibleId, initialResponsibleName, initialStatus } = {},
  } = useSelector((state) => state.event?.event);

  const [isLoadingResponsible, setIsLoadingResponsible] = useState(false);
  const [responsibleList, setResponsibleList] = useState([]);
  const [visibilityProcessed, setVisibilityProcessed] = useState(false);

  useEffect(() => {
    let availableOptions = [];
    let canSelect;
    if (roles.includes(EVENT_REGISTRATION_RESPONSIBLE) && !initialResponsibleId && initialStatus !== 'DocumentsFinalized') {
      availableOptions.push({
        id: currentUserId,
        name: currentUserName,
        holdings: currentUserHoldings
      });
    }
    if (roles.includes(WORKGROUP_HEAD)
      && (mode === 'creation' || !['DocumentsFinalized', 'DocumentsPrepared', 'DocumentsCheckedWithoutRemarks'].includes(initialStatus))) {
      canSelect = true;
    }
    if (canSelect) {
      dispatch(getUsers({ role: EVENT_REGISTRATION_RESPONSIBLE })).then((data) => {
        setIsLoadingResponsible(true);
        const list = get(data, 'payload', []);
        setIsLoadingResponsible(false);
        if (list.some(({ id }) => id === get(head(availableOptions), 'id'))) {
          availableOptions = list;
        } else {
          availableOptions.push(...list);
        }
        setResponsibleList(availableOptions);
      });
    } else {
      setResponsibleList(availableOptions);
    }
    setVisibilityProcessed(true);
  }, [initialStatus]);

  useEffect(() => {
    if (!holdingId || isEmpty(responsibleList)) return;
    const responsibleHoldings = get(responsibleList.find(({ id }) => id === value), 'holdings', []);
    if (!responsibleHoldings.includes(holdingId)) {
      onChange('');
    }
  }, [holdingId]);

  const renderNotEditableStatus = (
    <TextBlock title={RESPONSIBLE}
               data-test="responsible">{initialResponsibleName || '—'}</TextBlock>
  );

  if (!visibilityProcessed) return null;
  if (isEmpty(responsibleList)) return renderNotEditableStatus;

const compareNamesFunc = (responsibleA, responsibleB) => {
  const responsibleNameA = get(responsibleA, 'name', '').toLowerCase();
  const responsibleNameB = get(responsibleB, 'name', '').toLowerCase();;
    if ( responsibleNameA < responsibleNameB ) {
      return -1;
    }
    if ( responsibleNameA > responsibleNameB ) {
      return 1;
    }
    return 0;
  };

  return (
    <DottedLoader isLoading={isLoadingResponsible}>
      <CustomSelect
        autocomplete={checkRoles(roles, RESPONSIBLE_FILTER)}
        hasClearBtn
        label={RESPONSIBLE}
        optionValue="name"
        value={responsibleList.find(({ id }) => id === value)}
        onChange={onChange}
        options={holdingId ? responsibleList.filter(({ holdings = [] } = {}) => holdings.includes(holdingId)).sort(compareNamesFunc) : responsibleList.sort(compareNamesFunc)}
        data-test="responsible"
      />
    </DottedLoader>
  );
};

export default ResponsibleSelect;
