import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
  responsiveEllipsisWrapper: {
    width: '100%',
    overflowWrap: 'anywhere',
  },
  flexWrapper: {
    display: 'flex',
    flex: 1,
  },
}
));

export default useStyles;
