import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import useWindowDimensions from './useWindowDimensions';

import useStyles from './styles';

const defaultCriticalVisiblePart = 0.3;

function Tabs(props) {
  const classes = useStyles();

  const { width } = useWindowDimensions();

  const {
    value,
    onChange,
    options,
    criticalVisiblePart,
  } = props;

  const [translate, setTranslate] = useState(0);
  const [leftSideState, setLeftSideState] = useState({});
  const [rightSideState, setRightSideState] = useState({});
  const [didFirstRender, setDidFirstRender] = useState(false);
  const [activeIndicatorParams, setActiveIndicatorParams] = useState({});
  const [criticalWidthState, setCriticalWidthState] = useState({});

  const tabsWrapper = useRef(null);

  const {
    leftHiddenCount,
    leftPartiallyVisible: { hiddenPart: leftHiddenPart } = {},
  } = leftSideState || {};

  const {
    rightHiddenCount,
    rightPartiallyVisible: { hiddenPart: rightHiddenPart } = {},
  } = rightSideState || {};

  const { delta, wpWidth } = criticalWidthState;
  const allTabsAreVisible = width - wpWidth + delta > 0;

  useEffect(() => {
    if (allTabsAreVisible) setTranslate(0);
  }, [allTabsAreVisible]);

  const { offsetLeft = 0, clientWidth = 0 } = activeIndicatorParams;

  const setActiveIndicator = (index) => {
    const tabsElements = getTabsElements();
    const activeTab = tabsElements[index];
    if (isObject(activeTab)) {
      const { offsetLeft, clientWidth } = activeTab;
      const paramsToSet = { offsetLeft, clientWidth };
      if (!isEqual(paramsToSet, activeIndicatorParams)) {
        setActiveIndicatorParams(paramsToSet);
      }
    }
  };

  const getTabsElements = () => {
    const tabsWrapperElements = get(tabsWrapper, 'current.childNodes', []);
    const tabsElements = filter(tabsWrapperElements, ({ localName } = {}) => localName !== 'div');
    return tabsElements;
  };

  const processPartiallyVisible = (index) => {
    const {
      right: {
        rightPartiallyVisible: { index: rightHiddenPartIndex, hiddenPart: rightHiddenPart },
      },
      left: {
        leftPartiallyVisible: { index: leftHiddenPartIndex, hiddenPart: leftHiddenPart }
      }
    } = getHiddenContentInfo();

    if (index === leftHiddenPartIndex) {
      setTranslate(translate + leftHiddenPart);
    }
    if (index === rightHiddenPartIndex && rightHiddenPart > 0) {
      setTranslate(translate - rightHiddenPart);
    }
  };

  const processFullyInvisible = (index) => {
    const {
      right: {
        rightPartiallyVisible: { index: rightHiddenPartIndex, hiddenPart: rightHiddenPart }
      },
      left: {
        leftPartiallyVisible: { index: leftHiddenPartIndex, hiddenPart: leftHiddenPart }
      }
    } = getHiddenContentInfo();

    if (index > leftHiddenPartIndex && index < rightHiddenPartIndex) return;

    const tabsElements = getTabsElements();
    let intermediateTranslation = 0;

    if (index > rightHiddenPartIndex) {
      for (let tabIndex = rightHiddenPartIndex; tabIndex < index; tabIndex += 1) {
        const intermediateChildNode = tabsElements[tabIndex];
        const intermediateChildNodeWidth = get(intermediateChildNode, 'clientWidth', 0);
        intermediateTranslation += intermediateChildNodeWidth;
      }
      setTranslate(translate - rightHiddenPart - intermediateTranslation);
    }
    if (index < leftHiddenPartIndex) {
      for (let tabIndex = index; tabIndex < leftHiddenPartIndex; tabIndex += 1) {
        const intermediateChildNode = tabsElements[tabIndex];
        const intermediateChildNodeWidth = get(intermediateChildNode, 'clientWidth', 0);
        intermediateTranslation += intermediateChildNodeWidth;
      }
      setTranslate(translate + leftHiddenPart + intermediateTranslation);
    }
  };

  function getLeftHiddenCount(buttonsArrayWidth = []) {
    let leftHiddenCount = 0;
    let tabIndex = 0;
    let temp = 0;
    let leftPartiallyVisible = {};
    for (tabIndex; tabIndex < buttonsArrayWidth.length; tabIndex += 1) {
      const currentElementWidth = buttonsArrayWidth[tabIndex];
      const delta = temp + currentElementWidth + translate;
      if (delta < 0) {
        leftHiddenCount = tabIndex + 1;
        temp += currentElementWidth;
      } else {
        if (delta === 0) leftHiddenCount = tabIndex + 1;
        if (delta < currentElementWidth * criticalVisiblePart) {
          leftHiddenCount = tabIndex + 1;
        }
        leftPartiallyVisible = { index: tabIndex, hiddenPart: buttonsArrayWidth[tabIndex] - delta };
        break;
      }
    }
    return { leftHiddenCount, leftPartiallyVisible };
  }

  function getRightHiddenCount(buttonsArrayWidth = [],
    tabsWrapperClientWidth) {
    let rightHiddenCount = 0;
    let rightPartiallyVisible = {};
    const allElementsWidth = !isEmpty(buttonsArrayWidth)
      ? buttonsArrayWidth.reduce((a, b) => a + b, 0)
      : 0;
    const didntFitAtRight = allElementsWidth - tabsWrapperClientWidth + translate;
    let tabIndex = 0;
    let temp = 0;
    for (tabIndex; tabIndex < buttonsArrayWidth.length; tabIndex += 1) {
      const lastElementWidth = buttonsArrayWidth[buttonsArrayWidth.length - 1 - tabIndex];
      const delta = temp + lastElementWidth - didntFitAtRight;
      if (delta < 0) {
        rightHiddenCount = tabIndex + 1;
        temp += lastElementWidth;
      } else {
        if (delta === 0) rightHiddenCount = tabIndex + 1;
        if (delta < lastElementWidth * criticalVisiblePart) {
          rightHiddenCount = tabIndex + 1;
        }
        rightPartiallyVisible = {
          index: buttonsArrayWidth.length - 1 - tabIndex,
          hiddenPart: lastElementWidth - delta,
        };
        break;
      }
    }
    return { rightHiddenCount, rightPartiallyVisible, didntFitAtRight };
  }

  const getHiddenContentInfo = () => {
    const tabsWrapperClientWidth = get(tabsWrapper, 'current.clientWidth', 0);
    const tabsElements = getTabsElements();
    const buttonsArrayWidth = [];
    forEach(tabsElements, ({ clientWidth = 0 } = {}) => {
      buttonsArrayWidth.push(clientWidth);
    });
    const {
      leftHiddenCount,
      leftPartiallyVisible
    } = getLeftHiddenCount(buttonsArrayWidth);

    const {
      rightHiddenCount,
      rightPartiallyVisible,
    } = getRightHiddenCount(buttonsArrayWidth, tabsWrapperClientWidth, translate);

    return ({
      left: { leftHiddenCount, leftPartiallyVisible },
      right: { rightHiddenCount, rightPartiallyVisible }
    }
    );
  };

  const updateHiddenContentInfo = () => {
    const { left, right } = getHiddenContentInfo();
    setLeftSideState(left);
    setRightSideState(right);
  };

  useEffect(() => {
    const indexOfValue = findIndex(options, { id: value });
    setActiveIndicator(indexOfValue);
    updateHiddenContentInfo();
  }, [translate]);

  useEffect(() => {
    const indexOfValue = findIndex(options, { id: value });
    if (indexOfValue < 0) return;
    setActiveIndicator(indexOfValue);
    processFullyInvisible(indexOfValue);
    processPartiallyVisible(indexOfValue);
    updateHiddenContentInfo();
  }, [width, options.length]);

  useEffect(() => {
    const tabsWrapperClientWidth = get(tabsWrapper, 'current.clientWidth', 0);
    const tabsElements = getTabsElements();
    const buttonsArrayWidth = [];
    forEach(tabsElements, ({ clientWidth = 0 } = {}) => {
      buttonsArrayWidth.push(clientWidth);
    });
    const buttonsArrayWidthSum = buttonsArrayWidth.reduce((a, b) => a + b, 0);
    const delta = tabsWrapperClientWidth - buttonsArrayWidthSum;
    setCriticalWidthState({ delta, wpWidth: width });
  }, [width, options.length]);

  useEffect(() => {
    const indexOfValue = findIndex(options, { id: value });
    if (indexOfValue < 0) return;
    updateHiddenContentInfo();
    setActiveIndicator(indexOfValue);
    processFullyInvisible(indexOfValue);
    processPartiallyVisible(indexOfValue);
    if (!didFirstRender) setTimeout(() => setDidFirstRender(true), 500);
  }, [value, options.length]);

  return (
    <div className={classes.root}>
      {!allTabsAreVisible && (
        <div className={classes.backwardButtonWrapper}>
          <IconButton
            disabled={leftHiddenPart <= 0}
            size="small"
            className={classes.backwardButton}
            onClick={() => setTranslate(translate + leftHiddenPart)}
          >
            <SvgIcon component={KeyboardArrowLeftIcon}/>
          </IconButton>
          {leftHiddenCount > 0 && (
            <span className={classes.hiddenCount}>{`+${leftHiddenCount}`}</span>
          )}
        </div>
      )}
      <div className={classes.translationContent}>
        <div
          ref={tabsWrapper}
          className={classes.tabsWrapper}
          style={{
            transform: `translate(${translate}px)`,
            transition: didFirstRender ? 'all 0.5s' : 'none',
          }}
        >
          {true && (
            <div
              className={classes.activeIndicator}
              style={{
                width: clientWidth,
                left: offsetLeft,
                transition: didFirstRender ? 'all 0.5s' : 'none',
              }}
            />
          )}
          {options.map((option = {}) => {
            const { id, title } = option;
            const active = value.toString() === id.toString();
            return (
              <Button
                key={id}
                color="primary"
                onClick={() => onChange(id)}
                label={title}
                style={{
                  width: 'auto',
                }}
                className={`${classes.tab} ${active ? classes.active : classes.inactive}`}
              >
                {title}
              </Button>
            );
          })}
        </div>
      </div>
      {!allTabsAreVisible && (
        <div className={classes.forwardButtonWrapper}>
          {rightHiddenCount > 0 && (
            <span className={classes.hiddenCount}>{`+${rightHiddenCount}`}</span>
          )}
          <IconButton
            disabled={rightHiddenPart <= 0}
            size="small"
            className={classes.forwardButton}
            onClick={() => setTranslate(translate - rightHiddenPart)}
          >
            <SvgIcon
              component={KeyboardArrowRightIcon}
            />
          </IconButton>
        </div>
      )}
    </div>
  );
}

Tabs.defaultProps = {
  criticalVisiblePart: defaultCriticalVisiblePart,
};

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })]).isRequired,
  onChange: PropTypes.func.isRequired,
  criticalVisiblePart: PropTypes.number,
};

export default Tabs;
