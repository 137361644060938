/* eslint-disable no-console */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Loader from 'components/Loader';

import { loadUserData } from 'store/account';
import MsalAuth, { ACCESS_TOKEN_NAME } from './MsalAuth';

const msal = new MsalAuth();

const AuthContainer = () => {
  const dispatch = useDispatch();

  return React.lazy(() => new Promise((resolve) => {
    const handleLoginErrors = (errObj) => {
      if (errObj.status === 403) {
        return resolve(import('./NotAuthenticatedApp'));
      }

      return resolve(import('./AuthenticatedApp'));
    };

    const setUserTokenAndRenderApp = ({ accessToken }) => {
      localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);

      dispatch(loadUserData()).then((data) => {
        if (data.error) return handleLoginErrors(data.payload);

        return resolve(import('./AuthenticatedApp'));
      }).catch((err) => {
        console.error('load user data Error', err);
        msal.logout();
      });
    };

    msal.getAccessToken()
      .then(setUserTokenAndRenderApp)
      .catch(({ errorCode = null }) => {
        if (msal.requiresInteraction(errorCode)) {
          msal.acquireTokenRedirect();
          return;
        }
        if (msal.isUserLoginError(errorCode)) {
          msal.redirectToLogin();
        }
      });
  }));
};

function MSAuth(props) {
  const AuthAction = AuthContainer(props);

  return (
    <Suspense fallback={<Loader/>}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <AuthAction>{props.children}</AuthAction>
    </Suspense>
  );
}

MSAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export { msal };
export default MSAuth;
