import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from 'store/notifications';
import { startPolling, stopPolling } from 'store/pollingSlice';
import { getExcelStatus } from 'store/event';
import {
	DISPLAY_DATE_FORMAT_FILENAME,
	DISPLAY_TIME_FORMAT_FILENAME,
	formatDate,
	formatTime
} from 'utils/dateTime';
import { formatISO } from 'date-fns';
import onFileReceived from 'utils/file';
import useArchiveStatusPolling from './useArchiveStatusPolling';
import {
	ERROR_EVENTS_DOCUMENTS_EXPORT,
	ERROR_EVENTS_EXPORT,
	EVENT_SUCCESSFULLY_EXPORTED,
	EVENTS_SUCCESSFULLY_EXPORTED
} from 'constants/texts';

const useExcelStatusPolling = () => {
	const dispatch = useDispatch();
	const startArchiveStatusPolling = useArchiveStatusPolling();

	const startPollingProcess = useCallback((correlationId, type = 'case', casesIds = [], archiveCorrelationId) => {
		const showError = (errorMessage) => {
			let exportExcelErrorMessage = '';
			switch (type) {
				case 'case': {
					exportExcelErrorMessage = errorMessage || ERROR_EVENTS_DOCUMENTS_EXPORT;
					break;
				}
				case 'cases': {
					exportExcelErrorMessage = ERROR_EVENTS_EXPORT;
					break;
				}
				case 'casesWithDocuments': {
					exportExcelErrorMessage = ERROR_EVENTS_EXPORT;
					break;
				}
				default: {
					exportExcelErrorMessage = '';
				}
			}

			dispatch(showNotification({
				type: 'error',
				message: exportExcelErrorMessage
			}));
		};

		if (!correlationId) {
			showError();
			return;
		}

		const intervalId = setInterval(async () => {
			const result = await dispatch(getExcelStatus({ correlationId, type }));

			if (result?.payload?.headers['is-uploaded'] === 'true') {

				let excelUploadMessage = '';
				let excelUploadMessageType = 'info';
				let fileName = '';
				let shouldExecuteArchivePolling = false;

				switch (type) {
					case 'case': {
						shouldExecuteArchivePolling = true;
						excelUploadMessage = `${EVENT_SUCCESSFULLY_EXPORTED}. Формування архіву з документами відбувається у фоновому режимі. Ми додатково надамо сповіщення, коли він буде сформаний`;
						const [casesId] = casesIds;
						fileName = `case_${casesId}_documents_report_${formatDate(formatISO(new Date()), DISPLAY_DATE_FORMAT_FILENAME)}_${formatTime(formatISO(new Date()), DISPLAY_TIME_FORMAT_FILENAME)}.xlsx`;
						break;
					}
					case 'cases': {
						excelUploadMessage = EVENTS_SUCCESSFULLY_EXPORTED;
						excelUploadMessageType = 'success';
						fileName = `cases_report_${formatDate(formatISO(new Date()), DISPLAY_DATE_FORMAT_FILENAME)}_${formatTime(formatISO(new Date()), DISPLAY_TIME_FORMAT_FILENAME)}.xlsx`;
						break;
					}
					case 'casesWithDocuments': {
						shouldExecuteArchivePolling = true;
						excelUploadMessage = `${EVENTS_SUCCESSFULLY_EXPORTED}. Формування архіву з документами відбувається у фоновому режимі. Ми додатково надамо сповіщення, коли він буде сформаний`;
						fileName = `cases_documents_report_${formatDate(formatISO(new Date()), DISPLAY_DATE_FORMAT_FILENAME)}_${formatTime(formatISO(new Date()), DISPLAY_TIME_FORMAT_FILENAME)}.xlsx`;
						break;
					}
					default: {
						excelUploadMessage = '';
					}
				}

				excelUploadMessage =
					dispatch(showNotification({
						type: excelUploadMessageType,
						message: excelUploadMessage,
					}));

				onFileReceived(result.payload.data, fileName);

				clearInterval(intervalId);
				dispatch(stopPolling({ processId: correlationId }));

				if (shouldExecuteArchivePolling) {
					startArchiveStatusPolling(archiveCorrelationId, type, casesIds);
				}
			}
			if (result?.error) {
				clearInterval(intervalId);
				dispatch(stopPolling({ processId: correlationId }));

				showError(result?.payload?.error?.message);
			}
		}, 5000);

		dispatch(startPolling({ process: { processId: correlationId, intervalId, type, casesIds } }));
	}, [dispatch]);

	return startPollingProcess;
};

export default useExcelStatusPolling;
